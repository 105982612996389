import { Project } from "@/models/Project"
import React, { useContext, useEffect, useState } from "react"
import { AppStateContext, AppDispatchContext } from "../contexts/AppContext"
import { languagesList } from "@/utilities/LangISONameHelper"
import { message, Select, Checkbox } from "antd"
import { Button } from "@/components/common"
import ProjectClient from "@/clients/ProjectClient"
import { Actions } from "../reducers/AppReducer"
import Plus from "@/resources/images/plus-1-1_1.svg"

const TranslationSettings: React.ComponentType = (props) => {
  const projectClient = new ProjectClient()
  const { state } = useContext(AppStateContext)
  const { dispatch } = useContext(AppDispatchContext)
  const [language, setLanguage] = useState("en")
  const [token, setToken] = useState("")
  const [loading, setLoading] = useState(false)
  const [isLokalise, setIsLokalise] = useState(false)
  const [isWeglot, setIsWeglot] = useState(false)
  const [wegloLanguages, setWeglotLanguages] = useState<Array<any>>([
    { lang: "", domain: "" },
  ])

  useEffect(() => {
    if (!state.project?.data.translations) return

    if (state.project.data.lokalise_data) {
      // console.log(state.project.data.lokalise_data);
      setToken(state.project.data.lokalise_data.app_token)
      setLanguage(state.project.data.lokalise_data.base_language)
    }

    if (
      state.project!.data.translations &&
      state.project!.data.translations.includes("lokalise")
    )
      setIsLokalise(true)

    if (
      state.project!.data.translations &&
      state.project!.data.translations.includes("weglot")
    )
      setIsWeglot(true)

    if (state.project!.data.weglot_data) {
      setWeglotLanguages(state.project.data.weglot_data)
    } else {
      setWeglotLanguages([
        {
          lang: "",
          domain: "",
        },
      ])
    }
  }, [state.project])

  const setTranslation = (translation: string) => {
    if (translation === "lokalise") {
      setIsLokalise(true)
      setIsWeglot(false)
    } else if (translation === "weglot") {
      setIsLokalise(false)
      setIsWeglot(true)
    }
  }

  const addWeglotLanguage = () => {
    wegloLanguages.push({
      lang: "",
      domain: "",
    })
    setWeglotLanguages([...wegloLanguages])
  }

  const updateLanuage = (lang: string, index: number) => {
    wegloLanguages[index].lang = lang
    setWeglotLanguages([...wegloLanguages])
  }

  const updateSubdomain = (domain: string, index: number) => {
    wegloLanguages[index].domain = domain
    setWeglotLanguages([...wegloLanguages])
  }

  const enableWeglot = () => {
    setLoading(true)
    projectClient.enableWeglot(state.project!.id!, wegloLanguages).then(
      (project: Project) => {
        dispatch({ type: Actions.UPDATE_PROJECT, payload: project })
        setLoading(false)
      },
      (error: string) => {
        message.error(error)
        setLoading(false)
      }
    )
  }

  const enableLokalise = () => {
    if (token.length <= 0) {
      message.error("Please enter Lokalise Token.")
      return
    }

    if (language.length <= 0) {
      message.error("Please select Base Language.")
      return
    }

    let data = {
      lokalise_token: token,
      base_language: language,
    }

    setLoading(true)
    projectClient.createLokaliseProject(state.project!.id!, data).then(
      (project: Project) => {
        dispatch({ type: Actions.UPDATE_PROJECT, payload: project })
        setLoading(false)
      },
      (error: string) => {
        message.error(error)
        setLoading(false)
      }
    )
  }

  // console.log(state.project!)

  return (
    <div>
      <div className="w-tab-content">
        <div
          data-w-tab="Tab 1"
          className="w-tab-pane w--tab-active"
          id="w-tabs-0-data-w-pane-0"
          role="tabpanel"
          aria-labelledby="w-tabs-0-data-w-tab-0"
        >
          <div className="extensions-tab-content-wrapper">
            {!isWeglot && (
              <div className="translation-single-item">
                <div className="translation-single-item-name-wrapper">
                  <div className="icon-wrapper">
                    <img
                      loading="lazy"
                      src="https://assets.website-files.com/625e72e3b276d01282f085dd/62b46ebed10f4041f983fe15_image%2066.svg"
                      alt=""
                      className="object-fit-cover"
                    />
                  </div>
                  <div className="translation-single-item-text">Weglot</div>
                </div>
                <button
                  className="button-black w-button"
                  onClick={() => setTranslation("weglot")}
                >
                  Connect Weglot
                </button>
              </div>
            )}

            {/* <form id="start-new-project" name="wf-form-Start-new-project" data-name="Start new project" method="get">
              <div className="margin-bottom-30">
                <div className="weglot-form">
                  <div>Language</div>
                  <div>Sub-Domain</div>
                </div>
                {wegloLanguages.map((language: any, index: number) => {
                  return (
                    <div className="weglot-form">
                      <div>
                        <Select value={language.lang} onChange={(val) => updateLanuage(val, index)} style={{ width: "100%" }}
                          showSearch filterOption={(input, option) =>
                            (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                          }>
                          {languagesList().map((lang: any) => {
                            return (<Select.Option key={lang.code} value={lang.code}>{lang.name}</Select.Option>)
                          })}
                        </Select>
                      </div>
                      <div>
                        <input type="text" className="popup-modal-input-field w-input" name="subdomain"
                          value={language.domain} onChange={(e) => updateSubdomain(e.target.value, index)} data-name="Sub-Domain"
                          placeholder="Enter Sub-Domain..." id="Sub-Domain" />
                      </div>
                    </div>
                  )
                })}
                <a href="#" onClick={addWeglotLanguage} className="add-source-link w-inline-block" style={{ width: 200 }}>
                  <img src={Plus} loading="lazy" alt="" className="image" />
                  <div>Add Language</div>
                </a>
              </div>
              <Button label="Enable Weglot" fontSize={24} loading={loading} onClick={enableWeglot} />
            </form> */}

            {isWeglot && (
              <div className="translation-single-item-opened">
                <div className="translation-single-item-left">
                  <div className="translation-single-item-name-wrapper-opened">
                    <div className="icon-wrapper">
                      <img
                        loading="lazy"
                        src="https://assets.website-files.com/625e72e3b276d01282f085dd/62b46ebed10f4041f983fe15_image%2066.svg"
                        alt=""
                        className="object-fit-cover"
                      />
                    </div>
                    <div className="translation-single-item-text">Weglot</div>
                  </div>
                </div>
                <div className="translation-single-item-right">
                  <div className="translation-single-item-wrapper">
                    <div
                      id="w-node-_3cbaa639-9d41-91d9-eda6-98cdc1ce7e61-907a86d7"
                      className="repository-name-wrapper"
                    >
                      <div className="settings-grid-item-text">Language</div>
                    </div>
                    <div
                      id="w-node-_3cbaa639-9d41-91d9-eda6-98cdc1ce7e64-907a86d7"
                      className="repository-name-wrapper"
                    >
                      <div className="settings-grid-item-text">Sub-Domain</div>
                    </div>
                  </div>
                  {wegloLanguages.map((language: any, index: number) => {
                    return (
                      <div
                        className="translation-single-item-wrapper"
                        key={index}
                      >
                        <div
                          id="w-node-_2ceecf5a-ebc0-f1ad-9005-582cc34c8eec-907a86d7"
                          className="repository-name-wrapper"
                        >
                          {/* <Select
                            value={language.lang}
                            onChange={(val) => updateLanuage(val, index)}
                            className="no-margin"
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                              (option!.children as unknown as string)
                                .toLowerCase()
                                .startsWith(input.toLowerCase())
                            }
                          >
                            {languagesList().map((lang: any) => {
                              return (
                                <Select.Option
                                  key={lang.code}
                                  value={lang.code}
                                >
                                  {lang.name}
                                </Select.Option>
                              )
                            })}
                          </Select> */}
                          <input
                            type="text"
                            className="no-margin w-input"
                            name="subdomain"
                            value={language.lang}
                            onChange={(e) =>
                              updateLanuage(e.target.value, index)
                            }
                            data-name="Lang-ISO"
                            placeholder="Enter Language ISO..."
                            id="Lang-ISO"
                          />
                        </div>
                        <div>
                          <input
                            type="text"
                            className="no-margin w-input"
                            name="subdomain"
                            value={language.domain}
                            onChange={(e) =>
                              updateSubdomain(e.target.value, index)
                            }
                            data-name="Sub-Domain"
                            placeholder="Enter Sub-Domain..."
                            id="Sub-Domain"
                          />
                        </div>
                      </div>
                    )
                  })}
                  {/* <div className="translations-single-item-wrapper">
                    <div>Español</div>
                  </div>
                  <div id="w-node-_2ceecf5a-ebc0-f1ad-9005-582cc34c8eef-907a86d7" className="repository-name-wrapper">
                    <div>es.domain.com</div>
                  </div>
                  <div id="w-node-_2ceecf5a-ebc0-f1ad-9005-582cc34c8ef2-907a86d7" className="substitution-button-wrapper-copy"><a href="#" className="w-inline-block"><img loading="lazy" src="https://assets.website-files.com/625e72e3b276d01282f085dd/62b45ea0315e3471fc12cb99_trash-2%201.svg" alt="" /></a></div>
                </div> */}
                  <div className="translation-single-item-wrapper">
                    <div
                      id="w-node-_3cbaa639-9d41-91d9-eda6-98cdc1ce7e61-907a86d7"
                      className="repository-name-wrapper"
                    >
                      <div className="settings-grid-item-text"></div>
                    </div>
                    <div
                      id="w-node-_3cbaa639-9d41-91d9-eda6-98cdc1ce7e65-907a86d7"
                      className="substitution-button-wrapper-copy"
                    >
                      <button
                        onClick={addWeglotLanguage}
                        className="add-source-link w-inline-block"
                      >
                        <div>+ Add Language</div>
                      </button>
                    </div>
                  </div>

                  <div className="separator-line margin-bottom-30"></div>

                  <Button
                    label="Enable Weglot"
                    className="button-black w-button"
                    loading={loading}
                    onClick={enableWeglot}
                  />
                </div>
              </div>
            )}

            {/* {!isLokalise && (
              <div className="translation-single-item">
                <div className="translation-single-item-name-wrapper">
                  <div className="icon-wrapper">
                    <img
                      loading="lazy"
                      src="https://assets.website-files.com/625e72e3b276d01282f085dd/62b46df6f856a67f3c4f71a0_image%2067.svg"
                      alt=""
                      className="object-fit-cover"
                    />
                  </div>
                  <div className="translation-single-item-text">Lokalise</div>
                </div>
                <button
                  className="button-black w-button"
                  onClick={() => setTranslation("lokalise")}
                >
                  Connect Lokalise
                </button>
              </div>
            )} */}
            {/* {isLokalise && (
              <div className="translation-single-item-opened">
                <div className="translation-single-item-left">
                  <div className="translation-single-item-name-wrapper-opened">
                    <div className="icon-wrapper">
                      <img
                        loading="lazy"
                        src="https://assets.website-files.com/625e72e3b276d01282f085dd/62b46df6f856a67f3c4f71a0_image%2067.svg"
                        alt=""
                        className="object-fit-cover"
                      />
                    </div>
                    <div className="translation-single-item-text">Lokalise</div>
                  </div>
                </div>
                <div className="translation-single-item-right">
                  <div className="w-form">
                    <div className="other-single-item-wrapper">
                      <div
                        id="w-node-cfc9a258-12ca-a1d2-d884-e1b1f9f3c7c2-907a86d7"
                        className="repository-name-wrapper"
                      >
                        <div>Base language</div>
                      </div>
                      <div
                        id="w-node-cfc9a258-12ca-a1d2-d884-e1b1f9f3c7c5-907a86d7"
                        className="repository-name-wrapper"
                      >
                        <Select
                          value={language}
                          onChange={(val) => setLanguage(val)}
                          style={{ width: "100%" }}
                          showSearch
                          filterOption={(input, option) =>
                            (option!.children as unknown as string)
                              .toLowerCase()
                              .startsWith(input.toLowerCase())
                          }
                        >
                          {languagesList().map((lang: any) => {
                            return (
                              <Select.Option key={lang.code} value={lang.code}>
                                {lang.name}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </div>
                    </div>
                    <div className="other-single-item-wrapper">
                      <div
                        id="w-node-cfc9a258-12ca-a1d2-d884-e1b1f9f3c7c2-907a86d7"
                        className="repository-name-wrapper"
                      >
                        <div>API Token</div>
                      </div>
                      <div
                        id="w-node-cfc9a258-12ca-a1d2-d884-e1b1f9f3c7c5-907a86d7"
                        className="repository-name-wrapper"
                      >
                        <input
                          type="text"
                          className="w-input"
                          name="App-Token"
                          value={token}
                          onChange={(e) => setToken(e.target.value)}
                          data-name="App Token"
                          placeholder="Enter App Token..."
                          id="App-Token"
                        />
                      </div>
                    </div>
                    <div className="other-single-item-wrapper">
                      <div
                        id="w-node-cfc9a258-12ca-a1d2-d884-e1b1f9f3c7c2-907a86d7"
                        className="repository-name-wrapper"
                      >
                        <div></div>
                      </div>
                      <div
                        id="w-node-cfc9a258-12ca-a1d2-d884-e1b1f9f3c7c5-907a86d7"
                        className="repository-name-wrapper"
                      >
                        <Button
                          label="Enable Lokalise"
                          className="button-black w-button"
                          loading={loading}
                          onClick={enableLokalise}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TranslationSettings
