import { Project, ProjectSource } from "@/models/Project"
import React, { useEffect, useState } from "react"
import { Button, Modal } from "@/components/common"
import { Checkbox, Tabs, message, Radio, Select, Upload } from "antd"
import ProjectSourceClient from "@/clients/ProjectSourceClient"
import JWTHelper from "@/utilities/JWTHelper"
import { UploadOutlined } from "@ant-design/icons"

interface EditSourceModalProps {
  source: ProjectSource
  show: boolean
  onClose: any
  project: Project
  onUpdate: any
}

const { TabPane } = Tabs

const EditSourceModal: React.ComponentType<EditSourceModalProps> = ({
  show,
  onClose,
  project,
  onUpdate,
  source,
}) => {
  const sourceClient = new ProjectSourceClient()
  const [sourceName, setSourceName] = useState("")
  const [domain, setDomain] = useState("")
  const [homePageUrl, setHomePageUrl] = useState("")
  const [siteMapUrl, setSiteMapUrl] = useState("")
  const [hasPassword, setHasPassword] = useState(false)
  const [password, setPassword] = useState("")
  const [sourceType, setSourceType] = useState("advanced")
  const [sitemapType, setSitemapType] = useState("url")
  const [clonePages, setClonePages] = useState(false)
  const [loading, setLoading] = useState(false)
  const [csvUploading, setCsvUploading] = useState(false)

  useEffect(() => {
    if (source) {
      setSourceName(source.name)
      setDomain(source.domain)
      setHomePageUrl(source.url_homepage)
      setSiteMapUrl(source.url_sitemap!)
      setHasPassword(source.has_password! === 1)
      setPassword(source.password!)
      setClonePages(source.clone_pages!)
    }
  }, [source])

  const onUpload: any = (info: any) => {
    if (info.file.status === "done") {
      setCsvUploading(false)
      setSiteMapUrl(info.file.response.url)
    } else if (info.file.status === "error") {
      setCsvUploading(false)
      message.error("Unable to upload CSV file. Please try again.")
    }
  }

  const changeSourceType = (val: any) => {
    setSourceType(val)

    if (val === "webflow" && !sourceName) {
      setDomain(`${sourceName}.webflow.io`)
      setHomePageUrl(`https://${sourceName}.webflow.io/`)
      setSiteMapUrl(`https://${sourceName}.webflow.io/sitemap.xml`)
    }

    if (val === "other" && !sourceName) {
      setDomain("")
      setHomePageUrl("")
      setSiteMapUrl("")
    }
  }

  const updateSource = () => {
    if (sourceName.length === 0) {
      message.error("Please enter source name")
      return
    }

    if (domain.length === 0) {
      message.error("Please enter domain name")
      return
    }

    if (homePageUrl.length === 0) {
      setHomePageUrl("Please enter homepage url")
      return
    }

    if (hasPassword && password.length === 0) {
      message.error("Please enter site password.")
      return
    }

    // if (this.hasPassword && this.password.length === 0) {
    //   this.error = "Please enter site password.";
    //   return;
    // }

    setLoading(true)
    sourceClient
      .editSource(project.id!, source.id!, {
        project_id: project.id,
        name: sourceName,
        domain: domain,
        url_homepage: homePageUrl,
        url_sitemap: siteMapUrl,
        source_service: sourceName,
        has_password: hasPassword,
        password: password,
        clone_pages: clonePages
      })
      .then(
        (source: ProjectSource) => {
          onUpdate(source)
          // setSourceName("");
          // setDomain("");
          // setHomePageUrl("");
          // setSiteMapUrl("");
        },
        (error) => {
          message.error(error)
        }
      )
      .finally(() => setLoading(false))
  }

  if (!source) return null

  return (
    <Modal show={show} onClose={onClose}>
      <div className="margin-bottom-40">
        <h2 className="page-heading">Edit Project Source</h2>
      </div>
      <div className="popup-modal-form-block w-form">
        <form
          id="start-new-project"
          name="wf-form-Start-new-project"
          data-name="Start new project"
          method="get"
        >
          <div className="margin-bottom-20">
            <Tabs defaultActiveKey="advanced">
              <TabPane
                tab={
                  <div
                    className="extensions-tab-link w-inline-block w-tab-link"
                    onClick={() => changeSourceType("webflow")}
                  >
                    Webflow
                  </div>
                }
                key="webflow"
              >
                <div>
                  <div className="margin-bottom-20">
                    <label className="popup-modal-field-label">
                      Webflow Project Name
                    </label>
                    <div className="project-name-input">
                      <input
                        type="text"
                        className="popup-modal-input-field w-input"
                        name="Project-Name"
                        value={sourceName}
                        onChange={(e) => setSourceName(e.target.value)}
                        data-name="Weblfow Project Name"
                        placeholder="webflow-project"
                        id="Project-Name"
                      />
                      <span>.webflow.io</span>
                    </div>
                  </div>
                  <label className="popup-modal-field-label"></label>
                  <Radio
                    checked={clonePages}
                    onClick={() => setClonePages(true)}
                  >
                    Clone all pages
                  </Radio>
                  <Radio
                    checked={!clonePages}
                    onClick={() => setClonePages(false)}
                  >
                    Clone selected pages
                  </Radio>
                  <label className="popup-modal-field-label">
                    <Checkbox
                      checked={hasPassword}
                      onClick={() => setHasPassword(!hasPassword)}
                    />{" "}
                    Has Password
                  </label>
                  {hasPassword === true && (
                    <div>
                      <label className="popup-modal-field-label">
                        Site Password
                      </label>
                      <input
                        type="text"
                        className="popup-modal-input-field w-input"
                        name="Alias"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        data-name="Alias"
                        placeholder=""
                        id="Alias"
                        required
                      />
                    </div>
                  )}
                </div>
              </TabPane>
              <TabPane
                tab={
                  <div
                    className="extensions-tab-link w-inline-block w-tab-link"
                    onClick={() => changeSourceType("advanced")}
                  >
                    Advanced
                  </div>
                }
                key="advanced"
              >
                <div>
                  <label className="popup-modal-field-label">Name</label>
                  <input
                    type="text"
                    className="popup-modal-input-field w-input"
                    name="Project-Name"
                    value={sourceName}
                    onChange={(e) => setSourceName(e.target.value)}
                    data-name="Project Name"
                    placeholder="Project Name"
                    id="Project-Name"
                  />
                  <label className="popup-modal-field-label">Domain</label>
                  <input
                    type="text"
                    className="popup-modal-input-field w-input"
                    name="Alias"
                    value={domain}
                    onChange={(e) => setDomain(e.target.value)}
                    data-name="Alias"
                    placeholder="site.com"
                    id="Homepage-URL"
                    required
                  />
                  <label className="popup-modal-field-label">
                    Homepage Url
                  </label>
                  <input
                    type="text"
                    className="popup-modal-input-field w-input"
                    name="Alias"
                    value={homePageUrl}
                    onChange={(e) => setHomePageUrl(e.target.value)}
                    data-name="Alias"
                    placeholder="https://site.com/"
                    id="Sitemap-URL"
                    required
                  />
                  <label className="popup-modal-field-label">
                    Sitemap Type
                  </label>
                  <Radio
                    checked={sitemapType === "url"}
                    onClick={() => setSitemapType("url")}
                  >
                    URL
                  </Radio>
                  <Radio
                    checked={sitemapType === "csv"}
                    onClick={() => setSitemapType("csv")}
                  >
                    CSV
                  </Radio>
                  <label className="popup-modal-field-label">Sitemap Url</label>
                  {sitemapType === "url" && (
                    <React.Fragment>
                      <input
                        type="text"
                        className="popup-modal-input-field w-input"
                        name="Alias"
                        value={siteMapUrl}
                        onChange={(e) => setSiteMapUrl(e.target.value)}
                        data-name="Alias"
                        placeholder="https://site.com/sitemap.xml"
                        id="Alias"
                        required
                      />
                    </React.Fragment>
                  )}
                  {sitemapType === "csv" && (
                    <Upload
                      name="sitemap"
                      accept=".csv"
                      action={
                        process.env.REACT_APP_API_BASE_URL +
                        "/source/upload/csv"
                      }
                      method="POST"
                      headers={{
                        Authorization: `Bearer ${JWTHelper.getAccessToken()}`,
                      }}
                      beforeUpload={() => setCsvUploading(true)}
                      onChange={onUpload}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        label="Upload CSV"
                        loading={csvUploading}
                      />
                    </Upload>
                  )}
                  <label className="popup-modal-field-label"></label>
                  <Radio
                    checked={clonePages}
                    onClick={() => setClonePages(true)}
                  >
                    Clone all pages
                  </Radio>
                  <Radio
                    checked={!clonePages}
                    onClick={() => setClonePages(false)}
                  >
                    Clone selected pages
                  </Radio>
                  <label className="popup-modal-field-label">
                    <Checkbox
                      checked={hasPassword}
                      onClick={() => setHasPassword(!hasPassword)}
                    />{" "}
                    Has Password
                  </label>
                  {hasPassword === true && (
                    <div>
                      <label className="popup-modal-field-label">
                        Site Password
                      </label>
                      <input
                        type="text"
                        className="popup-modal-input-field w-input"
                        name="Alias"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        data-name="Alias"
                        placeholder=""
                        id="Alias"
                        required
                      />
                    </div>
                  )}
                </div>
              </TabPane>
            </Tabs>
          </div>
          <Button
            label="Update Source"
            fontSize={24}
            loading={loading}
            onClick={updateSource}
          />
        </form>
      </div>
    </Modal>
  )
}

export default EditSourceModal
