import React, { useState } from "react"
import CloseIcon from "@/resources/images/62614c167edf83cf5be4e62a_close-icon.svg"
import ProfileInformation from "@/components/usersettings/ProfileInformation"
import ChangePassword from "@/components/usersettings/ChangePassword"
import CreateUser from "@/components/usersettings/CreateUser"

interface AccountSettingsModalProps {
  show: boolean
  onClose: any
}

const AccountSettingsModal: React.ComponentType<AccountSettingsModalProps> = ({
  show,
  onClose,
}) => {
  const [activeTab, setActiveTab] = useState("info")

  const openTab = (tab: string) => {
    setActiveTab(tab)
  }

  return (
    <div
      style={{ display: show ? "flex" : "none" }}
      className="popup-modal-wrapper"
    >
      <div className="close-all-popup-modals"></div>
      <div className={"user-popup-modal" + (show ? " show" : " hidden")}>
        <div className="user-popup-modal-top-content">
          <h2 className="page-heading">Account Settings</h2>
        </div>
        <div className="user-popup-modal-bottom-content">
          <div
            data-current="Tab 1"
            data-easing="ease"
            data-duration-in="300"
            data-duration-out="100"
            className="user-popup-tabs w-tabs"
          >
            <div className="user-popup-tabs-menu w-tab-menu">
              <a
                data-w-tab="Tab 1"
                onClick={() => openTab("info")}
                className={
                  "user-tab-link w-inline-block w-tab-link" +
                  (activeTab === "info" ? " w--current" : "")
                }
              >
                <div className="user-popup-tab-button">
                  <div className="w-embed">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.3332 14V12.6667C13.3332 11.9594 13.0522 11.2811 12.5521 10.781C12.052 10.281 11.3737 10 10.6665 10H5.33317C4.62593 10 3.94765 10.281 3.44755 10.781C2.94746 11.2811 2.6665 11.9594 2.6665 12.6667V14"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.00016 7.33333C9.47292 7.33333 10.6668 6.13943 10.6668 4.66667C10.6668 3.19391 9.47292 2 8.00016 2C6.5274 2 5.3335 3.19391 5.3335 4.66667C5.3335 6.13943 6.5274 7.33333 8.00016 7.33333Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div>Profile Information</div>
                </div>
              </a>
              <a
                data-w-tab="Tab 2"
                onClick={() => openTab("password")}
                className={
                  "user-tab-link w-inline-block w-tab-link" +
                  (activeTab === "password" ? " w--current" : "")
                }
              >
                <div className="user-popup-tab-button">
                  <div className="w-embed">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.6667 7.33301H3.33333C2.59695 7.33301 2 7.92996 2 8.66634V13.333C2 14.0694 2.59695 14.6663 3.33333 14.6663H12.6667C13.403 14.6663 14 14.0694 14 13.333V8.66634C14 7.92996 13.403 7.33301 12.6667 7.33301Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.6665 7.33301V4.66634C4.6665 3.78229 5.01769 2.93444 5.64281 2.30932C6.26794 1.6842 7.11578 1.33301 7.99984 1.33301C8.88389 1.33301 9.73174 1.6842 10.3569 2.30932C10.982 2.93444 11.3332 3.78229 11.3332 4.66634V7.33301"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div>Password</div>
                </div>
              </a>
            </div>
            <div className="w-tab-content">
              <div
                data-w-tab="Tab 1"
                className={
                  "w-tab-pane" + (activeTab === "info" ? " w--tab-active" : "")
                }
              >
                <ProfileInformation />
              </div>
              <div
                data-w-tab="Tab 2"
                className={
                  "w-tab-pane" +
                  (activeTab === "password" ? " w--tab-active" : "")
                }
              >
                <ChangePassword />
              </div>
            </div>
          </div>
        </div>
        <a
          href="#"
          className="close-popup-modal w-inline-block"
          onClick={onClose}
        >
          <img src={CloseIcon} loading="lazy" alt="" />
        </a>
      </div>
    </div>
  )
}

export default AccountSettingsModal
