import React from "react"
import AppButton, { ButtonProps } from "./Button"
import AppModal, {
  ConfirmModalProps,
  ConfirmModal as AppConfirmModal,
  ModalProps,
} from "./Modal"
import AppSpinner, { SpinnerProps } from "./Spinner"

export const Button: React.ComponentType<ButtonProps> = (
  props: ButtonProps
) => {
  return <AppButton {...props} />
}

export const Spinner: React.ComponentType<SpinnerProps> = (
  props: SpinnerProps
) => {
  return <AppSpinner {...props} />
}

export const Modal: React.ComponentType<ModalProps> = (props: ModalProps) => {
  return <AppModal {...props} />
}

export const ConfirmModal: React.ComponentType<ConfirmModalProps> = (
  props: ConfirmModalProps
) => {
  return <AppConfirmModal {...props} />
}
