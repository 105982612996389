export const languagesList = () => {
  return [
    { code: "ab", name: "Abkhaz" },
    { code: "aa", name: "Afar" },
    { code: "af", name: "Afrikaans" },
    { code: "af_NA", name: "Afrikaans (Namibia)" },
    { code: "af_ZA", name: "Afrikaans (South Africa)" },
    { code: "agq", name: "Aghem" },
    { code: "ak", name: "Akan" },
    { code: "ak_GH", name: "Akan (Ghana)" },
    { code: "sq", name: "Albanian" },
    { code: "sq_AL", name: "Albanian (Albania)" },
    { code: "am", name: "Amharic" },
    { code: "ar", name: "Arabic" },
    { code: "ar_DZ", name: "Arabic (Algeria)" },
    { code: "ar_BH", name: "Arabic (Bahrain)" },
    { code: "ar_EG", name: "Arabic (Egypt)" },
    { code: "ar_IQ", name: "Arabic (Iraq)" },
    { code: "ar_JO", name: "Arabic (Jordan)" },
    { code: "ar_KW", name: "Arabic (Kuwait)" },
    { code: "ar_LB", name: "Arabic (Lebanon)" },
    { code: "ar_LY", name: "Arabic (Libya)" },
    { code: "ar_MA", name: "Arabic (Morocco)" },
    { code: "ar_OM", name: "Arabic (Oman)" },
    { code: "ar_QA", name: "Arabic (Qatar)" },
    { code: "ar_SA", name: "Arabic (Saudi Arabia)" },
    { code: "ar_SD", name: "Arabic (Sudan)" },
    { code: "ar_SY", name: "Arabic (Syria)" },
    { code: "ar_TN", name: "Arabic (Tunisia)" },
    { code: "ar_AE", name: "Arabic (United Arab Emirates)" },
    { code: "ar_001", name: "Arabic (World)" },
    { code: "ar_YE", name: "Arabic (Yemen)" },
    { code: "an", name: "Aragonese" },
    { code: "hy", name: "Armenian" },
    { code: "as", name: "Assamese" },
    { code: "asa", name: "Asu" },
    { code: "av", name: "Avaric" },
    { code: "ae", name: "Avestan" },
    { code: "ay", name: "Aymara" },
    { code: "az", name: "Azerbaijani" },
    { code: "az_AZ", name: "Azerbaijani (Azerbaijan)" },
    { code: "az_Cyrl_AZ", name: "Azerbaijani (Cyrillic, Azerbaijan)" },
    { code: "az_Cyrl", name: "Azerbaijani (Cyrillic)" },
    { code: "azb", name: "Azerbaijani (South)" },
    { code: "ksf", name: "Bafia" },
    { code: "bm", name: "Bambara" },
    { code: "ba", name: "Bashkir" },
    { code: "eu", name: "Basque" },
    { code: "eu_ES", name: "Basque (Spain)" },
    { code: "be", name: "Belarusian" },
    { code: "be_BY", name: "Belarusian (Belarus)" },
    { code: "bem", name: "Bemba " },
    { code: "bez", name: "Bena" },
    { code: "bn", name: "Bengali" },
    { code: "bn_BD", name: "Bengali (Bangladesh)" },
    { code: "bn_IN", name: "Bengali (India)" },
    { code: "bh", name: "Bihari" },
    { code: "bi", name: "Bislama" },
    { code: "bs", name: "Bosnian" },
    { code: "bs_BA", name: "Bosnian (Bosnia and Herzegovina)" },
    { code: "brx", name: "Bodo" },
    { code: "br", name: "Breton" },
    { code: "bg", name: "Bulgarian" },
    { code: "bg_BG", name: "Bulgarian (Bulgaria)" },
    { code: "my", name: "Burmese" },
    { code: "ca", name: "Catalan; Valencian" },
    { code: "ceb", name: "Cebuano" },
    { code: "tzm", name: "Central Morocco Tamazight" },
    { code: "ch", name: "Chamorro" },
    { code: "ce", name: "Chechen" },
    { code: "chr", name: "Cherokee" },
    { code: "ny", name: "Chichewa; Chewa; Nyanja" },
    { code: "cgg", name: "Chiga" },
    { code: "zh", name: "Chinese" },
    { code: "zh_CN", name: "Chinese Simplified" },
    { code: "zh_Hans_CN", name: "Chinese Simplified (China)" },
    { code: "zh_Hans_HK", name: "Chinese Simplified (Hong Kong)" },
    { code: "zh_Hans_MO", name: "Chinese Simplified (Macau)" },
    { code: "zh_MY", name: "Chinese Simplified (Malay)" },
    { code: "zh_SG", name: "Chinese Simplified (Singapore)" },
    { code: "zh_Hans_SG", name: "Chinese Simplified (Singapore)" },
    { code: "zh_TW", name: "Chinese Traditional" },
    { code: "zh_HK", name: "Chinese Traditional (Hong Kong)" },
    { code: "zh_Hant_HK", name: "Chinese Traditional (Hong Kong)" },
    { code: "zh_Hant_MO", name: "Chinese Traditional (Macau)" },
    { code: "zh_Hant_TW", name: "Chinese Traditional (Taiwan)" },
    { code: "cu", name: "Church Slavic" },
    { code: "cv", name: "Chuvash" },
    { code: "swc", name: "Congo Swahili" },
    { code: "kw", name: "Cornish" },
    { code: "co", name: "Corsican" },
    { code: "cr", name: "Cree" },
    { code: "hr", name: "Croatian" },
    { code: "hr_HR", name: "Croatian (Croatia)" },
    { code: "cs", name: "Czech" },
    { code: "cs_CZ", name: "Czech (Czech Republic)" },
    { code: "da", name: "Danish" },
    { code: "da_DK", name: "Danish (Denmark)" },
    { code: "prs", name: "Dari" },
    { code: "dv", name: "Divehi; Dhivehi; Maldivian;" },
    { code: "dua", name: "Duala" },
    { code: "nl", name: "Dutch" },
    { code: "nl_AW", name: "Dutch (Aruba)" },
    { code: "nl_BE", name: "Dutch (Belgium)" },
    { code: "nl_CW", name: "Dutch (Curaçao)" },
    { code: "nl_LU", name: "Dutch (Luxembourg)" },
    { code: "nl_NL", name: "Dutch (Netherlands)" },
    { code: "nl_SX", name: "Dutch (Sint Maarten)" },
    { code: "en", name: "English" },
    { code: "en_AS", name: "English (American Samoa)" },
    { code: "en_AU", name: "English (Australia)" },
    { code: "en_BB", name: "English (Barbados)" },
    { code: "en_BE", name: "English (Belgium)" },
    { code: "en_BZ", name: "English (Belize)" },
    { code: "en_BM", name: "English (Bermuda)" },
    { code: "en_BW", name: "English (Botswana)" },
    { code: "en_CA", name: "English (Canada)" },
    { code: "en_DK", name: "English (Denmark)" },
    { code: "en_FI", name: "English (Finland)" },
    { code: "en_FR", name: "English (France)" },
    { code: "en_GU", name: "English (Guam)" },
    { code: "en_GY", name: "English (Guyana)" },
    { code: "en_HK", name: "English (Hong Kong SAR China)" },
    { code: "en_IN", name: "English (India)" },
    { code: "en_ID", name: "English (Indonesia)" },
    { code: "en_IE", name: "English (Ireland)" },
    { code: "en_IL", name: "English (Israel)" },
    { code: "en_JM", name: "English (Jamaica)" },
    { code: "en_JP", name: "English (Japan)" },
    { code: "en_KR", name: "English (Korea)" },
    { code: "en_LU", name: "English (Luxembourg)" },
    { code: "en_MO", name: "English (Macau)" },
    { code: "en_MY", name: "English (Malaysia)" },
    { code: "en_MT", name: "English (Malta)" },
    { code: "en_MH", name: "English (Marshall Islands)" },
    { code: "en_MU", name: "English (Mauritius)" },
    { code: "en_MX", name: "English (Mexico)" },
    { code: "en_NA", name: "English (Namibia)" },
    { code: "en_NL", name: "English (Netherlands)" },
    { code: "en_NZ", name: "English (New Zealand)" },
    { code: "en_MP", name: "English (Northern Mariana Islands)" },
    { code: "en_NO", name: "English (Norway)" },
    { code: "en_PK", name: "English (Pakistan)" },
    { code: "en_PH", name: "English (Philippines)" },
    { code: "en_SG", name: "English (Singapore)" },
    { code: "en_ZA", name: "English (South Africa)" },
    { code: "en_ES", name: "English (Spain)" },
    { code: "en_SE", name: "English (Sweden)" },
    { code: "en_CH", name: "English (Switzerland)" },
    { code: "en_TW", name: "English (Taiwan)" },
    { code: "en_TH", name: "English (Thailand)" },
    { code: "en_TT", name: "English (Trinidad and Tobago)" },
    { code: "en_UM", name: "English (U.S. Minor Outlying Islands)" },
    { code: "en_VI", name: "English (U.S. Virgin Islands)" },
    { code: "en_AE", name: "English (United Arab Emirates)" },
    { code: "en_GB", name: "English (United Kingdom)" },
    { code: "en_US", name: "English (United States)" },
    { code: "en_ZW", name: "English (Zimbabwe)" },
    { code: "eo", name: "Esperanto" },
    { code: "et", name: "Estonian" },
    { code: "et_EE", name: "Estonian (Estonia)" },
    { code: "ee", name: "Ewe" },
    { code: "ee_GH", name: "Ewe (Ghana)" },
    { code: "ee_TG", name: "Ewe (Togo)" },
    { code: "ewo", name: "Ewondo" },
    { code: "fo", name: "Faroese" },
    { code: "fj", name: "Fijian" },
    { code: "fi", name: "Finnish" },
    { code: "fil", name: "Filipino" },
    { code: "fr", name: "French" },
    { code: "fr_BE", name: "French (Belgium)" },
    { code: "fr_BJ", name: "French (Benin)" },
    { code: "fr_BF", name: "French (Burkina Faso)" },
    { code: "fr_BI", name: "French (Burundi)" },
    { code: "fr_CM", name: "French (Cameroon)" },
    { code: "fr_CA", name: "French (Canada)" },
    { code: "fr_CF", name: "French (Central African Republic)" },
    { code: "fr_TD", name: "French (Chad)" },
    { code: "fr_KM", name: "French (Comoros)" },
    { code: "fr_CD", name: "French (Congo)" },
    { code: "fr_CI", name: "French (Côte d’Ivoire)" },
    { code: "fr_DJ", name: "French (Djibouti)" },
    { code: "fr_GQ", name: "French (Equatorial Guinea)" },
    { code: "fr_FR", name: "French (France)" },
    { code: "fr_GF", name: "French (French Guiana)" },
    { code: "fr_GA", name: "French (Gabon)" },
    { code: "fr_GP", name: "French (Guadeloupe)" },
    { code: "fr_GN", name: "French (Guinea)" },
    { code: "fr_LU", name: "French (Luxembourg)" },
    { code: "fr_MG", name: "French (Madagascar)" },
    { code: "fr_ML", name: "French (Mali)" },
    { code: "fr_MQ", name: "French (Martinique)" },
    { code: "fr_YT", name: "French (Mayotte)" },
    { code: "fr_MC", name: "French (Monaco)" },
    { code: "fr_MA", name: "French (Morocco)" },
    { code: "fr_NE", name: "French (Niger)" },
    { code: "fr_RE", name: "French (Réunion)" },
    { code: "fr_RW", name: "French (Rwanda)" },
    { code: "fr_BL", name: "French (Saint Barthélemy)" },
    { code: "fr_MF", name: "French (Saint Martin)" },
    { code: "fr_SN", name: "French (Senegal)" },
    { code: "fr_CH", name: "French (Switzerland)" },
    { code: "fr_TG", name: "French (Togo)" },
    { code: "fy_NL", name: "Frisian" },
    { code: "ff", name: "Fula; Fulah; Pulaar; Pular" },
    { code: "gl", name: "Galician" },
    { code: "lg", name: "Ganda" },
    { code: "ka", name: "Georgian" },
    { code: "de", name: "German" },
    { code: "de_AT", name: "German (Austria)" },
    { code: "de_BE", name: "German (Belgium)" },
    { code: "de_DE", name: "German (Germany)" },
    { code: "de_LI", name: "German (Liechtenstein)" },
    { code: "de_LU", name: "German (Luxembourg)" },
    { code: "de_CH", name: "German (Switzerland)" },
    { code: "el", name: "Greek, Modern" },
    { code: "el_CY", name: "Greek (Cyprus)" },
    { code: "el_GR", name: "Greek (Greece)" },
    { code: "gn", name: "Guaraní" },
    { code: "gu", name: "Gujarati" },
    { code: "guz", name: "Gusii" },
    { code: "ht", name: "Haitian; Haitian Creole" },
    { code: "ha", name: "Hausa" },
    { code: "ha_GH", name: "Hausa (Ghana)" },
    { code: "ha_NE", name: "Hausa (Niger)" },
    { code: "ha_NG", name: "Hausa (Nigeria)" },
    { code: "haw", name: "Hawaiian" },
    { code: "he", name: "Hebrew (modern)" },
    { code: "he_IL", name: "Hebrew (Israel)" },
    { code: "hz", name: "Herero" },
    { code: "hi", name: "Hindi" },
    { code: "hi_IN", name: "Hindi (India)" },
    { code: "ho", name: "Hiri Motu" },
    { code: "hu", name: "Hungarian" },
    { code: "hu_HU", name: "Hungarian (Hungary)" },
    { code: "hmn", name: "Hmong" },
    { code: "ia", name: "Interlingua" },
    { code: "ie", name: "Interlingue" },
    { code: "id", name: "Indonesian" },
    { code: "id_ID", name: "Indonesian (Indonesia)" },
    { code: "ga", name: "Irish" },
    { code: "ig", name: "Igbo" },
    { code: "ik", name: "Inupiaq" },
    { code: "io", name: "Ido" },
    { code: "is", name: "Icelandic" },
    { code: "is_IS", name: "Icelandic (Iceland)" },
    { code: "it", name: "Italian" },
    { code: "it_IT", name: "Italian (Italy)" },
    { code: "it_CH", name: "Italian (Switzerland)" },
    { code: "iu", name: "Inuktitut" },
    { code: "ja", name: "Japanese" },
    { code: "ja_JP", name: "Japanese (Japan)" },
    { code: "jv", name: "Javanese" },
    { code: "kl", name: "Kalaallisut, Greenlandic" },
    { code: "kea", name: "Kabuverdianu" },
    { code: "kab", name: "Kabyle" },
    { code: "kab", name: "Kalenjin" },
    { code: "kam", name: "Kamba" },
    { code: "kln", name: "Kannada" },
    { code: "kn_IN", name: "Kannada (India)" },
    { code: "kr", name: "Kanuri" },
    { code: "ks", name: "Kashmiri" },
    { code: "kk", name: "Kazakh" },
    { code: "km", name: "Khmer" },
    { code: "ki", name: "Kikuyu, Gikuyu" },
    { code: "rw", name: "Kinyarwanda" },
    { code: "ky", name: "Kirghiz, Kyrgyz" },
    { code: "tlh_KL", name: "Klingon" },
    { code: "kv", name: "Komi" },
    { code: "kg", name: "Kongo" },
    { code: "kok", name: "Konkani" },
    { code: "koo", name: "Konzo" },
    { code: "ko", name: "Korean" },
    { code: "ko_KR", name: "Korean (Korea)" },
    { code: "ku", name: "Kurdish" },
    { code: "ku_BA", name: "Kurdish (Badini)" },
    { code: "ku_KRM", name: "Kurdish (Kurmanji)" },
    { code: "ku_CKB", name: "Kurdish (Sorani)" },
    { code: "sdh", name: "Kurdish (Southern)" },
    { code: "kj", name: "Kwanyama, Kuanyama" },
    { code: "nmg", name: "Kwasio" },
    { code: "ky", name: "Kyrgyz " },
    { code: "lag", name: "Langi" },
    { code: "la", name: "Latin" },
    { code: "lb", name: "Luxembourgish, Letzeburgesch" },
    { code: "lg", name: "Luganda" },
    { code: "li", name: "Limburgish, Limburgan, Limburger" },
    { code: "ln", name: "Lingala" },
    { code: "lo", name: "Lao" },
    { code: "lt", name: "Lithuanian" },
    { code: "lt_LT", name: "Lithuanian (Lithuania)" },
    { code: "lu", name: "Luba-Katanga" },
    { code: "lv", name: "Latvian" },
    { code: "lv_LV", name: "Latvian (Latvia)" },
    { code: "luy", name: "Luyia" },
    { code: "gv", name: "Manx" },
    { code: "mk", name: "Macedonian" },
    { code: "mk_MK", name: "Macedonian (Macedonia)" },
    { code: "jmc", name: "Machame" },
    { code: "mgh", name: "Makhuwa-Meetto" },
    { code: "kde", name: "Makonde" },
    { code: "mg", name: "Malagasy" },
    { code: "ms", name: "Malay" },
    { code: "ms_BN", name: "Malay (Brunei)" },
    { code: "ms_MY", name: "Malay (Malaysia)" },
    { code: "ms_SG", name: "Malay (Singapore)" },
    { code: "ml", name: "Malayalam" },
    { code: "ml_IN", name: "Malayalam (India)" },
    { code: "mt", name: "Maltese" },
    { code: "mt_MT", name: "Maltese (Malta)" },
    { code: "mi", name: "Maori" },
    { code: "mr", name: "Marathi (Marāṭhī)" },
    { code: "mh", name: "Marshallese" },
    { code: "myx", name: "Masaaba" },
    { code: "mas", name: "Masai" },
    { code: "mas_KE", name: "Masai (Kenya)" },
    { code: "mas_TZ", name: "Masai (Tanzania)" },
    { code: "mer", name: "Meru" },
    { code: "mn", name: "Mongolian" },
    { code: "mn_MN", name: "Mongolian (Mongolia)" },
    { code: "mfe", name: "Morisyen" },
    { code: "mua", name: "Mundang" },
    { code: "naq", name: "Nama" },
    { code: "na", name: "Nauru" },
    { code: "nv", name: "Navajo, Navaho" },
    { code: "nb", name: "Norwegian Bokmål" },
    { code: "nb_NO", name: "Norwegian Bokmål (Norway)" },
    { code: "ne", name: "Nepali" },
    { code: "ne_IN", name: "Nepali (India)" },
    { code: "ne_NP", name: "Nepali (Nepal)" },
    { code: "nd", name: "North Ndebele" },
    { code: "nap_IT", name: "Neapolitan" },
    { code: "ndc", name: "Ndau" },
    { code: "ng", name: "Ndonga" },
    { code: "nn", name: "Norwegian Nynorsk" },
    { code: "nn_NO", name: "Norwegian Nynorsk (Norway)" },
    { code: "no", name: "Norwegian" },
    { code: "nus", name: "Nuer" },
    { code: "nyn", name: "Nyankole" },
    { code: "ii", name: "Nuosu" },
    { code: "nr", name: "South Ndebele" },
    { code: "oc", name: "Occitan" },
    { code: "oj", name: "Ojibwe, Ojibwa" },
    { code: "om", name: "Oromo" },
    { code: "om_ET", name: "Oromo (Ethiopia)" },
    { code: "om_KE", name: "Oromo (Kenya)" },
    { code: "or", name: "Oriya" },
    { code: "or_IN", name: "Oriya (India)" },
    { code: "os", name: "Ossetian, Ossetic" },
    { code: "pa", name: "Panjabi, Punjabi" },
    { code: "pa_Arab_PK", name: "Punjabi (Arabic, Pakistan)" },
    { code: "pa_Arab", name: "Punjabi (Arabic)" },
    { code: "pa_IN", name: "Punjabi (India)" },
    { code: "pi", name: "Pāli" },
    { code: "fa", name: "Persian" },
    { code: "fa_AF", name: "Persian (Afghanistan)" },
    { code: "fa_IR", name: "Persian (Iran)" },
    { code: "pl", name: "Polish" },
    { code: "pl_PL", name: "Polish (Poland)" },
    { code: "ps", name: "Pashto, Pushto" },
    { code: "ps_AF", name: "Pashto (Afghanistan)" },
    { code: "pt", name: "Portuguese" },
    { code: "pt_AO", name: "Portuguese (Angola)" },
    { code: "pt_BR", name: "Portuguese (Brazil)" },
    { code: "pt_GN", name: "Portuguese (Guinea)" },
    { code: "pt_MO", name: "Portuguese (Macau)" },
    { code: "pt_MZ", name: "Portuguese (Mozambique)" },
    { code: "pt_PT", name: "Portuguese (Portugal)" },
    { code: "pt_ST", name: "Portuguese (São Tomé and Príncipe)" },
    { code: "qu", name: "Quechua" },
    { code: "rm", name: "Romansh" },
    { code: "rn", name: "Kirundi" },
    { code: "ro", name: "Romanian" },
    { code: "ro_MD", name: "Romanian (Moldova)" },
    { code: "ro_RO", name: "Romanian (Romania)" },
    { code: "ru", name: "Russian" },
    { code: "ru_AZ", name: "Russian (Azerbaijani)" },
    { code: "ru_BY", name: "Russian (Belarus)" },
    { code: "ru_IL", name: "Russian (Israel)" },
    { code: "ru_MD", name: "Russian (Moldova)" },
    { code: "ru_RU", name: "Russian (Russia)" },
    { code: "ru_UA", name: "Russian (Ukraine)" },
    { code: "rwk", name: "Rwa" },
    { code: "sa", name: "Sanskrit (Saṁskṛta)" },
    { code: "sc", name: "Sardinian" },
    { code: "sd", name: "Sindhi" },
    { code: "se", name: "Northern Sami" },
    { code: "saq", name: "Samburu" },
    { code: "sm", name: "Samoan" },
    { code: "sg", name: "Sango" },
    { code: "sbp", name: "Sangu" },
    { code: "skr", name: "Saraiki" },
    { code: "seh", name: "Sena" },
    { code: "swk", name: "Sena (Malawi)" },
    { code: "sr", name: "Serbian" },
    { code: "sr_BA", name: "Serbian (Bosnia and Herzegovina)" },
    { code: "sr_Latn_BA", name: "Serbian (Latin, Bosnia and Herzegovina)" },
    { code: "sr_Latn_ME", name: "Serbian (Latin, Montenegro)" },
    { code: "sr_Latn_RS", name: "Serbian (Latin, Serbia)" },
    { code: "sr_Latn", name: "Serbian (Latin)" },
    { code: "sr_ME", name: "Serbian (Montenegro)" },
    { code: "sr_RS", name: "Serbian (Serbia)" },
    { code: "gd", name: "Scottish Gaelic; Gaelic" },
    { code: "ksb", name: "Shambala" },
    { code: "sn", name: "Shona" },
    { code: "ii", name: "Sichuan Yi" },
    { code: "si", name: "Sinhala, Sinhalese" },
    { code: "sk", name: "Slovak" },
    { code: "sk_SK", name: "Slovak (Slovakia)" },
    { code: "sl", name: "Slovenian" },
    { code: "sl_SI", name: "Slovenian (Slovenia)" },
    { code: "xog", name: "Soga" },
    { code: "so", name: "Somali" },
    { code: "so_DJ", name: "Somali (Djibouti)" },
    { code: "so_ET", name: "Somali (Ethiopia)" },
    { code: "so_KE", name: "Somali (Kenya)" },
    { code: "so_SO", name: "Somali (Somalia)" },
    { code: "st", name: "Southern Sotho" },
    { code: "es", name: "Spanish" },
    { code: "es_AR", name: "Spanish (Argentina)" },
    { code: "es_BO", name: "Spanish (Bolivia)" },
    { code: "es_CL", name: "Spanish (Chile)" },
    { code: "es_CO", name: "Spanish (Colombia)" },
    { code: "es_CR", name: "Spanish (Costa Rica)" },
    { code: "es_DO", name: "Spanish (Dominican Republic)" },
    { code: "es_EC", name: "Spanish (Ecuador)" },
    { code: "es_SV", name: "Spanish (El Salvador)" },
    { code: "es_GQ", name: "Spanish (Equatorial Guinea)" },
    { code: "es_GT", name: "Spanish (Guatemala)" },
    { code: "es_HN", name: "Spanish (Honduras)" },
    { code: "es_419", name: "Spanish (Latin America)" },
    { code: "es_MX", name: "Spanish (Mexico)" },
    { code: "es_NI", name: "Spanish (Nicaragua)" },
    { code: "es_PA", name: "Spanish (Panama)" },
    { code: "es_PY", name: "Spanish (Paraguay)" },
    { code: "es_PE", name: "Spanish (Peru)" },
    { code: "es_PR", name: "Spanish (Puerto Rico)" },
    { code: "es_ES", name: "Spanish (Spain)" },
    { code: "es_US", name: "Spanish (United States)" },
    { code: "es_UY", name: "Spanish (Uruguay)" },
    { code: "es_VE", name: "Spanish (Venezuela)" },
    { code: "su", name: "Sundanese" },
    { code: "sw", name: "Swahili" },
    { code: "sw_KE", name: "Swahili (Kenya)" },
    { code: "sw_TZ", name: "Swahili (Tanzania)" },
    { code: "ss", name: "Swati" },
    { code: "sv", name: "Swedish" },
    { code: "sv_FI", name: "Swedish (Finland)" },
    { code: "sv_SE", name: "Swedish (Sweden)" },
    { code: "gsw", name: "Swiss German" },
    { code: "shi", name: "Tachelhit" },
    { code: "shi_MA", name: "Tachelhit (Morocco)" },
    { code: "shi_Tfng_MA", name: "Tachelhit (Tifinagh, Morocco)" },
    { code: "shi_Tfng", name: "Tachelhit (Tifinagh)" },
    { code: "ta", name: "Tamil" },
    { code: "ta_IN", name: "Tamil (India)" },
    { code: "ta_SG", name: "Tamil (Singapore)" },
    { code: "ta_LK", name: "Tamil (Sri Lanka)" },
    { code: "twq", name: "Tasawaq" },
    { code: "dav", name: "Taita" },
    { code: "te", name: "Telugu" },
    { code: "te_IN", name: "Telugu (India)" },
    { code: "teo", name: "Teso" },
    { code: "teo_KE", name: "Teso (Kenya)" },
    { code: "teo_UG", name: "Teso (Uganda)" },
    { code: "tg", name: "Tajik" },
    { code: "th", name: "Thai" },
    { code: "th_TH", name: "Thai (Thailand)" },
    { code: "ti", name: "Tigrinya" },
    { code: "ti_ER", name: "Tigrinya (Eritrea)" },
    { code: "ti_ET", name: "Tigrinya (Ethiopia)" },
    { code: "bo", name: "Tibetan Standard, Tibetan, Central" },
    { code: "bo_CN", name: "Tibetan (China)" },
    { code: "bo_IN", name: "Tibetan (India)" },
    { code: "tk", name: "Turkmen" },
    { code: "tl", name: "Tagalog" },
    { code: "tl_PH", name: "Tagalog (Philippines)" },
    { code: "tsg", name: "Tausug" },
    { code: "tsg_Arab", name: "Tausug (Arab)" },
    { code: "tn", name: "Tswana" },
    { code: "to", name: "Tonga (Tonga Islands)" },
    { code: "tr", name: "Turkish" },
    { code: "tr_TR", name: "Turkish (Turkey)" },
    { code: "ts", name: "Tsonga" },
    { code: "tt", name: "Tatar" },
    { code: "tw", name: "Twi" },
    { code: "ty", name: "Tahitian" },
    { code: "ug", name: "Uighur, Uyghur" },
    { code: "uk", name: "Ukrainian" },
    { code: "uk_UA", name: "Ukrainian (Ukraine)" },
    { code: "ur", name: "Urdu" },
    { code: "ur_IN", name: "Urdu (India)" },
    { code: "ur_PK", name: "Urdu (Pakistan)" },
    { code: "uz", name: "Uzbek" },
    { code: "uz_Arab_AF", name: "Uzbek (Arabic, Afghanistan)" },
    { code: "uz_Arab", name: "Uzbek (Arabic)" },
    { code: "uz_Latn_UZ", name: "Uzbek (Latin, Uzbekistan)" },
    { code: "uz_Latn", name: "Uzbek (Latin)" },
    { code: "uz_UZ", name: "Uzbek (Uzbekistan)" },
    { code: "vai", name: "Vai" },
    { code: "vai_Latn_LR", name: "Vai (Latin, Liberia)" },
    { code: "vai_Latn", name: "Vai (Latin)" },
    { code: "vai_LR", name: "Vai (Liberia)" },
    { code: "ve", name: "Venda" },
    { code: "vi", name: "Vietnamese" },
    { code: "vi_VN", name: "Vietnamese (Vietnam)" },
    { code: "vo", name: "Volapük" },
    { code: "vun", name: "Vunjo" },
    { code: "wa", name: "Walloon" },
    { code: "cy_GB", name: "Welsh (United Kingdom)" },
    { code: "cy", name: "Welsh" },
    { code: "wo", name: "Wolof" },
    { code: "fy", name: "Western Frisian" },
    { code: "xh", name: "Xhosa" },
    { code: "yi", name: "Yiddish" },
    { code: "yav", name: "Yangben" },
    { code: "yo", name: "Yoruba" },
    { code: "yue", name: "Yue Chinese (Cantonese)" },
    { code: "dje", name: "Zarma" },
    { code: "zu", name: "Zulu" },
    { code: "za", name: "Zhuang, Chuang" },
  ]
}

export const getIsoName = (langIso: string): string => {
  let languages = languagesList()

  let name = languages.find((l) => {
    return l.code.toLocaleLowerCase() === langIso.toLocaleLowerCase()
  })

  if (name) return name.name

  return langIso;
}
