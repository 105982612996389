import React from "react"
import { Link } from 'react-router-dom'

const Privacy = () => {
  return (
    <React.Fragment>
      <link rel="stylesheet" href="/css/landing/components.css"></link>
      <link rel="stylesheet" href="/css/landing/normalize.css"></link>
      <link rel="stylesheet" href="/css/landing/wes-landing-page.css"></link>
      <link rel="stylesheet" href="/css/landingpage.css"></link>
      <div className="page-wrapper gradient">
        <div
          data-animation="default"
          className="navbar4_component w-nav"
          data-easing2="ease"
          fs-scrolldisable-element="smart-nav"
          data-easing="ease"
          data-collapse="all"
          data-w-id="e0e2adeb-8a20-0fb3-a322-77e438213f12"
          role="banner"
          data-duration="400"
          data-doc-height="1"
        >
          <div
            data-w-id="685e9566-0db0-54ed-1639-4656d8a303a0"
            className="nav-background"
          ></div>
          <div className="navbar4_container">
            <a href="index.html" className="navbar4_logo-link w-nav-brand">
              <img
                src="images/Wes.svg"
                loading="lazy"
                alt="Wes"
                className="navbar4_logo"
              />
            </a>
            <div className="navbar4_wrapper">
              <Link to="/login"
                className="button is-hero is-tertiary w-button"
              >
                Log In
              </Link>
              {/* <a
                data-w-id="e0e2adeb-8a20-0fb3-a322-77e438213f19"
                href="#"
                className="button is-navbar4-button w-button"
              >
                Book a Demo
              </a> */}
            </div>
          </div>
        </div>
        <main className="main-wrapper">
          <section className="section_content7">
            <div className="padding-global">
              <div className="container-small">
                <div className="padding-section-large">
                  <div className="content7_component">
                    <div className="margin-bottom margin-small">
                      <h1
                        id="w-node-e07f61d8-1278-16a8-4fdd-ecc47768c926-44d60467"
                        className="heading-style-h2"
                      >
                        Privacy Policy
                      </h1>
                    </div>
                    <div className="text-rich-text w-richtext">
                      <p>
                        This Privacy Policy explains how Wes (&quot;we,&quot;
                        &quot;us,&quot; or &quot;our&quot;) collects, uses,
                        discloses, and protects the personal information of
                        users (&quot;you&quot; or &quot;users&quot;) when you
                        access and use our services. We are committed to
                        protecting your privacy and ensuring the security of
                        your personal information. By using Wes, you consent to
                        the practices described in this Privacy Policy.
                      </p>
                      <h2>1. Information We Collect</h2>
                      <p>
                        <strong>1.1 Personal Information: </strong>When you
                        register for an account on Wes, we may collect personal
                        information, such as your name, email address, and
                        Webflow URLs. We require this information to provide you
                        with access to our services and to communicate with you
                        regarding your account.
                      </p>
                      <p>
                        <strong>1.2 Usage Information: </strong>We may
                        automatically collect certain information about your use
                        of Wes, such as your IP address, device information,
                        browser type, and operating system. This information is
                        used to analyze trends, administer the site, track user
                        movements, and gather demographic information for
                        aggregate use.
                      </p>
                      <h2>2. Use of Information</h2>
                      <p>
                        <strong>2.1 Service Provision:</strong> We use the
                        personal information we collect to provide and improve
                        our services, personalize your experience, and respond
                        to your inquiries or support requests.
                      </p>
                      <p>
                        <strong>2.2 Communication:</strong> We may use your
                        email address to send you important notifications,
                        updates, or promotional materials related to Wes. You
                        can opt-out of receiving marketing communications by
                        following the unsubscribe instructions included in the
                        emails.
                      </p>
                      <p>
                        <strong>2.3 Analytics and Improvements: </strong>We may
                        use the information we collect to analyze user behavior,
                        monitor the effectiveness of our services, and make
                        improvements to Wes.
                      </p>
                      <p>
                        <strong>2.4 Aggregated Data:</strong> We may anonymize
                        and aggregate user data for statistical and analytical
                        purposes. Aggregated data does not identify any
                        individual user and may be shared with third parties for
                        various purposes, including research or marketing.
                      </p>
                      <h2>3. Information Sharing and Disclosure</h2>
                      <p>
                        <strong>3.1 Service Providers:</strong> We may engage
                        third-party service providers to assist us in delivering
                        our services or performing related functions. These
                        service providers will have access to your personal
                        information only to the extent necessary to fulfill
                        their contractual obligations to us and are obligated to
                        keep the information confidential.
                      </p>
                      <p>
                        <strong>3.2 Legal Compliance:</strong> We may disclose
                        your personal information if required to do so by law,
                        legal process, or government request. We may also
                        disclose your information to protect our rights,
                        property, or safety, or the rights, property, or safety
                        of others.
                      </p>
                      <p>
                        <strong>3.3 Business Transfers: </strong>In the event of
                        a merger, acquisition, or sale of all or a portion of
                        our assets, your personal information may be transferred
                        as part of the transaction. We will notify you via email
                        and/or a prominent notice on our website of any change
                        in ownership or uses of your personal information, as
                        well as any choices you may have regarding your
                        information.
                      </p>
                      <h2>4. Data Security</h2>
                      <p>
                        <strong>4.1 Security Measures:</strong> We implement
                        reasonable and appropriate technical and organizational
                        security measures to protect your personal information
                        from unauthorized access, use, alteration, or
                        disclosure.
                      </p>
                      <p>
                        <strong>4.2 Data Retention:</strong> We retain personal
                        information for as long as necessary to fulfill the
                        purposes outlined in this Privacy Policy, unless a
                        longer retention period is required or permitted by law.
                        We will securely delete or anonymize personal
                        information once it is no longer needed.
                      </p>
                      <h2>5. Third-Party Links</h2>
                      <p>
                        Wes may contain links to third-party websites,
                        applications, or services that are not owned or
                        controlled by us. This Privacy Policy does not apply to
                        those third-party services. We encourage you to review
                        the privacy policies of those third parties before
                        providing any personal information.
                      </p>
                      <h2>6. Children&#x27;s Privacy</h2>
                      <p>
                        Wes is not intended for use by individuals under the age
                        of majority in their jurisdiction. We do not knowingly
                        collect personal information from children. If you
                        become aware of any personal information we have
                        inadvertently collected from a child, please contact us
                        immediately, and we will take steps to delete the
                        information.
                      </p>
                      <h2>7. Your Rights</h2>
                      <p>
                        <strong>7.1 Access and Rectification:</strong> You have
                        the right to access and update the personal information
                        we hold about you. If you believe that any information
                        we have is inaccurate or incomplete, please contact us,
                        and we will promptly correct it.
                      </p>
                      <p>
                        <strong>7.2 Data Portability:</strong> Upon your
                        request, we will provide you with a copy of your
                        personal information in a structured, commonly used, and
                        machine-readable format, to the extent feasible.
                      </p>
                      <p>
                        <strong>7.3 Consent Withdrawal:</strong> You have the
                        right to withdraw your consent to the processing of your
                        personal information at any time. Please note that
                        withdrawal of consent may affect your ability to access
                        or use certain features of Wes.
                      </p>
                      <p>
                        <strong>7.4 Account Closure:</strong> You may request
                        the closure of your Wes account at any time. Upon
                        account closure, we will delete or anonymize your
                        personal information, except as required by law or as
                        necessary for legitimate business purposes.
                      </p>
                      <p>
                        <strong>7.5 Marketing Communications:</strong> You can
                        opt-out of receiving marketing communications from us by
                        following the instructions provided in the emails or by
                        contacting us directly. Even if you opt-out, we may
                        still send you non-promotional messages relating to your
                        use of Wes.
                      </p>
                      <h2>8. Changes to this Privacy Policy</h2>
                      <p>
                        We reserve the right to modify or update this Privacy
                        Policy at any time. We will notify you of any material
                        changes by posting the updated policy on our website or
                        through other reasonable means. Your continued use of
                        Wes after the effective date of the updated Privacy
                        Policy constitutes your acceptance of the changes.
                      </p>
                      <h2>9. Contact Us</h2>
                      <p>
                        If you have any questions, concerns, or requests
                        regarding this Privacy Policy or our privacy practices,
                        please contact  your account manager.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer className="footer4_component">
          <div className="padding-global">
            <div className="container-large">
              <div className="padding-vertical padding-small">
                <div className="w-layout-grid footer4_top-wrapper">
                  <a
                    href="index.html"
                    id="w-node-ee999d70-510f-77aa-68d9-ad3b2d593bf2-2d593bed"
                    className="footer4_logo-link w-nav-brand"
                  >
                    <img src="images/Wes.svg" loading="lazy" alt="Wes" />
                  </a>
                  <div className="w-layout-grid footer4_link-list">
                    <Link to="/policy"
                      id="w-node-_630333c3-5fc9-5e71-243c-aa5930162276-2d593bed"
                      aria-current="page"
                      className="footer4_link w--current"
                    >
                      Privacy Policy
                    </Link>
                    <Link to="/term"className="footer4_link">
                      Terms of Service
                    </Link>
                  </div>
                  <div
                    id="w-node-ee999d70-510f-77aa-68d9-ad3b2d593bf6-2d593bed"
                    className="w-layout-grid footer4_social-list"
                  >
                    <a
                      href="https://www.edgarallan.com/"
                      target="_blank"
                      className="made-by-ea w-inline-block"
                    >
                      <div>Made By</div>
                      <img
                        src="images/ea-logo-dot.svg"
                        loading="lazy"
                        alt=""
                        className="image"
                      />
                    </a>
                    <div className="display-none">
                      <a
                        href="https://www.linkedin.com/company/edgar-allan"
                        target="_blank"
                        className="footer4_social-link w-inline-block"
                      >
                        <div className="social-icon w-embed">
                          <svg
                            width="100%"
                            height="100%"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3ZM8 18C8.27614 18 8.5 17.7761 8.5 17.5V10.5C8.5 10.2239 8.27614 10 8 10H6.5C6.22386 10 6 10.2239 6 10.5V17.5C6 17.7761 6.22386 18 6.5 18H8ZM7.25 9C6.42157 9 5.75 8.32843 5.75 7.5C5.75 6.67157 6.42157 6 7.25 6C8.07843 6 8.75 6.67157 8.75 7.5C8.75 8.32843 8.07843 9 7.25 9ZM17.5 18C17.7761 18 18 17.7761 18 17.5V12.9C18.0325 11.3108 16.8576 9.95452 15.28 9.76C14.177 9.65925 13.1083 10.1744 12.5 11.1V10.5C12.5 10.2239 12.2761 10 12 10H10.5C10.2239 10 10 10.2239 10 10.5V17.5C10 17.7761 10.2239 18 10.5 18H12C12.2761 18 12.5 17.7761 12.5 17.5V13.75C12.5 12.9216 13.1716 12.25 14 12.25C14.8284 12.25 15.5 12.9216 15.5 13.75V17.5C15.5 17.7761 15.7239 18 16 18H17.5Z"
                              fill="CurrentColor"
                            ></path>
                          </svg>
                        </div>
                      </a>
                      <a
                        href="https://www.youtube.com/edgarallan"
                        target="_blank"
                        className="footer4_social-link w-inline-block"
                      >
                        <div className="social-icon w-embed">
                          <svg
                            width="100%"
                            height="100%"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M18.5399 4.33992L19.9999 4.48992C21.7284 4.68529 23.0264 6.16064 22.9999 7.89992V16.0999C23.0264 17.8392 21.7284 19.3146 19.9999 19.5099L18.5999 19.6599C14.2315 20.1099 9.82835 20.1099 5.45991 19.6599L3.99991 19.5099C2.27143 19.3146 0.973464 17.8392 0.999909 16.0999V7.89992C0.973464 6.16064 2.27143 4.68529 3.99991 4.48992L5.39991 4.33992C9.76835 3.88995 14.1715 3.88995 18.5399 4.33992ZM11.1099 15.2199L14.9999 12.6199H15.0599C15.2695 12.4833 15.3959 12.2501 15.3959 11.9999C15.3959 11.7497 15.2695 11.5165 15.0599 11.3799L11.1699 8.77992C10.9402 8.62469 10.6437 8.60879 10.3987 8.73859C10.1538 8.86839 10.0004 9.12271 9.99991 9.39992V14.5999C10.0128 14.858 10.1576 15.0913 10.3832 15.2173C10.6088 15.3433 10.8834 15.3443 11.1099 15.2199Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </div>
                      </a>
                      <a
                        href="https://twitter.com/EdgarAllanCo"
                        target="_blank"
                        className="footer4_social-link w-inline-block"
                      >
                        <div className="social-icon w-embed">
                          <svg
                            width="100%"
                            height="100%"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20.9728 6.7174C20.5084 7.33692 19.947 7.87733 19.3103 8.31776C19.3103 8.47959 19.3103 8.64142 19.3103 8.81225C19.3154 11.7511 18.1415 14.5691 16.0518 16.6345C13.962 18.6999 11.1312 19.8399 8.19405 19.7989C6.49599 19.8046 4.81967 19.4169 3.29642 18.6661C3.21428 18.6302 3.16131 18.549 3.16162 18.4593V18.3604C3.16162 18.2313 3.26623 18.1267 3.39527 18.1267C5.06442 18.0716 6.67402 17.4929 7.99634 16.4724C6.48553 16.4419 5.12619 15.5469 4.5006 14.1707C4.46901 14.0956 4.47884 14.0093 4.52657 13.9432C4.57429 13.8771 4.653 13.8407 4.73425 13.8471C5.19342 13.8932 5.65718 13.8505 6.1002 13.7212C4.43239 13.375 3.17921 11.9904 2.99986 10.2957C2.99349 10.2144 3.02992 10.1357 3.096 10.0879C3.16207 10.0402 3.24824 10.0303 3.32338 10.062C3.77094 10.2595 4.25409 10.3635 4.74324 10.3676C3.28184 9.40846 2.65061 7.58405 3.20655 5.92622C3.26394 5.76513 3.40181 5.64612 3.5695 5.61294C3.73718 5.57975 3.90996 5.63728 4.02432 5.76439C5.99639 7.86325 8.70604 9.11396 11.5819 9.25279C11.5083 8.95885 11.4721 8.65676 11.4741 8.35372C11.501 6.76472 12.4842 5.34921 13.9634 4.76987C15.4425 4.19054 17.1249 4.56203 18.223 5.71044C18.9714 5.56785 19.695 5.31645 20.3707 4.96421C20.4202 4.93331 20.483 4.93331 20.5325 4.96421C20.5634 5.01373 20.5634 5.07652 20.5325 5.12604C20.2052 5.87552 19.6523 6.50412 18.9509 6.92419C19.5651 6.85296 20.1685 6.70807 20.7482 6.49264C20.797 6.45942 20.8611 6.45942 20.9099 6.49264C20.9508 6.51134 20.9814 6.54711 20.9935 6.59042C21.0056 6.63373 20.998 6.68018 20.9728 6.7174Z"
                              fill="CurrentColor"
                            ></path>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </React.Fragment>
  )
}

export default Privacy
