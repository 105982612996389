import React, { useContext, useEffect, useState } from "react"
import Plus from "@/resources/images/plus-1-1_1.svg"
import {
  AppStateContext,
  AppDispatchContext,
} from "@/components/contexts/AppContext"
import LokaliseItem from "@/components/translation/LokaliseItem"
import WeglotItem from "@/components/translation/WeglotItem"
import PageList from "@/components/translation/PageList"
import { ProjectAsset } from "@/models/Project"
import ProjectAssetClient from "@/clients/ProjectAssetClient"
import ProjectClient from "@/clients/ProjectClient"
import AddLanguageModal from "@/components/modals/AddLanguageModal"
import AddWeglotLanguageModal from "@/components/modals/AddWeglotLanguageModal"
import { Actions } from "../reducers/AppReducer"

const Translation: React.ComponentType = () => {
  const assetClient = new ProjectAssetClient()
  const projectClient = new ProjectClient()
  const { state } = useContext(AppStateContext)
  const { dispatch } = useContext(AppDispatchContext)
  const [languages, setLanguages] = useState<Array<any>>([])
  const [showPageList, setShowPageList] = useState(false)
  const [currentLang, setCurrentLang] = useState("")
  const [showAddLanguageModal, setShowAddLanguageModal] = useState(false)
  const [translation, setTranslation] = useState("")

  useEffect(() => {
    if (
      state.project!.data.translations &&
      state.project!.data.translations.includes("lokalise")
    ) {
      setTranslation("lokalise")
      setLanguages(state.project!.data.lokalise_data.languages)
    } else if (
      state.project!.data.translations &&
      state.project!.data.translations.includes("weglot")
    ) {
      setTranslation("weglot")
      setLanguages(state.project!.data.weglot_data)
      // console.log(state.project!.data.weglot_data);
    }
  }, [state.project])

  const languageClick = (e: any, language: any) => {
    if (e.target.classList.contains("object-fit-cover")) {
      languages.map((lang: any, index) => {
        if (
          translation === "lokalise" &&
          Object.keys(lang)[0] === Object.keys(language)[0]
        )
          languages[index].open_dropdown = !languages[index].open_dropdown
        else if (translation === "weglot" && language.lang === lang.lang) {
          languages[index].open_dropdown = !languages[index].open_dropdown
        } else languages[index].open_dropdown = false
      })
      setLanguages([...languages])
    } else if (!e.target.classList.contains("w-dropdown-link")) {
      languages.map((lang: any, index) => {
        languages[index].open_dropdown = false
      })
      setLanguages([...languages])
      if (translation === "lokalise") setCurrentLang(Object.keys(language)[0])
      else if (translation === "weglot") setCurrentLang(language.lang)
      setShowPageList(true)
    }
  }

  const onDelete = (language: any) => {
    if (translation === "weglot") {
      console.log(language)
      let updatedLanguages = languages.filter((lang: any) => {
        return lang.lang !== language.lang
      })
      setLanguages(updatedLanguages)

      state.project!.data.weglot_data = updatedLanguages
      dispatch({ type: Actions.UPDATE_PROJECT, payload: state.project! })
      projectClient.updateProject(state.project!.id!, state.project!)
    }
  }

  const onCreateLanguage = () => {
    setShowAddLanguageModal(false)
  }

  return (
    <div className="padding-vertical-small">
      <div className="page-heading-wrapper">
        <h2 className="page-heading">Translation Sources</h2>
      </div>
      <div className="repository-grid-wrapper source-grid-wrapper ">
        <div className="repository-grid-navigation-wrapper">
          <div className="repository-name-wrapper-w-padding">
            <div className="page-grid-text">Name</div>
          </div>
          <div className="page-grid-text">Translation URL</div>
          <div className="page-grid-text">Service</div>
        </div>
        {translation === "lokalise" &&
          languages.map((language: any, index: number) => {
            return (
              <LokaliseItem
                key={index}
                langauge={language}
                onClick={(e: any) => languageClick(e, language)}
                project={state.project!}
                onDelete={onDelete}
              />
            )
          })}
        {!languages.length && (
          <div className="empty-state">
            You haven't added a translation source yet
          </div>
        )}
        {translation === "weglot" &&
          languages.map((language: any, index: number) => {
            return (
              <WeglotItem
                key={index}
                langauge={language}
                project={state.project!}
                onDelete={onDelete}
                onClick={(e: any) => languageClick(e, language)}
              />
            )
          })}
      </div>
      <a
        href="#"
        className="add-source-link w-inline-block"
        style={{ width: 150 }}
        onClick={() => setShowAddLanguageModal(true)}
      >
        <img src={Plus} loading="lazy" alt="" className="image" />
        <div>Add Language</div>
      </a>
      <PageList
        show={showPageList}
        onClose={() => setShowPageList(false)}
        language={currentLang}
        project={state.project!}
        translation={translation}
      />
      {translation === "lokalise" && (
        <AddLanguageModal
          show={showAddLanguageModal}
          onClose={() => setShowAddLanguageModal(false)}
          project={state.project!}
          onCreate={onCreateLanguage}
        />
      )}
      {translation === "weglot" && (
        <AddWeglotLanguageModal
          show={showAddLanguageModal}
          onClose={() => setShowAddLanguageModal(false)}
          project={state.project!}
          onCreate={onCreateLanguage}
        />
      )}
    </div>
  )
}

export default Translation
