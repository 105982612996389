import React, { useContext, useState } from "react"
import { FolderOutlined } from "@ant-design/icons"
import { Button, ConfirmModal } from "@/components/common"
import ProjectClient from "@/clients/ProjectClient"
import { AppStateContext } from "@/components/contexts/AppContext"
import { message } from "antd"

const RepositorySettings: React.ComponentType = (props) => {
  const projectClient = new ProjectClient()
  const { state } = useContext(AppStateContext)
  const [loading, setLoading] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const purgeRepository = () => {
    setShowConfirmModal(false)
    setLoading(true)
    projectClient.purgeRepository(state.project!.id!).then(
      (res: any) => {
        message.success(`Successfully deleted ${res.count} files.`)
        setLoading(false)
      },
      (error: string) => {
        message.error(error)
        setLoading(false)
      }
    )
  }

  return (
    <div {...props}>
      <Button
        label="Delete Repository Files"
        className="danger-button w-button"
        loading={loading}
        onClick={() => setShowConfirmModal(true)}
      />
      <ConfirmModal
        message="Are you sure you want to delete all the files found on the Repository tab? This action can not be reversed."
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        danger
        onConfirm={purgeRepository}
      />
    </div>
  )
}

export default RepositorySettings
