import BaseClient from "./BaseClient"

class SubstitutionClient extends BaseClient {
  /**
   * Get all project substitution
   * @param projectId
   * @param params
   * @returns
   */
  getSubstitution = (
    projectId: number,
    params: { limit?: number }
  ): Promise<any> => {
    return this.get(`/project/${projectId}/substitution`, { params })
  }

  /**
   * Create new project substitution
   * @param projectId
   * @param data
   * @returns
   */
  createSubstitution = (projectId: number, data: any): Promise<any> => {
    return this.post(`/project/${projectId}/substitution`, data)
  }

  /**
   * Delete substitution
   * @param projectId
   * @param substitutionId
   * @returns
   */
  deleteSubstitution = (
    projectId: number,
    substitutionId: number
  ): Promise<any> => {
    return this.delete(`/project/${projectId}/substitution/${substitutionId}`)
  }
}

export default SubstitutionClient
