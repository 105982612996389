import React, { useContext, useState } from "react"
import moment from "moment"
import ProjectClient from "@/clients/ProjectClient"
import { CloudUploadOutlined, DownloadOutlined } from "@ant-design/icons"
import { Project } from "@/models/Project"
import { message } from "antd"
import { Actions } from "../reducers/AppReducer"
import axios from "axios"

interface ArchiveItemProps {
  archive: any
  isgrey?: boolean | number
  project: Project
}

const ArchiveItem: React.ComponentType<ArchiveItemProps> = (
  props: ArchiveItemProps
) => {
  const { archive, isgrey, project } = props
  const projectClient = new ProjectClient()
  const [isUploading, setIsUploading] = useState(false)

  const uploadArchive = () => {
    if (isUploading) return

    setIsUploading(true)
    projectClient.uploadArchive(project.id!, archive.id, false).then(
      () => {
        message.success("Archive successfully uploaded.")
        setIsUploading(false)
      },
      (error: string) => {
        message.error(error)
        setIsUploading(false)
      }
    )
  }

  const getBuildLink = () => {
    return process.env.REACT_APP_SITE_URL + "/archive/" + archive.filename
  }

  const uploadToProduction = () => {
    setIsUploading(true)

    let data = {
      build_id: archive.id,
    }

    projectClient.publishProduction(project.id!, data).then(
      (proj: Project) => {
        //message.success("Successfully publish build.")
        // console.log(proj);
        proj.is_publishing_prod = false
        proj!.is_published = true
        setIsUploading(false)
      },
      (error: string) => {
        // message.error(error);
        setIsUploading(false)
      }
    )
  }

  return (
    <div
      {...props}
      className={
        "repository-single-item-wrapper arhive-item-wrapper" +
        (isgrey ? " is-grey" : "")
      }
    >
      <div className="repository-name-wrapper-w-padding">
        <div className="page-grid-item-text">{archive.filename}</div>
      </div>
      <div className="repository-name-wrapper">
        <div className="page-grid-item-text">
          {moment(archive.created_at).fromNow()}
        </div>
      </div>
      <div className="repository-name-wrapper">
        <div className="page-grid-item-text">
          {!archive.is_done && <div>Creating Archive...</div>}

          <a
            href="#"
            className="link-button w-inline-block"
            onClick={uploadArchive}
          >
            {archive.is_done && isUploading && <div>Uploading...</div>}
          </a>
          {archive.is_done && !isUploading && (
            <a
              href="#"
              className="link-button w-inline-block"
              onClick={uploadToProduction}
              style={{ marginRight: 30 }}
            >
              {archive.is_done && isUploading && <div>Uploading...</div>}
              {project.data?.hosting && archive.is_done && !isUploading && (
                <div>
                  <CloudUploadOutlined /> Upload to Production
                </div>
              )}
            </a>
          )}
          {archive.is_done && !isUploading && (
            <a
              href={getBuildLink()}
              target="_blank"
              className="link-button w-inline-block"
              rel="noreferrer"
            >
              <div>
                <DownloadOutlined /> Download
              </div>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default ArchiveItem
