import React, { useState } from "react"
import { User } from "@/models/User"
import UserClient from "@/clients/UserClient"
import { Button } from "@/components/common"
import { message } from "antd"

const ChangePassword: React.ComponentType = () => {
  const userClient = new UserClient()
  const [currentPass, setCurrentPass] = useState("")
  const [newPass, setNewPass] = useState("")
  const [confirmPass, setConfirmPass] = useState("")
  const [loading, setLoading] = useState(false)

  const updatePassword = () => {
    if (currentPass.length <= 0) {
      message.error("Please enter current Password.")
      return
    }

    if (newPass.length <= 0) {
      message.error("Please enter New Password.")
      return
    }

    if (newPass !== confirmPass) {
      message.error("Password mismatch!")
      return
    }

    setLoading(true)
    userClient.changePassword(currentPass, newPass).then(
      (user: User) => {
        message.success("Password successfully changed.")
        setLoading(false)
        setCurrentPass("")
        setNewPass("")
        setConfirmPass("")
      },
      (error: string) => {
        message.error(error)
        setLoading(false)
      }
    )
  }

  return (
    <div className="user-popup-content-wrapper">
      <div className="popup-modal-form-block w-form">
        <form
          id="Password-Update"
          name="wf-form-Password-Update"
          data-name="Password Update"
          method="get"
        >
          <div className="margin-bottom-30">
            <label className="popup-modal-field-label">Current Password</label>
            <input
              type="password"
              className="popup-modal-input-field w-input"
              maxLength={256}
              name="current-password"
              value={currentPass}
              onChange={(e) => setCurrentPass(e.target.value)}
              data-name="current password"
              placeholder="Current Password"
              id="current-password"
            />
            <label className="popup-modal-field-label">New Password</label>
            <input
              type="password"
              className="popup-modal-input-field w-input"
              maxLength={256}
              name="new-password"
              value={newPass}
              onChange={(e) => setNewPass(e.target.value)}
              data-name="new password"
              placeholder="New Password"
              id="new-password"
              required
            />
            <label className="popup-modal-field-label">Re-type Password</label>
            <input
              type="password"
              className="popup-modal-input-field w-input"
              maxLength={256}
              name="re-type-Password"
              value={confirmPass}
              onChange={(e) => setConfirmPass(e.target.value)}
              data-name="re-type Password"
              placeholder="Re-type Password"
              id="re-type-Password"
              required
            />
          </div>
          <Button
            label="Change Password"
            loading={loading}
            className={"submit-button w-button"}
            onClick={updatePassword}
          />
        </form>
      </div>
    </div>
  )
}

export default ChangePassword
