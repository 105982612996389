import React, { useContext, useEffect, useState } from "react"
import {
  AppStateContext,
  AppDispatchContext,
} from "@/components/contexts/AppContext"
import { ProjectAsset, ProjectSource } from "@/models/Project"
import { Actions } from "../reducers/AppReducer"
import RepositoryItem from "@/components/repository/RepositoryItem"
import RepositoryTabs from "@/components/repository/RepositoryTabs"
import ProjectAssetClient from "@/clients/ProjectAssetClient"
import { message, Skeleton } from "antd"
import Project from "./Project"

const Repository = () => {
  const assetClient = new ProjectAssetClient()
  const { state } = useContext(AppStateContext)
  const { dispatch } = useContext(AppDispatchContext)
  const [assets, setAssets] = useState<Array<ProjectAsset>>([])
  const [reloadAssets, setReloadAssets] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showAssetSettings, setShowAssetSettings] = useState(false)
  const [currentAsset, setCurrentAsset] = useState<ProjectAsset>()

  useEffect(() => {
    if (!state.project) return
    loadAssets()
  }, [])

  useEffect(() => {
    if(reloadAssets) {
      loadAssets();
    }
  }, [reloadAssets]);

  const loadAssets = () => {
    setLoading(true)
    assetClient.getAssets(state.project?.id!).then(
      (res: Array<ProjectAsset>) => {
        setAssets(res)
        setLoading(false)
        setReloadAssets(false) // make sure to set the reload assets to false to prevent reloading redundency
      },
      (error: string) => {
        message.error(error)
        setLoading(false)
      }
    )
  }

  const toggleAsset = (event: any, asset: ProjectAsset, index: number) => {
    if (event.target.classList.contains("asset-url")) return

    if (event.target.classList.contains("update-asset") || event.target.parentNode.classList.contains("update-asset")) {
      console.log(asset)
      return;
    }

    if (asset.type !== "folder") {
      setCurrentAsset(asset)
      setShowAssetSettings(true)
    } else {
      assets[index].is_close = !asset.is_close
      setAssets([...assets])
    }
  }

  const onDeleteAsset = (asset: ProjectAsset) => {
    const filteredAssets = assets.filter((a: ProjectAsset) => {
      return a.id !== asset.id
    })
    setAssets([...filteredAssets])
    setShowAssetSettings(false)
  }

  return (
    <div className="padding-vertical-small">
      <div className="page-heading-wrapper">
        <h2 className="page-heading">Repository</h2>
      </div>
      <div className="repository-grid-wrapper">
        <div className="repository-grid-navigation-wrapper">
          <div
            id="w-node-_4daa76ca-2b85-2e30-0711-de7cb3f0b322-1d037768"
            className="repository-name-wrapper-w-padding"
          >
            <div className="page-grid-text">Name</div>
          </div>
          <div className="page-grid-text">Source URL</div>
          <div className="page-grid-text">Updated</div>
          <div className="page-grid-text">Kind</div>
          <div className="page-grid-text"></div>
        </div>
        <Skeleton loading={loading}>
          {!assets.length && (
            <div className="empty-state">
              You haven't published anything yet
            </div>
          )}
          {assets.map((asset: ProjectAsset, index: number, project: any) => {
            return (
              <RepositoryItem
                asset={asset}
                key={index}
                index={index}
                assets={assets}
                alias={state.project!.alias}
                onClick={(e: any) => {
                  toggleAsset(e, asset, index)
                }}
              />
            )
          })}
        </Skeleton>
      </div>
      <RepositoryTabs
        asset={currentAsset!}
        show={showAssetSettings}
        project={state.project!}
        assets={assets}
        onClose={() => setShowAssetSettings(false)}
        onDeleteAsset={onDeleteAsset}
        setReloadAssets={setReloadAssets}
      />
    </div>
  )
}

export default Repository
