import { Project, ProjectSource } from "@/models/Project"
import React, { useContext, useState } from "react"
import { Button, Modal } from "@/components/common"
import { message, Select } from "antd"
import ProjectClient from "@/clients/ProjectClient"
import { languagesList } from "@/utilities/LangISONameHelper"
import { AppDispatchContext } from "@/components/contexts/AppContext"
import { Actions } from "../reducers/AppReducer"

interface AddLanguageModalProps {
  show: boolean
  onClose: any
  project: Project
  onCreate: any
}

const AddLanguageModal: React.ComponentType<AddLanguageModalProps> = ({
  show,
  onClose,
  project,
  onCreate,
}) => {
  const projectClient = new ProjectClient()
  const { dispatch } = useContext(AppDispatchContext)
  const [language, setLanguage] = useState("")
  const [loading, setLoading] = useState(false)

  const addLanguage = () => {
    if (language.length === 0) {
      message.error("Please select a language.")
      return
    }

    setLoading(true)
    projectClient.addLokaliseProjectLanguage(project.id!, language).then(
      (project: Project) => {
        dispatch({ type: Actions.UPDATE_PROJECT, payload: project })
        setLoading(false)
        onCreate()
      },
      (error: string) => {
        message.error(error)
        setLoading(false)
      }
    )
  }

  return (
    <Modal show={show} onClose={onClose}>
      <div className="margin-bottom-40">
        <h2 className="page-heading">Add Project Source</h2>
      </div>
      <div className="popup-modal-form-block w-form">
        <form
          id="start-new-project"
          name="wf-form-Start-new-project"
          data-name="Start new project"
          method="get"
        >
          <div className="margin-bottom-30">
            <label className="popup-modal-field-label">Language</label>
            <Select
              value={language}
              onChange={(val) => setLanguage(val)}
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .startsWith(input.toLowerCase())
              }
            >
              {languagesList().map((lang: any) => {
                return (
                  <Select.Option key={lang.code} value={lang.code}>
                    {lang.name}
                  </Select.Option>
                )
              })}
            </Select>
          </div>
          <Button
            label="Add Language"
            fontSize={24}
            loading={loading}
            onClick={addLanguage}
          />
        </form>
      </div>
    </Modal>
  )
}

export default AddLanguageModal
