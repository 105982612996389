import { Project, ProjectAsset, ProjectSource } from "@/models/Project"
import React, { useState } from "react"
import { Button, ConfirmModal } from "@/components/common"
import ProjectAssetClient from "@/clients/ProjectAssetClient"
import { message } from "antd"

interface RepositoryDeleteProps {
  asset: ProjectAsset
  project: Project
  onDelete: (asset: ProjectAsset) => void
}

const RepositoryDelete: React.ComponentType<RepositoryDeleteProps> = (
  props: RepositoryDeleteProps
) => {
  const assetClient = new ProjectAssetClient()
  const { asset, project, onDelete } = props
  const [loading, setLoading] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const deleteAsset = () => {
    setShowDeleteModal(false)

    let source = project.sources?.find((source: ProjectSource) => {
      return source.id === asset.source_id
    })

    // console.log(source)

    if(source && source.clone_pages) {
      message.error("Clone all pages is enable for this source. Please set the source to 'Clone Selected Pages' in the Sources section.")
      return;
    }

    setLoading(true)
    assetClient.deleteAsset(project.id!, asset.id!).then(
      () => {
        setLoading(false)
        message.success("Asset successfully deleted.")
        onDelete(asset)
      },
      (error: string) => {
        setLoading(false)
        message.error(error)
      }
    )
  }

  return (
    <div>
      <div
        className="project-overview-card-right"
        style={{ position: "relative" }}
      >
        <div className="project-overview-name-text">Remove from Repository</div>
      </div>
      <div className="content">
        <p>Do you wish to remove this Asset from your Repository?</p>

        <ul className="content-list">
          <li>Deletes this asset from the Repository.</li>
          <li>Deletes all imported versions of this asset.</li>
          <li>
            This operation will not affect a previously published build. You
            will need to publish a build for this change to take effect.
          </li>
        </ul>
      </div>

      <Button
        label="Remove Asset"
        loading={loading}
        className="danger-button"
        onClick={() => setShowDeleteModal(true)}
      />
      <ConfirmModal
        message="Are you sure you want to delete this asset?"
        onClose={() => setShowDeleteModal(false)}
        show={showDeleteModal}
        onConfirm={deleteAsset}
      />
    </div>
  )
}

export default RepositoryDelete
