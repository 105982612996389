import { ProjectSource } from "@/models/Project"
import React, { useContext } from "react"
import moment from "moment"
import MoreHorizontal from "@/resources/images/more-horizontal-2-1.svg"
import Arrow3 from "@/resources/images/Arrow-3.svg"
import {
  AppStateContext,
  AppDispatchContext,
} from "@/components/contexts/AppContext"

interface SourceItemProps {
  source: ProjectSource
  index: number
  onClick: any
  editSource: (source: ProjectSource) => void
  onDelete: any
}

const SourceItem: React.ComponentType<SourceItemProps> = (props) => {
  const { source, index, editSource, onDelete, onClick } = props

  return (
    <div
      onClick={onClick}
      className={`repository-single-item-wrapper ${
        index % 2 == 0 ? "is-grey" : ""
      }`}
    >
      <div className="repository-name-wrapper-w-padding">
        <div className="page-grid-item-text">{source.name}</div>
      </div>
      <div className="repository-name-wrapper">
        <a
          href={source.url_homepage}
          target="_blank"
          className="page-grid-item-link w-inline-block"
          rel="noreferrer"
        >
          <div>
            {source.url_homepage} {}
          </div>
        </a>
      </div>
      <div className="repository-name-wrapper">
        <div className="page-grid-item-text">
          {moment(source.created_at).fromNow()}
        </div>
      </div>
      <div
        data-hover="true"
        data-delay="0"
        className="extra-settings-dropdown w-dropdown"
      >
        <div className="extra-settings-dropdown-toggle w-dropdown-toggle">
          <div className="extra-settings-dropdown-image-wrapper">
            <img
              src={MoreHorizontal}
              loading="lazy"
              alt=""
              className="object-fit-cover"
            />
          </div>
        </div>
        <nav
          className={
            "extra-settings-dropdown-list w-dropdown-list" +
            (source.open_dropdown ? " w--open" : "")
          }
        >
          <div className="extra-settings-dropdown-list-inner">
            <a
              href={source.url_homepage}
              target="_blank"
              className="navbar-user-dropdown-link w-dropdown-link"
              rel="noreferrer"
            >
              View Page
            </a>
            <a
              onClick={() => editSource(source)}
              className="navbar-user-dropdown-link w-dropdown-link"
            >
              Edit Source
            </a>
            <a
              href="#"
              onClick={() => onDelete(source)}
              className="navbar-user-dropdown-link w-dropdown-link"
            >
              Delete Source
            </a>
            <img
              src={Arrow3}
              loading="lazy"
              alt=""
              className="navbar-user-dropdown-triangle"
            />
          </div>
        </nav>
      </div>
    </div>
  )
}

export default SourceItem
