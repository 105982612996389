import React, { useContext, useEffect, useState } from "react"
import { Button } from "@/components/common"
import {
  AppStateContext,
  AppDispatchContext,
} from "@/components/contexts/AppContext"
import ProjectClient from "@/clients/ProjectClient"
import { Project } from "@/models/Project"
import { message, Radio } from "antd"
import { Actions } from "@/components/reducers/AppReducer"

const AzureSettings: React.ComponentType = () => {
  const projectClient = new ProjectClient()
  const { state } = useContext(AppStateContext)
  const { dispatch } = useContext(AppDispatchContext)
  const [accountName, setAccountName] = useState("")
  const [accountKey, setAccountKey] = useState("")
  const [container, setContainer] = useState("")
  const [sasToken, setSasToken] = useState("")
  const [accessType, setAccessType] = useState("access_key")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (state.project!.data.azure_data) {
      setAccessType(state.project!.data.azure_data.access_type)
      setAccountName(state.project!.data.azure_data.name)
      setAccountKey(state.project!.data.azure_data.key)
      setContainer(state.project!.data.azure_data.container)
      setSasToken(state.project!.data.azure_data.sas_token)
    }
  }, [])

  const updateAccessType = (type: string) => {
    setAccessType(type)
  }

  const setupAzure = () => {
    if (accountName.length === 0) {
      message.error("Please enter Azure Account name.")
      return
    }

    if (accessType === "access_key" && accountKey.length === 0) {
      message.error("Please enter Azure Account key.")
      return
    }

    if (accessType === "sas_token" && sasToken.length === 0) {
      message.error("Please enter Blob SAS Token.")
      return
    }

    if (accountKey.length === 0) {
      message.error("Please enter Azure Container name.")
      return
    }

    state.project!.data.hosting = "azure"
    state.project!.data.azure_data = {
      access_type: accessType,
      name: accountName,
      key: accountKey,
      sas_token: sasToken,
      container: container,
    }

    setLoading(true)
    projectClient.updateProject(state.project!.id!, state.project!).then(
      (project: Project) => {
        dispatch({ type: Actions.UPDATE_PROJECT, payload: project })
        setLoading(false)
      },
      (error: string) => {
        message.error(error)
        setLoading(false)
      }
    )
  }

  return (
    <div>
      <div className="translation-single-item-opened">
        <div className="translation-single-item-left">
          <div className="translation-single-item-name-wrapper-opened">
            <div className="icon-wrapper">
              <img
                loading="lazy"
                src="https://uploads-ssl.webflow.com/625e72e3b276d01282f085dd/62d96d17c7c0e9a63426c343_azure.svg"
                alt=""
                className="object-fit-cover"
              />
            </div>
            <div className="translation-single-item-text">Azure</div>
          </div>
        </div>
        <div className="translation-single-item-right">
          <div className="w-form">
            <div className="other-single-item-wrapper">
              <div
                id="w-node-cfc9a258-12ca-a1d2-d884-e1b1f9f3c7c2-907a86d7"
                className="repository-name-wrapper"
              >
                <div>Account Name</div>
              </div>
              <div
                id="w-node-cfc9a258-12ca-a1d2-d884-e1b1f9f3c7c5-907a86d7"
                className="repository-name-wrapper"
              >
                <input
                  type="text"
                  className="no-margin w-input"
                  name="Key"
                  value={accountName}
                  onChange={(e) => setAccountName(e.target.value)}
                  data-name="Key"
                  placeholder="Enter Acount Name"
                  id="Key"
                />
              </div>
            </div>
            <div className="other-single-item-wrapper">
              <div
                id="w-node-_17239fc9-e44b-99a7-ae7c-c115a455709a-907a86d7"
                className="repository-name-wrapper"
              >
                <div>Account Key</div>
              </div>
              <div
                id="w-node-_17239fc9-e44b-99a7-ae7c-c115a455709d-907a86d7"
                className="repository-name-wrapper"
              >
                <input
                  type="text"
                  className="no-margin w-input"
                  name="Key"
                  value={accountKey}
                  onChange={(e) => setAccountKey(e.target.value)}
                  data-name="Key"
                  placeholder="Enter Acount Key"
                  id="Key"
                />
              </div>
            </div>
            <div className="other-single-item-wrapper">
              <div
                id="w-node-_605ab198-92f3-0d15-32db-d3d8d317ff55-907a86d7"
                className="repository-name-wrapper"
              >
                <div>Container</div>
              </div>
              <div
                id="w-node-_605ab198-92f3-0d15-32db-d3d8d317ff58-907a86d7"
                className="repository-name-wrapper"
              >
                <input
                  type="text"
                  className="no-margin w-input"
                  name="Key"
                  value={container}
                  onChange={(e) => setContainer(e.target.value)}
                  data-name="Key"
                  placeholder="Enter Container name"
                  id="Key"
                />
              </div>
            </div>
            <div className="other-single-item-wrapper">
              <div></div>
              <div className="flex">
                <Button
                  label="Update"
                  loading={loading}
                  className="button-black align-right w-button"
                  onClick={setupAzure}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AzureSettings
