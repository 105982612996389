import React from "react"
import { Tabs, Badge, message } from "antd"
import GeneralSettings from "@/components/settings/GeneralSettings"
import RepositorySettings from "@/components/settings/RepositorySettings"
import BuildSettings from "@/components/settings/BuildSettings"
import HostingSettings from "@/components/settings/HostingSettings"
import SubstitutionSettings from "@/components/settings/SubstitutionSettings"
import TranslationSettings from "@/components/settings/TranslationSettings"

const { TabPane } = Tabs

const Settings: React.ComponentType = () => {
  return (
    <div>
      <GeneralSettings />
      <div className="settings-section-separator"></div>
      <div className="padding-vertical-big">
        <div className="settings-heading-wrapper">
          <h2 className="settings-heading normal" id="settings-extensions">Extensions</h2>
        </div>
        <div
          data-current="Tab 1"
          data-easing="ease"
          data-duration-in="300"
          data-duration-out="100"
          className="w-tabs"
        >
          <Tabs defaultActiveKey="hosting">
            <TabPane
              tab={
                <span className="extensions-tab-link w-inline-block w-tab-link">
                  Hosting
                </span>
              }
              key="hosting"
            >
              <div className="extensions-tab-content-wrapper">
                <HostingSettings />
              </div>
            </TabPane>
            <TabPane
              tab={
                <span className="extensions-tab-link w-inline-block w-tab-link">
                  Translations
                </span>
              }
              key="translations"
            >
              <TranslationSettings />
            </TabPane>
          </Tabs>
        </div>
      </div>
      <div className="settings-section-separator"></div>
      <SubstitutionSettings />
      <div className="settings-section-separator"></div>
      <div className="padding-vertical-big">
        <div className="settings-heading-wrapper">
          <h2 className="settings-heading normal">Delete Files</h2>
        </div>
        <BuildSettings />
        <RepositorySettings />
      </div>
    </div>
  )
}

export default Settings
