import { Project, ProjectAsset } from "@/models/Project"
import React, { useEffect, useState } from "react"
import moment from "moment"
import { Button } from "@/components/common"
import ProjectAssetClient from "@/clients/ProjectAssetClient"
import { message } from "antd"
import {
  truncateUrl,
  getStagingUrl,
  getTruncatedStagingUrl,
} from "@/utilities/String"

interface RepositoryConfigureTabProps {
  asset: ProjectAsset
  project: Project
  setReloadAssets: (reload: boolean) => void
}

const RepositoryConfigureTab: React.ComponentType<
  RepositoryConfigureTabProps
> = (props: RepositoryConfigureTabProps) => {
  const assetClient = new ProjectAssetClient()
  const { asset, project, setReloadAssets } = props
  const [path, setPath] = useState("")
  const [sourceUrl, setSourceUrl] = useState("")
  const [type, setType] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setPath(asset.path!)
    setSourceUrl(asset.url_source)
    setType(asset.type!)
  }, [asset])

  const updateAsset = () => {
    setLoading(true)
    asset.path = path
    asset.url_source = sourceUrl
    asset.type = type

    assetClient.updateAsset(project.id!, asset.id!, asset).then(
      (res: ProjectAsset) => {
        setLoading(false)
        setReloadAssets(true)
      },
      (error: string) => {
        message.error(error)
        setLoading(false)
      }
    )
  }

  return (
    <div>
      <div className="project-overview-card-right">
        <div className="project-overview-name-text">Overview</div>
        <div className="project-overview-single-item is-two-rows-mobile">
          <div
            id="w-node-d7fc5910-4378-d8b6-5739-c62ed79cd2e7-d32c5218"
            className="text-medium"
          >
            Source
          </div>
          <a
            href={asset.url_source}
            target="_blank"
            className="project-overview-link-wrapper w-inline-block"
            style={{ padding: 0, paddingRight: 20 }}
            rel="noreferrer"
          >
            <div
              id="w-node-d7fc5910-4378-d8b6-5739-c62ed79cd2e9-d32c5218"
              className="project-overview-link"
            >
              {truncateUrl(asset.url_source!, 60)}
            </div>
          </a>
        </div>
        <div className="project-overview-single-item is-two-rows-mobile">
          <div
            id="w-node-d7fc5910-4378-d8b6-5739-c62ed79cd2e7-d32c5218"
            className="text-medium"
          >
            Staging URL
          </div>
          <a
            href={asset.path && getStagingUrl(asset.path, project.alias)}
            target="_blank"
            className="project-overview-link-wrapper w-inline-block"
            style={{ padding: 0, paddingRight: 20 }}
            rel="noreferrer"
          >
            <div
              id="w-node-d7fc5910-4378-d8b6-5739-c62ed79cd2e9-d32c5218"
              className="project-overview-link"
            >
              {asset.path && getTruncatedStagingUrl(asset.path, project.alias)}
            </div>
          </a>
        </div>
        <div className="project-overview-single-item">
          <div
            id="w-node-_0484f09e-a15b-d8b3-230f-df2c6e8aeb09-d32c5218"
            className="text-medium"
          >
            Last Imported
          </div>
          <div
            id="w-node-_9ee25719-9534-b26c-c99c-3ca5ab3a4cf3-d32c5218"
            className="text-light"
          >
            {moment(asset.updated_at).fromNow()}
          </div>
        </div>
      </div>
      <div
        className="project-overview-card-right"
        style={{ marginTop: 40, width: 400 }}
      >
        <div className="project-overview-name-text">General Settings</div>
        <form
          id="start-new-project"
          name="wf-form-Start-new-project"
          data-name="Start new project"
          method="get"
        >
          <div className="margin-bottom-30">
            <label className="popup-modal-field-label">Local Path</label>
            <input
              type="text"
              className="popup-modal-input-field w-input"
              name="Path"
              value={path}
              onChange={(e) => setPath(e.target.value)}
              data-name="Path"
              placeholder="Primary Source"
              id="Project-Name"
            />
            <label className="popup-modal-field-label">
              Source URL (Static)
            </label>
            <input
              type="text"
              className="popup-modal-input-field w-input"
              name="Source-URL"
              value={sourceUrl}
              readOnly
              data-name="Alias"
              placeholder="www.site.com"
              id="Source-URL"
              required
            />
            <label className="popup-modal-field-label">Type</label>
            <input
              type="text"
              className="popup-modal-input-field w-input"
              name="Alias"
              value={type}
              readOnly
              data-name="Alias"
              placeholder=""
              id="Sitemap-URL"
              required
            />
          </div>
          <Button
            label="Update Asset"
            className="button-black w-button"
            loading={loading}
            onClick={updateAsset}
          />
        </form>
      </div>
    </div>
  )
}

export default RepositoryConfigureTab
