import BaseClient from "./BaseClient"

class LokaliseClient extends BaseClient {
  /**
   * Add files to Lokalise for Translation
   * @param project_id
   * @param source_url
   * @param lang
   * @returns Promise
   */
  addFileToLokalise = (
    project_id: number,
    source_url: Array<any>,
    lang: string,
    type: string
  ): Promise<any> => {
    return this.post(`/lokalisefile`, { project_id, source_url, lang, type })
  }

  updateLokaliseFile = (id: number, data: { lang: string }): Promise<any> => {
    return this.put(`/lokalisefile/${id}`, data)
  }

  /**
   * Delete file in Lokalise
   * @param id
   * @returns Promise
   */
  deleteFileInLokalise = (id: number): Promise<any> => {
    return this.delete(`/lokalisefile/${id}`)
  }
}

export default LokaliseClient
