import React, { useEffect, useState } from "react"
import grid from "@/resources/images/grid-1-1.svg"
import columns from "@/resources/images/columns-1-1.svg"
import arrowDown from "@/resources/images/arrow-down.svg"
import Arrow3 from "@/resources/images/Arrow-3.svg"
import arrowUpRight from "@/resources/images/arrow-up-right-1-1.svg"
import closeIcon from "@/resources/images/close-icon.svg"
import JWTHelper from "@/utilities/JWTHelper"
import { useNavigate } from "react-router-dom"
import ProjectClient from "@/clients/ProjectClient"
import { Paginator } from "@/models/Paginator"
import { Project } from "@/models/Project"
import { Skeleton, message } from "antd"
import moment from "moment"
import ProjectCard from "@/components/project/ProjectCard"
import AccountSettings from "@/components/common/AccountSettings"
import CreateProjecdtModal from "@/components/modals/CreateProjectModal"
import DeleteProjectModal from "@/components/modals/DeleteProjectModal"

const Home: React.ComponentType = () => {
  let navigate = useNavigate()
  let projectClient = new ProjectClient()

  const [projectData, setProjectData] = useState<Paginator<Project>>()
  const [projects, setProjects] = useState<Array<Project>>([])
  const [loading, setLoading] = useState(false)
  const [loadMoreLoading, setLoadMoreLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(15)
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteProject, setDeleteProject] = useState<Project>()

  useEffect(() => {
    if (!JWTHelper.isLogin()) {
      navigate("/login", { replace: true })
    } else {
      // navigate("/project/accel-today", { replace: true });
      loadProject()
    }
  }, [])

  const loadProject = () => {
    setLoading(true)
    projectClient.getAllProjects({ limit, page, search }).then(
      (data: Paginator<Project>) => {
        setProjectData(data)
        setProjects(projects.concat(data.data))
        setLoading(false)
      },
      (error: string) => {
        message.error(error)
      }
    )
  }

  const onCreateProject = (project: Project) => {
    projects.unshift(project)
    setProjects(projects)
    setShowModal(false)
  }

  const onDeleteProject = (project: Project) => {
    let prj = projects.filter((p) => {
      return p.id !== project.id
    })

    setProjects([...prj])
    setShowDeleteModal(false)
  }

  const setProjectToDelete = (project: Project) => {
    setDeleteProject(project)
    setShowDeleteModal(true)
  }

  return (
    <div>
      <div>
        <div
          data-animation="default"
          data-collapse="small"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="navbar-home w-nav"
        >
          <div className="navbar-container">
            <a href="#" className="w-nav-brand">
              <div className="navbar-wes-logo">Wes</div>
            </a>
            <nav role="navigation" className="navbar-menu w-nav-menu">
              <a
                href="#"
                className="start-new-project-button w-inline-block"
                onClick={() => setShowModal(true)}
              >
                <img src={grid} loading="lazy" alt="" />
                <div>Start New Project</div>
              </a>
              <AccountSettings />
            </nav>
            <div className="w-nav-button">
              <div className="w-icon-nav-menu"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="main">
        <div className="page-padding">
          <div className="container">
            <div className="padding-vertical">
              <div className="project-overview-cards-wrapper">
                {/* <div className="project-overview-filters-wrapper">
                  <div className="project-overview-filters">
                    <div className="project-overview-filters-holder">
                      <a href="#" className="w-inline-block"><img src={grid} loading="lazy" alt="" /></a>
                      <a href="#" className="w-inline-block"><img src={columns} loading="lazy" alt="" /></a>
                    </div>
                    <div data-hover="false" data-delay="0" className="w-dropdown">
                      <div className="project-filters-dropdown w-dropdown-toggle">
                        <div className="dropdown-icon w-icon-dropdown-toggle"></div>
                        <div>Last modified</div>
                      </div>
                      <nav className="w-dropdown-list">
                        <a href="#" className="w-dropdown-link">Link 1</a>
                        <a href="#" className="w-dropdown-link">Link 2</a>
                        <a href="#" className="w-dropdown-link">Link 3</a>
                      </nav>
                    </div>
                  </div>
                </div> */}

                {loading && <Skeleton loading={loading} />}
                {!loading &&
                  projects.map((project: Project) => {
                    return (
                      <ProjectCard
                        key={project.alias}
                        project={project}
                        setDeleteProject={setProjectToDelete}
                      />
                    )
                  })}

                {/* <a href="#" className="load-more-button w-button">Load More</a> */}
              </div>
            </div>
          </div>
        </div>
        <CreateProjecdtModal
          show={showModal}
          onClose={() => setShowModal(false)}
          onCreate={onCreateProject}
        />
        <DeleteProjectModal
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          project={deleteProject!}
          onDelete={onDeleteProject}
        />
      </div>
      <div>
        <div className="page-padding">
          <div className="container">
            <div className="footer">
              <div className="footer-text">© 2022 Edgar Allan</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
