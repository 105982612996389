import React from "react"
import { Link } from "react-router-dom"

const TermService = () => {
  return (
    <React.Fragment>
      <link rel="stylesheet" href="/css/landing/components.css"></link>
      <link rel="stylesheet" href="/css/landing/normalize.css"></link>
      <link rel="stylesheet" href="/css/landing/wes-landing-page.css"></link>
      <link rel="stylesheet" href="/css/landingpage.css"></link>
      <div className="page-wrapper gradient">
        <div
          data-animation="default"
          className="navbar4_component w-nav"
          data-easing2="ease"
          fs-scrolldisable-element="smart-nav"
          data-easing="ease"
          data-collapse="all"
          data-w-id="e0e2adeb-8a20-0fb3-a322-77e438213f12"
          role="banner"
          data-duration="400"
          data-doc-height="1"
        >
          <div
            data-w-id="685e9566-0db0-54ed-1639-4656d8a303a0"
            className="nav-background"
          ></div>
          <div className="navbar4_container">
            <a href="index.html" className="navbar4_logo-link w-nav-brand">
              <img
                src="images/Wes.svg"
                loading="lazy"
                alt="Wes"
                className="navbar4_logo"
              />
            </a>
            <div className="navbar4_wrapper">
              <Link to="/login"
                className="button is-hero is-tertiary w-button"
              >
                Log In
              </Link>
              {/* <a
                data-w-id="e0e2adeb-8a20-0fb3-a322-77e438213f19"
                href="#"
                className="button is-navbar4-button w-button"
              >
                Book a Demo
              </a> */}
            </div>
          </div>
        </div>
        <main className="main-wrapper">
          <section className="section_content7">
            <div className="padding-global">
              <div className="container-small">
                <div className="padding-section-large">
                  <div className="content7_component">
                    <div className="margin-bottom margin-small">
                      <h1
                        id="w-node-e07f61d8-1278-16a8-4fdd-ecc47768c926-b659957c"
                        className="heading-style-h2"
                      >
                        Terms of Service
                      </h1>
                    </div>
                    <div className="text-rich-text w-richtext">
                      <p>
                        These terms of service (&quot;Agreement&quot;) govern
                        your access to and use of the Wes services
                        (&quot;Services&quot;). Please read this Agreement
                        carefully before using Wes. By using the Services, you
                        indicate your acceptance of this Agreement.
                      </p>
                      <h2>1. Service Description</h2>
                      <p>
                        <strong>1.1 Primary Purpose and Functionality: </strong>
                        Wes is a solutions toolkit designed to enable the
                        creation of tailored server-side configurations for
                        Webflow in enterprise environments. It offers large
                        organizations the flexibility to customize, integrate,
                        and deploy Webflow at scale, enhancing their web
                        development capabilities.
                      </p>
                      <p>
                        <strong>1.2 Features and Functionalities:</strong> Wes
                        provides a range of features, including but not limited
                        to:
                      </p>
                      <ul role="list">
                        <li>
                          Configuration management tools for Webflow sites
                        </li>
                        <li>
                          Integration capabilities with third-party services and
                          APIs
                        </li>
                        <li>
                          Advanced deployment options for efficient scaling
                        </li>
                        <li>Collaborative features for team-based workflows</li>
                        <li>
                          Comprehensive analytics and reporting functionalities
                        </li>
                      </ul>
                      <h2>2. User Accounts</h2>
                      <p>
                        <strong>2.1 User Registration:</strong> To access and
                        use Wes, users must register for an account by providing
                        accurate and up-to-date information, including their
                        email address and relevant Webflow URLs.
                      </p>
                      <p>
                        <strong>2.2 Account Credentials: </strong>Users are
                        responsible for maintaining the confidentiality of their
                        account credentials and ensuring the security of their
                        Wes accounts. They must promptly notify Wes of any
                        unauthorized access or suspected security breaches.
                      </p>
                      <p>
                        <strong>2.3 Age Restrictions:</strong> The use of Wes is
                        intended for individuals who have reached the age of
                        majority in their jurisdiction. By using Wes, users
                        represent that they meet the age requirements specified
                        in their jurisdiction.
                      </p>
                      <h2>3. Acceptance of Terms</h2>
                      <p>
                        <strong>3.1 Agreement Acceptance:</strong> By using the
                        Wes services, you acknowledge that you have read,
                        understood, and agree to be bound by these terms of
                        service.
                      </p>
                      <p>
                        <strong>3.2 Additional Agreements:</strong> In addition
                        to these terms, users must also comply with any
                        additional agreements or policies specified by Wes and
                        Webflow, including but not limited to Webflow&#x27;s
                        Master Service Agreement (MSA) or Terms of Service
                        (ToS).
                      </p>
                      <h2>4. User Responsibilities</h2>
                      <p>
                        <strong>4.1 Proper Use: </strong>Users are responsible
                        for using Wes in compliance with all applicable laws,
                        regulations, and these terms of service. They must
                        refrain from using the Services for any unlawful,
                        fraudulent, or malicious activities.
                      </p>
                      <p>
                        <strong>4.2 Webflow Site Ownership:</strong> Users may
                        only utilize Wes for Webflow sites owned or authorized
                        by their organization. Any unauthorized use of Wes on
                        external or unauthorized Webflow sites is strictly
                        prohibited.
                      </p>
                      <p>
                        <strong>4.3 Compliance with Policies: </strong>Users
                        must adhere to the policies set forth in Webflow&#x27;s
                        MSA or ToS while utilizing Wes. This includes but is not
                        limited to respecting Webflow&#x27;s acceptable use
                        policies, content guidelines, and intellectual property
                        rights.
                      </p>
                      <p>
                        <strong>4.4 Prohibited Activities:</strong> Users shall
                        not use Wes to avoid fees or services provided by
                        Webflow, engage in any form of hacking, data scraping,
                        or other unauthorized activities, or violate the rights
                        of any third party.
                      </p>
                      <p>
                        <strong>4.5 Account and Environment Security:</strong>{" "}
                        Users are responsible for maintaining the security and
                        confidentiality of their Wes accounts, ensuring the
                        privacy and protection of their passwords, and taking
                        appropriate measures to secure their own computing
                        environments.
                      </p>
                      <h2>5. Intellectual Property</h2>
                      <p>
                        <strong>5.1 Ownership:</strong> Edgar Allan LLC owns all
                        intellectual property rights related to the Wes product,
                        including but not limited to its software, codebase,
                        design elements, and documentation.
                      </p>
                      <p>
                        <strong>5.2 User Content: </strong>By using Wes, users
                        retain ownership of the intellectual property rights to
                        the content they create, upload, or share through the
                        Services. Wes does not claim ownership of user-generated
                        content.
                      </p>
                      <p>
                        <strong>5.3 Use of Intellectual Property: </strong>Users
                        are granted a limited, non-exclusive, non-transferable
                        license to use Wes solely for the purpose of accessing
                        and utilizing the Services. Users may not modify, copy,
                        distribute, transmit, display, perform, reproduce,
                        publish, license, create derivative works from,
                        transfer, or sell any information, software, products,
                        or services obtained from or through Wes.
                      </p>
                      <p>
                        <strong>
                          5.4 Restrictions on Confidential and Personally
                          Identifiable Information:{" "}
                        </strong>
                        Users must exercise caution and refrain from sharing or
                        transmitting any confidential or personally identifiable
                        information through Wes. Users acknowledge that Wes is
                        not responsible for any unauthorized access or
                        disclosure of such information shared by users.
                      </p>
                      <h2>6. Payment and Subscriptions</h2>
                      <p>
                        <strong>6.1 Paid Services: </strong>Wes is a paid
                        product, and users are required to pay the applicable
                        fees as specified in the pricing or subscription plan
                        provided by Wes.
                      </p>
                      <p>
                        <strong>6.2 Payment Processing:</strong> Wes utilizes
                        secure payment processing services to handle payment
                        transactions. Users agree to provide accurate and
                        complete payment information and authorize Wes to charge
                        the designated payment method for the applicable fees.
                      </p>
                      <p>
                        <strong>6.3 Payment Disputes and Refunds: </strong>In
                        the event of a payment dispute, users must notify Wes
                        within a reasonable timeframe. Wes will make reasonable
                        efforts to resolve the issue promptly. Refunds, if
                        applicable, will be handled in accordance with
                        Wes&#x27;s refund policy.
                      </p>
                      <p>
                        <strong>6.4 Suspension for Non-Payment: </strong>Failure
                        to make timely payment may result in the suspension or
                        termination of access to Wes. Wes reserves the right to
                        take appropriate measures to recover any outstanding
                        payments, including but not limited to the use of debt
                        collection agencies.
                      </p>
                      <h2>7. Privacy and Data Protection</h2>
                      <p>
                        <strong>7.1 Data Collection and Usage: </strong>Wes
                        collects and uses user data solely for the purpose of
                        providing and improving the Services. Wes follows
                        industry-standard practices to safeguard user data and
                        employs appropriate technical and organizational
                        measures to protect against unauthorized access, loss,
                        or alteration of data.
                      </p>
                      <p>
                        <strong>7.2 Third-Party Services: </strong>Wes may
                        integrate with third-party services or utilize
                        third-party analytics tools to improve the Services.
                        Users acknowledge and agree that such third-party
                        services may collect and process data in accordance with
                        their own privacy policies and terms of service.
                      </p>
                      <p>
                        <strong>7.3 Data Retention: </strong>User data will be
                        retained for as long as necessary to fulfill the
                        purposes outlined in this Agreement unless a longer
                        retention period is required or permitted by law.
                      </p>
                      <h2>8. Termination and Suspension</h2>
                      <p>
                        <strong>8.1 Termination by User:</strong> Users may
                        terminate their Wes account at any time by following the
                        account closure process provided by Wes. Termination of
                        the account will result in the cessation of access to
                        the Services and deletion of associated user data.
                      </p>
                      <p>
                        <strong>8.2 Termination by Wes: </strong>Wes reserves
                        the right to terminate or suspend user accounts, in
                        whole or in part, in the event of violation of these
                        terms of service, non-payment, unauthorized use, or any
                        other conduct that Wes deems detrimental to the Services
                        or other users.
                      </p>
                      <p>
                        <strong>8.3 Data upon Termination: </strong>Upon
                        termination of the account, Wes will make reasonable
                        efforts to provide users with the opportunity to export
                        or retrieve their data. Wes is not responsible for any
                        data loss or damages that may occur after termination.
                      </p>
                      <p>
                        <strong>8.4 Survival of Terms: </strong>The provisions
                        regarding intellectual property rights, limitations of
                        liability, indemnification, and dispute resolution shall
                        survive any termination or expiration of this Agreement.
                      </p>
                      <h2>9. Limitation of Liability</h2>
                      <p>
                        <strong>9.1 Disclaimer of Warranties:</strong> Wes is
                        provided on an &quot;as is&quot; and &quot;as
                        available&quot; basis. Wes does not make any warranties,
                        whether express, implied, or statutory, regarding the
                        Services, including but not limited to warranties of
                        merchantability, fitness for a particular purpose,
                        accuracy, reliability, or non-infringement.
                      </p>
                      <p>
                        <strong>9.2 Limitation of Liability: </strong>To the
                        maximum extent permitted by law, Wes and its officers,
                        directors, employees, agents, and affiliates shall not
                        be liable for any indirect, incidental, special,
                        consequential, or punitive damages, or any loss of
                        profits, data, or business opportunities, arising out of
                        or in connection with the use or inability to use the
                        Services.
                      </p>
                      <h2>10. Issue Resolution and Contact</h2>
                      <p>
                        <strong>10.1 Issue Reporting:</strong> Users should
                        promptly report any issues, complaints, or
                        questionsrelated to the Services to Wes&#x27;s customer
                        support team. Users can contact the customer support
                        team through the designated communication channels
                        provided by Wes, such as email or support ticketing
                        system.
                      </p>
                      <p>
                        <strong>10.2 Account Representative: </strong>Users may
                        also reach out to their designated account
                        representative as defined in the Webflow Master Service
                        Agreement (MSA) or other applicable agreements for
                        assistance, issue resolution, or account-related
                        inquiries.
                      </p>
                      <p>
                        <strong>10.3 Notice:</strong> Any notices or
                        communications required or permitted under this
                        Agreement shall be deemed effective when sent in writing
                        to the contact information provided by the user or
                        posted on Wes&#x27;s website or within the user&#x27;s
                        account.
                      </p>
                      <h2>11. Governing Law and Jurisdiction</h2>
                      <p>
                        <strong>11.1 Governing Law:</strong> This Agreement
                        shall be governed by and construed in accordance with
                        the laws of the jurisdiction in which Wes operates.
                      </p>
                      <p>
                        <strong>11.2 Jurisdiction:</strong> Any disputes arising
                        out of or in connection with this Agreement shall be
                        subject to the exclusive jurisdiction of the courts in
                        the jurisdiction where Wes operates.
                      </p>
                      <h2>12. Severability</h2>
                      <p>
                        If any provision of this Agreement is held to be
                        invalid, illegal, or unenforceable, the remaining
                        provisions shall continue in full force and effect. In
                        such cases, the invalid or unenforceable provision shall
                        be replaced with a valid and enforceable provision that
                        achieves the original intent of the Agreement to the
                        maximum extent possible.
                      </p>
                      <h2>13. Entire Agreement</h2>
                      <p>
                        This Agreement constitutes the entire agreement between
                        the user and Wes regarding the subject matter herein and
                        supersedes all prior or contemporaneous communications,
                        discussions, and proposals, whether oral or written,
                        between the user and Wes.
                      </p>
                      <h2>14. Waiver</h2>
                      <p>
                        The failure of Wes to enforce any right or provision of
                        this Agreement shall not constitute a waiver of such
                        right or provision unless acknowledged and agreed to in
                        writing. Any waiver of any provision of this Agreement
                        shall only be effective if it is in writing and signed
                        by an authorized representative of Wes.
                      </p>
                      <h2>15. Contact Information</h2>
                      <p>
                        If you have any questions, concerns, or require further
                        information regarding these terms of service, please
                        contact your account manager.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer className="footer4_component">
          <div className="padding-global">
            <div className="container-large">
              <div className="padding-vertical padding-small">
                <div className="w-layout-grid footer4_top-wrapper">
                  <a
                    href="index.html"
                    id="w-node-ee999d70-510f-77aa-68d9-ad3b2d593bf2-2d593bed"
                    className="footer4_logo-link w-nav-brand"
                  >
                    <img src="images/Wes.svg" loading="lazy" alt="Wes" />
                  </a>
                  <div className="w-layout-grid footer4_link-list">
                    <Link to="/policy"
                      id="w-node-_630333c3-5fc9-5e71-243c-aa5930162276-2d593bed"
                      className="footer4_link"
                    >
                      Privacy Policy
                    </Link>
                    <Link to="/term"
                      aria-current="page"
                      className="footer4_link w--current"
                    >
                      Terms of Service
                    </Link>
                  </div>
                  <div
                    id="w-node-ee999d70-510f-77aa-68d9-ad3b2d593bf6-2d593bed"
                    className="w-layout-grid footer4_social-list"
                  >
                    <a
                      href="https://www.edgarallan.com/"
                      target="_blank"
                      className="made-by-ea w-inline-block"
                    >
                      <div>Made By</div>
                      <img
                        src="images/ea-logo-dot.svg"
                        loading="lazy"
                        alt=""
                        className="image"
                      />
                    </a>
                    <div className="display-none">
                      <a
                        href="https://www.linkedin.com/company/edgar-allan"
                        target="_blank"
                        className="footer4_social-link w-inline-block"
                      >
                        <div className="social-icon w-embed">
                          <svg
                            width="100%"
                            height="100%"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3ZM8 18C8.27614 18 8.5 17.7761 8.5 17.5V10.5C8.5 10.2239 8.27614 10 8 10H6.5C6.22386 10 6 10.2239 6 10.5V17.5C6 17.7761 6.22386 18 6.5 18H8ZM7.25 9C6.42157 9 5.75 8.32843 5.75 7.5C5.75 6.67157 6.42157 6 7.25 6C8.07843 6 8.75 6.67157 8.75 7.5C8.75 8.32843 8.07843 9 7.25 9ZM17.5 18C17.7761 18 18 17.7761 18 17.5V12.9C18.0325 11.3108 16.8576 9.95452 15.28 9.76C14.177 9.65925 13.1083 10.1744 12.5 11.1V10.5C12.5 10.2239 12.2761 10 12 10H10.5C10.2239 10 10 10.2239 10 10.5V17.5C10 17.7761 10.2239 18 10.5 18H12C12.2761 18 12.5 17.7761 12.5 17.5V13.75C12.5 12.9216 13.1716 12.25 14 12.25C14.8284 12.25 15.5 12.9216 15.5 13.75V17.5C15.5 17.7761 15.7239 18 16 18H17.5Z"
                              fill="CurrentColor"
                            ></path>
                          </svg>
                        </div>
                      </a>
                      <a
                        href="https://www.youtube.com/edgarallan"
                        target="_blank"
                        className="footer4_social-link w-inline-block"
                      >
                        <div className="social-icon w-embed">
                          <svg
                            width="100%"
                            height="100%"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M18.5399 4.33992L19.9999 4.48992C21.7284 4.68529 23.0264 6.16064 22.9999 7.89992V16.0999C23.0264 17.8392 21.7284 19.3146 19.9999 19.5099L18.5999 19.6599C14.2315 20.1099 9.82835 20.1099 5.45991 19.6599L3.99991 19.5099C2.27143 19.3146 0.973464 17.8392 0.999909 16.0999V7.89992C0.973464 6.16064 2.27143 4.68529 3.99991 4.48992L5.39991 4.33992C9.76835 3.88995 14.1715 3.88995 18.5399 4.33992ZM11.1099 15.2199L14.9999 12.6199H15.0599C15.2695 12.4833 15.3959 12.2501 15.3959 11.9999C15.3959 11.7497 15.2695 11.5165 15.0599 11.3799L11.1699 8.77992C10.9402 8.62469 10.6437 8.60879 10.3987 8.73859C10.1538 8.86839 10.0004 9.12271 9.99991 9.39992V14.5999C10.0128 14.858 10.1576 15.0913 10.3832 15.2173C10.6088 15.3433 10.8834 15.3443 11.1099 15.2199Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </div>
                      </a>
                      <a
                        href="https://twitter.com/EdgarAllanCo"
                        target="_blank"
                        className="footer4_social-link w-inline-block"
                      >
                        <div className="social-icon w-embed">
                          <svg
                            width="100%"
                            height="100%"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20.9728 6.7174C20.5084 7.33692 19.947 7.87733 19.3103 8.31776C19.3103 8.47959 19.3103 8.64142 19.3103 8.81225C19.3154 11.7511 18.1415 14.5691 16.0518 16.6345C13.962 18.6999 11.1312 19.8399 8.19405 19.7989C6.49599 19.8046 4.81967 19.4169 3.29642 18.6661C3.21428 18.6302 3.16131 18.549 3.16162 18.4593V18.3604C3.16162 18.2313 3.26623 18.1267 3.39527 18.1267C5.06442 18.0716 6.67402 17.4929 7.99634 16.4724C6.48553 16.4419 5.12619 15.5469 4.5006 14.1707C4.46901 14.0956 4.47884 14.0093 4.52657 13.9432C4.57429 13.8771 4.653 13.8407 4.73425 13.8471C5.19342 13.8932 5.65718 13.8505 6.1002 13.7212C4.43239 13.375 3.17921 11.9904 2.99986 10.2957C2.99349 10.2144 3.02992 10.1357 3.096 10.0879C3.16207 10.0402 3.24824 10.0303 3.32338 10.062C3.77094 10.2595 4.25409 10.3635 4.74324 10.3676C3.28184 9.40846 2.65061 7.58405 3.20655 5.92622C3.26394 5.76513 3.40181 5.64612 3.5695 5.61294C3.73718 5.57975 3.90996 5.63728 4.02432 5.76439C5.99639 7.86325 8.70604 9.11396 11.5819 9.25279C11.5083 8.95885 11.4721 8.65676 11.4741 8.35372C11.501 6.76472 12.4842 5.34921 13.9634 4.76987C15.4425 4.19054 17.1249 4.56203 18.223 5.71044C18.9714 5.56785 19.695 5.31645 20.3707 4.96421C20.4202 4.93331 20.483 4.93331 20.5325 4.96421C20.5634 5.01373 20.5634 5.07652 20.5325 5.12604C20.2052 5.87552 19.6523 6.50412 18.9509 6.92419C19.5651 6.85296 20.1685 6.70807 20.7482 6.49264C20.797 6.45942 20.8611 6.45942 20.9099 6.49264C20.9508 6.51134 20.9814 6.54711 20.9935 6.59042C21.0056 6.63373 20.998 6.68018 20.9728 6.7174Z"
                              fill="CurrentColor"
                            ></path>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </React.Fragment>
  )
}

export default TermService
