import { Notification } from "@/models/Notification"
import { Paginator } from "@/models/Paginator"
import BaseClient from "./BaseClient"

class NotificationClient extends BaseClient {
  /**
   * Get all notifications
   * @param projectId
   * @returns Promise<Paginator<Notification>>
   */
  getNotifications = (
    project_id: number,
    page: number
  ): Promise<Paginator<Notification>> => {
    return this.get("/notification", { params: { project_id, page } })
  }

  getNotificationCount = (project_id: number): Promise<any> => {
    return this.get("/notification/unread/count", { params: { project_id } })
  }
}

export default NotificationClient
