import React, { useContext, useEffect, useState } from "react"
import { AppDispatchContext, AppStateContext } from "../contexts/AppContext"
import ProjectClient from "@/clients/ProjectClient"
import { useParams } from "react-router-dom"
import { Project as ProjectModel } from "@/models/Project"
import { Actions } from "../reducers/AppReducer"
import { message, notification, Spin } from "antd"
import HomeIcon from "@/resources/images/home.svg"
import Arrow3 from "@/resources/images/Arrow-3.svg"
import DashboardIcon from "@/resources/images/dashboard.svg"
import LinkIcon from "@/resources/images/link.svg"
import DatabaseIcon from "@/resources/images/database.svg"
import ArchiveIcon from "@/resources/images/archive.svg"
import SliderIcon from "@/resources/images/sliders.svg"
import Profile from "@/resources/images/icon-profile_1.svg"
import TranslationIcon from "@/resources/images/grid-1-1.svg"
import ArrowDown from "@/resources/images/arrow-down.svg"
import {
  Outlet,
  Link,
  NavLink,
  useNavigate,
  useLocation,
} from "react-router-dom"
import AccountSettings from "../common/AccountSettings"
import { Notification } from "@/models/Notification"
import Echo from "laravel-echo"
import JWTHelper from "@/utilities/JWTHelper"
const Pusher = require("pusher-js")

const Project: React.ComponentType = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = useContext(AppStateContext)
  const { dispatch } = useContext(AppDispatchContext)
  const projectClient = new ProjectClient()
  const { alias } = useParams()
  const [echo, setEcho] = useState<Echo | null>()
  const [loading, setLoading] = useState(false)
  const [isListening, setIsListening] = useState(false)
  const [launchUrl, setLaunchUrl] = useState("")
  const [insertNotification, setInsertNotificaiton] = useState<Notification>()

  useEffect(() => {
    if (!JWTHelper.isLogin()) navigate("/login", { replace: true })
    else loadProject()
  }, [])

  useEffect(() => {
    if (insertNotification) {
      state.notifications!.unshift(insertNotification)
      dispatch({
        type: Actions.UPDATE_NOTIFICATIONS,
        payload: state.notifications,
      })
    }
  }, [insertNotification])

  useEffect(() => {
    if (echo === undefined) return
    // console.log("Start listening")

    let filechannel = echo!
      .private(`publish-project.${state.project!.id}`)
      .listen(".move.files", (e: any) => {
        // console.log("Listen to publish files")
        // console.log(e);
        // if (e.file && !this.publishedFiles.includes(e.file)) {
        // let files = this.publishedFiles;
        // files.push(e.file);
        // this.$store.commit("setPublishedFiles", [...files]);
        // state.project!.publish_percent = e.percent
        if (!state.project!.is_publishing) {
          state.project!.is_publishing = true
          dispatch({ type: Actions.UPDATE_PROJECT, payload: state.project })
        }
        // this.$store.commit("setPublishPercent", e.percent);
        dispatch({ type: Actions.UPDATE_PUBLISH_PERCENT, payload: e.percent })
        if (e.isLast) {
          state.project!.is_publishing = false
          state.project!.is_dirty = false
          state.project!.is_published = true
          console.log("Publish finished.")
          dispatch({ type: Actions.UPDATE_PROJECT, payload: state.project })
        }
        // console.log(state.project!.publish_percent);
        // this.$store.commit("setCurrentProject", state.project);
        // console.log(state.project)

        // }
      })
      .listen(".publish.project", (e: any) => {
        console.log("listening to publish")
        // console.log(e);
      })

    let notifchannel = echo!
      .private(`project.${state.project!.id}.notifications`)
      .listen(".send.notification", (e: any) => {
        updateNotifications(e.notification)
        // let notifications = state.notifications!.unshift(e.notification);
        // dispatch({ type: Actions.UPDATE_NOTIFICATIONS, payload: notifications});
        message.info(e.notification.message)
        if (
          e.notification.type === "update_pages_success" &&
          e.notification.project_id === state.project!.id
        ) {
          state.project!.is_publishing = false
          state.project!.is_dirty = false
          state.project!.is_published = true
          // console.log(this.project.publish_percent);
          // console.log(state.project)
          dispatch({ type: Actions.UPDATE_PROJECT, payload: state.project })
        }
      })

    setIsListening(true)

    return () => {
      console.log("Destroy socket listeners")
      setIsListening(false)
      // setEcho(undefined)
      notifchannel.stopListening(".send.notification")
      filechannel.stopListening(".move.files")
    }
  }, [state.project])

  // some neat trcik to update state within Laravel echo
  const updateNotifications = (notification: Notification) => {
    setInsertNotificaiton(notification)
  }

  const loadProject = () => {
    setLoading(true)
    projectClient.getProject(alias!).then(
      (project: ProjectModel) => {
        // project.is_publishing = false
        dispatch({ type: Actions.UPDATE_PROJECT, payload: project })
        initializeEcho()
        setLoading(false)
        setLaunchUrl(
          process.env.REACT_APP_SITE_URL + `/sites/${project.alias}/`
        )
      },
      (error: string) => {
        // message.error(error)
        setLoading(false)
        navigate("/", { replace: true })
      }
    )
  }

  const initializeEcho = () => {
    if (echo !== undefined)
      // don't re-initiliaze echo so it will not send multiple notifications
      return

    let client = new Echo({
      broadcaster: "pusher",
      key: process.env.REACT_APP_PUSHER_APP_KEY,
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      forceTLS: false,
      authEndpoint: process.env.REACT_APP_API_BASE_URL + "/broadcasting/auth",
      auth: {
        headers: { Authorization: "Bearer " + JWTHelper.getAccessToken() },
      },
    })
    setEcho(client)
  }

  const isCurrentUrl = (path: string): boolean => {
    if (location.pathname.endsWith(state.project?.alias + path)) return true
    return false
  }

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: window.innerHeight,
        }}
      >
        <Spin />
      </div>
    )

  if (!state.project) return null

  return (
    <div className="page-wrapper">
      <div>
        <div
          data-animation="default"
          data-collapse="none"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="navbar-home w-nav"
        >
          <div className="navbar-container">
            <div className="navbar-global-left">
              <Link
                data-w-id="b3b98ea5-44d7-c0d0-8dca-ed23d60189ab"
                to="/home"
                className="navbar-global-single-item w-inline-block"
              >
                <img
                  src={HomeIcon}
                  loading="lazy"
                  alt=""
                  className="navigation-icon"
                />
                <div className="navbar-global-item-popup">
                  <div>Home</div>
                  <img
                    src={Arrow3}
                    loading="lazy"
                    alt=""
                    className="navbar-global-item-triangle"
                  />
                </div>
              </Link>
              {state.project && (
                <a href="#" className="hide-mobile w-nav-brand">
                  <div className="navbar-page-name">{state.project.name}</div>
                </a>
              )}
            </div>
            <div className="navbar-global-items-wrapper">
              <Link
                to={`/project/${state.project.alias}`}
                className={
                  "navbar-global-single-item w-inline-block" +
                  (isCurrentUrl("") ? " w--current" : "")
                }
              >
                <img
                  src={DashboardIcon}
                  loading="lazy"
                  alt=""
                  className="navigation-icon"
                />
                <div className="navbar-global-item-popup">
                  <div>Dashboard</div>
                  <img
                    src={Arrow3}
                    loading="lazy"
                    alt=""
                    className="navbar-global-item-triangle"
                  />
                </div>
              </Link>
              <Link
                to={`/project/${state.project.alias}/sources`}
                aria-current="page"
                className={
                  "navbar-global-single-item w-inline-block" +
                  (isCurrentUrl("/sources") ? " w--current" : "")
                }
              >
                <img
                  src={LinkIcon}
                  loading="lazy"
                  alt=""
                  className="navigation-icon"
                />
                <div className="navbar-global-item-popup">
                  <div>Sources</div>
                  <img
                    src={Arrow3}
                    loading="lazy"
                    alt=""
                    className="navbar-global-item-triangle"
                  />
                </div>
              </Link>
              <Link
                to={`/project/${state.project.alias}/repository`}
                className={
                  "navbar-global-single-item w-inline-block" +
                  (isCurrentUrl("/repository") ? " w--current" : "")
                }
              >
                <img
                  src={DatabaseIcon}
                  loading="lazy"
                  alt=""
                  className="navigation-icon"
                />
                <div className="navbar-global-item-popup">
                  <div>Repository</div>
                  <img
                    src={Arrow3}
                    loading="lazy"
                    alt=""
                    className="navbar-global-item-triangle"
                  />
                </div>
              </Link>
              <Link
                to={`/project/${state.project.alias}/archive`}
                className={
                  "navbar-global-single-item w-inline-block" +
                  (isCurrentUrl("/archive") ? " w--current" : "")
                }
              >
                <img
                  src={ArchiveIcon}
                  loading="lazy"
                  alt=""
                  className="navigation-icon"
                />
                <div className="navbar-global-item-popup">
                  <div>Archive</div>
                  <img
                    src={Arrow3}
                    loading="lazy"
                    alt=""
                    className="navbar-global-item-triangle"
                  />
                </div>
              </Link>
              <Link
                to={`/project/${state.project.alias}/settings`}
                className={
                  "navbar-global-single-item w-inline-block" +
                  (isCurrentUrl("/settings") ? " w--current" : "")
                }
              >
                <img
                  src={SliderIcon}
                  loading="lazy"
                  alt=""
                  className="navigation-icon"
                />
                <div className="navbar-global-item-popup">
                  <div>Settings</div>
                  <img
                    src={Arrow3}
                    loading="lazy"
                    alt=""
                    className="navbar-global-item-triangle"
                  />
                </div>
              </Link>
            </div>
            <nav role="navigation" className="navbar-menu w-nav-menu">
              {state.project && state.project.is_published && (
                <a
                  href={launchUrl}
                  target="_blank"
                  className="start-new-project-button w-inline-block"
                  rel="noreferrer"
                >
                  <div className="hide-mobile">Launch Build</div>
                  <img
                    src={Profile}
                    loading="lazy"
                    alt=""
                    className="navigation-icon"
                  />
                </a>
              )}
              <AccountSettings />
            </nav>
            <div className="w-nav-button">
              <div className="w-icon-nav-menu"></div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="page-padding">
          <div className="container">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Project
