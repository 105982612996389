import { Project, Team, TeamMember } from "@/models/Project"
import { User } from "@/models/User"
import { Notification } from "@/models/Notification"

export interface State {
  project?: Project | null
  user?: User | null
  publishPercent: number
  team?: TeamMember | null
  notifications?: Array<Notification>
}

export const Actions = {
  UPDATE_PROJECT: "update_project",
  UPDATE_USER: "update_user",
  UPDATE_PUBLISH_PERCENT: "update_publish_percent",
  UPDATE_TEAM: "update_team",
  UPDATE_NOTIFICATIONS: "update_notifications",
}

export const reducer = (state: State, action: any): State => {
  switch (action.type) {
    case Actions.UPDATE_PROJECT:
      return {
        ...state,
        project: {...action.payload},
      }
    case Actions.UPDATE_USER:
      return {
        ...state,
        user: {...action.payload},
      }
    case Actions.UPDATE_PUBLISH_PERCENT:
      return {
        ...state,
        publishPercent: action.payload,
      }
    case Actions.UPDATE_TEAM:
      return {
        ...state,
        team: action.payload,
      }
    case Actions.UPDATE_TEAM:
      return {
        ...state,
        team: action.payload,
      }
    case Actions.UPDATE_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      }
    default:
      return { ...state }
  }
}
