import { Project, ProjectAsset } from "@/models/Project"
import React, { useEffect, useState } from "react"
import ProjectAssetClient from "@/clients/ProjectAssetClient"

interface RepositoryCodeTabProps {
  supportingFiles: Array<ProjectAsset>
  asset: ProjectAsset
  project: Project
}

const RepositoryCodeTab: React.ComponentType<RepositoryCodeTabProps> = (
  props: RepositoryCodeTabProps
) => {
  const assetClient = new ProjectAssetClient()
  const { asset, project, supportingFiles } = props
  const [content, setContent] = useState<any>()

  useEffect(() => {
    assetClient.getAsssetContent(project.id!, asset.id!).then((cont: any) => {
      supportingFiles.forEach((file: ProjectAsset) => {
        let sourceRegex = new RegExp(file.url_source, "g")
        let pathRegex = new RegExp(file.path!, "g")
        cont.replace(
          sourceRegex,
          `<span class="isurl green">${file.url_source}</span>`
        )
        cont.replace(pathRegex, `<span class="isurl green">${file.path}</span>`)
      })
      setContent(cont)
    })
  }, [asset])

  const getDestination = () => {
    return `/${project.alias}${asset.path}`
  }

  return (
    <div {...props}>
      <div>
        <div className="project-overview-name-text">Inspect</div>
        <div>
          <p>
            You are inspecting the asset as it appears in the current build.
            URLs may differ from the original source, as they may have been
            replaced with links to other assets of this build that are stored
            locally.
          </p>
          <b>File Hosted Locally At:</b>
          <p>{getDestination()}</p>
        </div>
        <div className="project-overview-name-text" style={{ marginTop: 15 }}>
          Project URLs
        </div>
        <div className="content">
          <span className="isurl2 bgorange">
            {process.env.REACT_APP_SITE_URL + "/sites/" + project.alias}
          </span>
          <br />
          <span className="isurl2 blue">/files</span>
          <br />
          <span className="isurl2 blue">/images</span>
        </div>
        <div className="content">
          {supportingFiles.map((file: ProjectAsset) => {
            if (file.type!.includes("file")) {
              return (
                <p key={file.id}>
                  <span className="isurl2 green">{file.path}</span>
                  <br />
                  <span className="isurl2 red">{file.url_source}</span>
                </p>
              )
            }
          })}
        </div>
        <div className="small code-block code">
          <pre dangerouslySetInnerHTML={{ __html: content }}></pre>
        </div>
      </div>
    </div>
  )
}

export default RepositoryCodeTab
