import { Paginator } from "@/models/Paginator"
import { User, UserToken } from "@/models/User"
import BaseClient from "./BaseClient"

class UserClient extends BaseClient {
  /**
   * Login to get access token
   *
   * @param username
   * @param password
   * @returns User
   */
  login = (username: string, password: string): Promise<UserToken> => {
    return this.post("/login", { username, password })
  }

  /**
   * get the current user
   * @returns User
   */
  getCurrentUser = (): Promise<User> => {
    return this.get("/me")
  }

  /**
   * get all users
   * @param params
   * @returns Paginator<User>
   */
  getAllUsers = (params: {
    search?: string
    limit?: number
    page?: number
    role_id?: number
  }): Promise<Paginator<User>> => {
    return this.get("/user", { params })
  }

  /**
   * create new user
   * @param user
   * @returns User
   */
  createUser = (user: User): Promise<User> => {
    return this.post("/user", user)
  }

  /**
   * Update user
   * @param id
   * @param user
   * @returns User
   */
  updateUser = (id: number, user: User): Promise<User> => {
    return this.put(`/user/${id}`, user)
  }

  /**
   * delete user
   * @param id
   * @returns
   */
  deleteUser = (id: number): Promise<any> => {
    return this.delete(`/user/${id}`)
  }

  /**
   * Change user password
   * @param old_password
   * @param new_password
   * @returns
   */
  changePassword = (
    old_password: string,
    new_password: string
  ): Promise<User> => {
    return this.post(`/user/change/password`, { old_password, new_password })
  }

  /**
   * search user by email
   * @param email
   * @returns Promise
   */
  findByEmail = (email: string): Promise<User> => {
    return this.get(`/user/find/by/email`, { params: { email } })
  }
}

export default UserClient
