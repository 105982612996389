import { Project } from "@/models/Project"
import React, { useState } from "react"
import { Button, Modal } from "@/components/common"
import ProjectClient from "@/clients/ProjectClient"
import { message } from "antd"

interface DeleteProjectModalProps {
  show: boolean
  onClose: any
  project: Project
  onDelete: any
}

const DeleteProjectModal: React.ComponentType<DeleteProjectModalProps> = ({
  show,
  onClose,
  project,
  onDelete,
}) => {
  const projectClient = new ProjectClient()
  const [loading, setLoading] = useState(false)

  const deleteProject = () => {
    setLoading(true)
    projectClient.deleteProject(project.id!).then(
      () => {
        onDelete(project)
        setLoading(false)
      },
      (error) => {
        message.error(error)
        setLoading(false)
      }
    )
  }

  return (
    <Modal show={show} onClose={onClose}>
      <div className="margin-bottom-30">
        <div>Are you sure you want to delete this project?</div>
      </div>

      <Button
        className="delete-project-button w-button"
        label="Yes, delete"
        danger
        onClick={deleteProject}
        fontSize={24}
        loading={loading}
      />
      <a href="#" className="cancel-link" onClick={onClose}>
        No, cancel
      </a>
    </Modal>
  )
}

export default DeleteProjectModal
