import { Team, TeamMember } from "@/models/Project"
import BaseClient from "./BaseClient"

class TeamClient extends BaseClient {
  getTeams = () => {
    return this.get(`/team`)
  }

  getTeamMembers = (teamId: number, limit: number) => {
    return this.get(`/team/${teamId}/member`, { params: { limit } })
  }

  addTeamMember = (teamId: number, data: TeamMember) => {
    return this.post(`/team/${teamId}/member`, data)
  }

  updateTeamMember = (teamId: number, memberId: number, data: TeamMember) => {
    return this.put(`/team/${teamId}/member/${memberId}`, data)
  }

  removeMember = (teamId: number, memberId: number) => {
    return this.delete(`/team/${teamId}/member/${memberId}`)
  }
}

export default TeamClient
