import { Project, ProjectSource } from "@/models/Project"
import React, { useContext } from "react"
import moment from "moment"
import MoreHorizontal from "@/resources/images/more-horizontal-2-1.svg"
import Arrow3 from "@/resources/images/Arrow-3.svg"
import {
  AppStateContext,
  AppDispatchContext,
} from "@/components/contexts/AppContext"
import { getIsoName } from "@/utilities/LangISONameHelper"

interface LokaliseItemProps {
  langauge: any
  project: Project
  onClick?: any
  onDelete: any
}

const WeglotItem: React.ComponentType<LokaliseItemProps> = (props) => {
  const { langauge, project, onDelete, onClick } = props

  const getTranslatedUrl = (langIso: string) => {
    let url =
      process.env.REACT_APP_SITE_URL +
      "/sites/" +
      project.alias +
      "/" +
      langIso.toLowerCase()

    return url
  }

  return (
    <div onClick={onClick} className="repository-single-item-wrapper is-grey">
      <div className="repository-name-wrapper-w-padding">
        <div className="page-grid-item-text">{getIsoName(langauge.lang)}</div>
      </div>
      <div className="repository-name-wrapper">
        <a
          href={"https://" + langauge.domain}
          target="_blank"
          className="page-grid-item-link w-inline-block"
          rel="noreferrer"
        >
          <div>{langauge.domain}</div>
        </a>
      </div>
      <div className="repository-name-wrapper">
        <div className="page-grid-item-text titlecase">
          {project.data.translations[0]}
        </div>
      </div>
      <div
        data-hover="true"
        data-delay="0"
        className="extra-settings-dropdown w-dropdown"
      >
        <div className="extra-settings-dropdown-toggle w-dropdown-toggle">
          <div className="extra-settings-dropdown-image-wrapper">
            <img
              src={MoreHorizontal}
              loading="lazy"
              alt=""
              className="object-fit-cover"
            />
          </div>
        </div>
        <nav
          className={
            "extra-settings-dropdown-list w-dropdown-list" +
            (langauge.open_dropdown ? " w--open" : "")
          }
        >
          <div className="extra-settings-dropdown-list-inner">
            <a
              href={getTranslatedUrl(langauge.lang)}
              target="_blank"
              className="navbar-user-dropdown-link w-dropdown-link"
              rel="noreferrer"
            >
              View Staging Translation
            </a>
            <a
              href="#"
              onClick={() => onDelete(langauge)}
              className="navbar-user-dropdown-link w-dropdown-link"
            >
              Delete Language
            </a>
            <img
              src={Arrow3}
              loading="lazy"
              alt=""
              className="navbar-user-dropdown-triangle"
            />
          </div>
        </nav>
      </div>
    </div>
  )
}

export default WeglotItem
