import React, { useContext, useState } from "react"
import {
  AppStateContext,
  AppDispatchContext,
} from "@/components/contexts/AppContext"
import { Button } from "@/components/common"
import moment from "moment"
import ProjectClient from "@/clients/ProjectClient"
import { Project } from "@/models/Project"
import { Actions } from "../reducers/AppReducer"
import { message, Switch } from "antd"

const GeneralSettings: React.ComponentType = (props) => {
  const projectClient = new ProjectClient()
  const { state } = useContext(AppStateContext)
  const { dispatch } = useContext(AppDispatchContext)
  const [name, setName] = useState(state.project!.name)
  // const [isRails, setIsRails] = useState(state.project!.is_rails)
  // const [railsFolder, setRailsFolder] = useState(state.project!.rails_folder)
  const [domain, setDomain] = useState(state.project?.data.project_domain ? state.project?.data.project_domain : "")
  const [loading, setLoading] = useState(false)
  const baseUrl = process.env.REACT_APP_SITE_URL

  const updateProject = () => {
    setLoading(true)
    state.project!.name = name
    state.project!.data.project_domain = domain;
    // state.project!.is_rails = isRails
    // state.project!.rails_folder = railsFolder
    projectClient.updateProject(state.project?.id!, state.project!).then(
      (proj: Project) => {
        dispatch({ type: Actions.UPDATE_PROJECT, payload: state.project })
        setLoading(false)
      },
      (error: string) => {
        message.error(error)
      }
    )
  }

  return (
    <div {...props}>
      <div className="padding-vertical-big">
        <div className="settings-section">
          <div className="settings-left">
            <div className="settings-heading-wrapper">
              <h2 className="settings-heading normal">General</h2>
            </div>
          </div>
          <div className="settings-right">
            <div className="settings-single-item-wrapper two-column">
              <div
                className="setting-single-item-text-wrapper"
              >
                <div className="settings-single-item-heading">Project name</div>
              </div>
              <div
                className="settings-form-block w-form"
              >
                <input
                  type="text"
                  className="settings-input-field w-input"
                  name="Alias"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  data-name="Alias"
                  placeholder=""
                  id="Alias"
                  required
                />
              </div>
            </div>
            <div className="settings-single-item-wrapper two-column">
              <div
                className="setting-single-item-text-wrapper"
              >
                <div className="settings-grid-item-text">Project Domain</div>
              </div>
              <div
                className="settings-form-block w-form"
              >
                <input
                  type="text"
                  className="settings-input-field w-input"
                  name="Domain"
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                  data-name="Domain"
                  placeholder=""
                  id="Domain"
                  required
                />
              </div>
            </div>
            <div className="settings-single-item-wrapper two-column">
              <div
                className="setting-single-item-text-wrapper"
              >
                <div className="settings-grid-item-text"></div>
              </div>
              <div
                className="settings-form-block w-form"
              >
                <Button
                  label="Save Changes"
                  loading={loading}
                  className="button-black w-button"
                  onClick={updateProject}
                  style={{ minWidth: "inherit" }}
                />
              </div>
            </div>
            <div className="settings-single-item-wrapper two-column">
              <div
                className="setting-single-item-text-wrapper"
              >
                <div className="settings-grid-item-text">Alias</div>
              </div>
              <div
                className="setting-single-item-text-wrapper"
              >
                <div className="settings-grid-item-text">
                  {state.project!.alias}
                </div>
              </div>
            </div>
            <div className="settings-single-item-wrapper two-column">
              <div
                className="setting-single-item-text-wrapper"
              >
                <div className="settings-grid-item-text">Build folder</div>
              </div>
              <div
                className="setting-single-item-text-wrapper"
              >
                <div className="settings-grid-item-text">
                  ./sites/{state.project!.alias}
                </div>
              </div>
            </div>
            <div className="settings-single-item-wrapper two-column">
              <div
                className="setting-single-item-text-wrapper"
              >
                <div className="settings-grid-item-text">Thumbnail</div>
              </div>
              <div>
                <div className="project-overview-image-wrapper">
                  {state.project!.thumbnail && (
                    <img
                      src={baseUrl + state.project!.thumbnail}
                      width="600"
                      sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, (max-width: 991px) 44vw, 35vw"
                      alt=""
                      className="object-fit-cover"
                    />
                  )}
                  {!state.project!.thumbnail && (
                    <img
                      src={require("@/resources/images/project_default.jpg")}
                      width="600"
                      sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, (max-width: 991px) 44vw, 35vw"
                      alt=""
                      className="object-fit-cover"
                    />
                  )}
                </div>
                <div className="margin-top-16">
                  <div className="settings-grid-item-text">
                    Update the metatag image in your Webflow project to change
                    your project image.
                  </div>
                </div>
              </div>
            </div>
            
            
            {/* <div className="project-overview-single-item">
              <div className="text-medium">Date Created</div>
              <div className="text-light">{moment(state.project!.created_at).format("MMMM D, YYYY")}</div>
            </div>
            <div className="project-overview-single-item">
              <div className="text-medium">Last Update</div>
              <div className="text-light">{moment(state.project!.updated_at).fromNow()}</div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralSettings
