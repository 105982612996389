import React, { useEffect, useState } from "react"
import { Input, Skeleton } from "antd"
import ProjectClient from "@/clients/ProjectClient"
import { Project, ProjectLog } from "@/models/Project"
import { Paginator } from "@/models/Paginator"
import CloseIcon from "@/resources/images/62614c167edf83cf5be4e62a_close-icon.svg"
import moment from "moment"
import Echo from "laravel-echo"
import JWTHelper from "@/utilities/JWTHelper"
const Pusher = require("pusher-js")

interface ProjectLogsModalProps {
  show: boolean
  onClose: any
  project: Project
}

const ProjectLogsModal: React.ComponentType<ProjectLogsModalProps> = ({
  show,
  onClose,
  project,
}) => {
  const projectClient = new ProjectClient()
  const [logData, setLogData] = useState<Paginator<ProjectLog>>()
  const [logs, setLogs] = useState<Array<ProjectLog>>([])
  const [search, setSearch] = useState("")
  const [translate, setTranslate] = useState("50px")
  const [loading, setLoading] = useState(false)
  const [echo, setEcho] = useState<Echo | null>()

  useEffect(() => {
    setTranslate(show ? "50px" : "0px")
  }, [show])

  useEffect(() => {
    loadLogs()
    initializeEcho()
  }, [])

  useEffect(() => {
    // don't re-initiliaze echo so it will not send multiple notifications
    if (echo === undefined) return

    const logchannel = echo!
      .private(`project-log.${project!.id}`)
      .listen(".project.log", (e: any) => {
        // console.log("receive log: ", e)
        logs.unshift(e.log)
        setLogs([...logs])
      })

      return () => {
        logchannel.stopListening(".project.log")
      }
  }, [logs])

  const loadLogs = () => {
    setLoading(true)
    projectClient.getLogs(project.id!, search).then(
      (res: Paginator<ProjectLog>) => {
        setLogData(res)
        setLogs(res.data)
        setLoading(false)
      },
      (error: string) => {
        console.log(error)
        setLoading(false)
      }
    )
  }

  const initializeEcho = () => {
    // don't re-initiliaze echo so it will not send multiple notifications
    if (echo !== undefined) return

    let client = new Echo({
      broadcaster: "pusher",
      key: process.env.REACT_APP_PUSHER_APP_KEY,
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      forceTLS: false,
      authEndpoint: process.env.REACT_APP_API_BASE_URL + "/broadcasting/auth",
      auth: {
        headers: { Authorization: "Bearer " + JWTHelper.getAccessToken() },
      },
    })
    setEcho(client)
  }

  return (
    <div
      style={{ display: show ? "flex" : "none", animation: "showDiv 0.3s" }}
      className="popup-modal-wrapper"
    >
      <div className="close-all-popup-modals"></div>
      <div
        className={"project-logs-modal"}
        style={{
          transform: `translate3d(0px, ${translate}, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
          opacity: show ? 1 : 0,
          transition: "opacity 0.3s ease-in-out, transform 0.3s",
          transformStyle: "preserve-3d",
        }}
      >
        <div className="start-new-project-popup-content">
          <div className="margin-bottom-40">
            <h2 className="page-heading">Project Log</h2>
          </div>
          <div className="popup-modal-form-block w-form">
            <div className="search">
              <Input.Search
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onSearch={loadLogs}
                placeholder="Search Logs..."
              />
            </div>
            <div className="logs-container">
              <Skeleton active loading={loading}>
                {logs.map((log: ProjectLog) => {
                  return (
                    <div key={log.id} className={"log-item-wrapper " + log.type}>
                      <div className="content">
                        <p>{log.content}</p>
                      </div>
                      <div className="created-at">
                        {moment(log.created_at).fromNow()}
                      </div>
                    </div>
                  )
                })}
              </Skeleton>
            </div>
          </div>
        </div>
        <a
          href="#"
          className="close-popup-modal w-inline-block"
          onClick={onClose}
        >
          <img src={CloseIcon} loading="lazy" alt="" />
        </a>
      </div>
    </div>
  )
}

export default ProjectLogsModal
