import React, { useContext, useEffect, useState } from "react"
import { Button, Modal } from "@/components/common"
import ProjectClient from "@/clients/ProjectClient"
import { message } from "antd"
import { Project } from "@/models/Project"
import { AppStateContext } from "@/components/contexts/AppContext"

interface CreateProjecdtModalProps {
  show: boolean
  onClose: any
  onCreate: any
}

const CreateProjecdtModal: React.ComponentType<CreateProjecdtModalProps> = ({
  show,
  onClose,
  onCreate,
}) => {
  const projectClient = new ProjectClient()
  const { state } = useContext(AppStateContext)
  const [projectName, setProjectName] = useState("")
  const [alias, setAlias] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let al = projectName
      .toLowerCase()
      .replace(/[^a-zA-Z ]/g, "")
      .replace(new RegExp(" ", "g"), "-")
    setAlias(al)
  }, [projectName])

  const createProject = () => {
    if (projectName.length === 0) {
      message.error("Please enter project name.")
      return
    }

    if (alias.length === 0) {
      message.error("Please enter project alias.")
      return
    }

    setLoading(true)
    projectClient
      .createProject({
        name: projectName,
        alias: alias,
        team_id: state.team!.team_id!,
      })
      .then(
        (project: Project) => {
          onCreate(project)
          setLoading(false)
          setProjectName("")
          setAlias("")
        },
        (error) => {
          message.error(error)
        }
      )
      .finally(() => setLoading(false))
  }

  return (
    <Modal show={show} onClose={onClose}>
      <div className="margin-bottom-40">
        <h2 className="page-heading">Start a new project</h2>
      </div>
      <div className="popup-modal-form-block w-form">
        <form
          id="start-new-project"
          name="wf-form-Start-new-project"
          data-name="Start new project"
          method="get"
        >
          <div className="margin-bottom-30">
            <label className="popup-modal-field-label">Project Name</label>
            <input
              type="text"
              className="popup-modal-input-field w-input"
              name="Project-Name"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              data-name="Project Name"
              placeholder="Enter project name"
              id="Project-Name"
            />
            <label className="popup-modal-field-label">Alias</label>
            <input
              type="text"
              className="popup-modal-input-field w-input"
              name="Alias"
              value={alias}
              onChange={(e) => setAlias(e.target.value)}
              data-name="Alias"
              placeholder="Enter project alias"
              id="Alias"
              required
            />
          </div>
          <Button
            label="Create Project"
            fontSize={24}
            loading={loading}
            onClick={createProject}
          />
        </form>
      </div>
    </Modal>
  )
}

export default CreateProjecdtModal
