import React, { useContext, useEffect, useState } from "react"
import { Button, Modal } from "@/components/common"
import { Input, message } from "antd"
import { AppStateContext } from "@/components/contexts/AppContext"
import ProjectClient from "@/clients/ProjectClient"
import SubstitutionClient from "@/clients/SubstitutionClient"
import { Project } from "@/models/Project"

const { TextArea } = Input

interface CreateSubstitutionModalProps {
  show: boolean
  onClose: any
  onCreate: any
}

const CreateSubstitutionModal: React.ComponentType<
  CreateSubstitutionModalProps
> = ({ show, onClose, onCreate }) => {
  const projectClient = new ProjectClient()
  const subClient = new SubstitutionClient()
  const { state } = useContext(AppStateContext)
  const [projectName, setProjectName] = useState("")
  const [alias, setAlias] = useState("")
  const [loading, setLoading] = useState(false)
  const [subs, setSubs] = useState<Array<any>>([])
  const [strFind, setStrFind] = useState("")
  const [strReplace, setStrReplace] = useState("")

  const createSubstitution = () => {
    if (strFind.length === 0) {
      message.error("Please enter Text to Replace")
      return
    }

    if (strReplace.length === 0) {
      message.error("Please enter Replacement text")
      return
    }

    setLoading(true)
    let data = {
      str_find: strFind,
      str_replace: strReplace,
    }

    subClient.createSubstitution(state.project!.id!, data).then(
      (sub: any) => {
        subs.unshift(sub)
        setSubs([...subs])
        setStrFind("")
        setStrReplace("")
        setLoading(false)
        onCreate()
      },
      (error: string) => {
        message.error(error)
        setLoading(false)
      }
    )
  }

  return (
    <Modal show={show} onClose={onClose}>
      <div className="margin-bottom-40">
        <h2 className="page-heading">Start a new project</h2>
      </div>
      <div className="popup-modal-form-block w-form">
        <div>
          <div className="text-medium">
            <TextArea
              rows={3}
              value={strFind}
              onChange={(e) => setStrFind(e.target.value)}
              placeholder="Enter text to replace..."
              className="popup-modal-input-field w-input"
            />
          </div>
          <div className="text-medium">
            <TextArea
              rows={3}
              value={strReplace}
              onChange={(e) => setStrReplace(e.target.value)}
              placeholder="Enter replacement text..."
              className="popup-modal-input-field w-input"
            />
          </div>
          <Button
            label="Create Substitution"
            loading={loading}
            className="submit-button w-button"
            onClick={createSubstitution}
          />
        </div>
      </div>
    </Modal>
  )
}

export default CreateSubstitutionModal
