import React, { useContext, useEffect, useState } from "react"
import { Input, message, Spin } from "antd"
import { Button, ConfirmModal } from "@/components/common"
import SubstitutionClient from "@/clients/SubstitutionClient"
import { AppStateContext } from "@/components/contexts/AppContext"
import { Paginator } from "@/models/Paginator"
import CreateSubstitutionModal from "@/components/modals/CreateSubstitutionModal"
import CloseIcon from "@/resources/images/62614c167edf83cf5be4e62a_close-icon.svg"
import { LoadingOutlined } from "@ant-design/icons"
const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />

const { TextArea } = Input

const SubstitutionSettings: React.ComponentType = (props) => {
  const subClient = new SubstitutionClient()
  const { state } = useContext(AppStateContext)
  const [strFind, setStrFind] = useState("")
  const [strReplace, setStrReplace] = useState("")
  const [loading, setLoading] = useState(false)
  const [subs, setSubs] = useState<Array<any>>([])
  const [idToDelete, setIdToDelete] = useState<number>()
  const [indexToDelete, setIndexToDelete] = useState<number>()
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  useEffect(() => {
    loadSubstituions()
  }, [])

  const loadSubstituions = () => {
    subClient
      .getSubstitution(state.project!.id!, { limit: 50 })
      .then((substituions: Paginator<any>) => {
        setSubs(substituions.data)
      })
  }

  const showConfirmDelete = (id: number, index: number) => {
    setIdToDelete(id)
    setIndexToDelete(index)
    setShowDeleteModal(true)
  }

  const deleteSub = () => {
    if (!idToDelete && !indexToDelete) return

    setShowDeleteModal(false)
    subs[indexToDelete!].loading = true
    setSubs([...subs])
    subClient.deleteSubstitution(state.project!.id!, idToDelete!).then(
      () => {
        subs.splice(indexToDelete!, 1)
        setSubs([...subs])
      },
      (error: string) => {
        subs[indexToDelete!].loading = true
        setSubs([...subs])
        message.error(error)
      }
    )
  }

  const onCreateSubstitution = () => {
    setShowModal(false)
    loadSubstituions()
  }

  return (
    <div {...props}>
      <div className="padding-vertical-big">
        <div className="settings-section">
          <div className="settings-left">
            <div className="settings-heading-wrapper">
              <h2 className="settings-heading normal">Substitutions</h2>
            </div>
            <div className="margin-bottom-30">
              <div className="settings-grid-item-text-big">
                Substitutions allow you to find specific content within Project
                Assets and replace it with content of your choice when you
                publish the build.
              </div>
            </div>
          </div>

          { !subs.length && <div className="settings-right">
            <div className="settings-single-item-wrapper is-substitution">
              <div id="w-node-_26b7d67d-1731-d2b4-4284-30193254c0e4-907a86d7" className="setting-single-item-text-wrapper">
                <div className="settings-single-item-heading"></div>
              </div>
              <div id="w-node-_26b7d67d-1731-d2b4-4284-30193254c0f0-907a86d7" className="substitution-button-wrapper">
                <a href="#" className="button-black w-button" onClick={() => setShowModal(true)}>Add a substitution</a>
              </div>
            </div>
          </div> }
          { !!subs.length && <div className="settings-right">
            <div className="settings-single-item-wrapper is-substitution">
              <div
                id="w-node-_26b7d67d-1731-d2b4-4284-30193254c0e4-907a86d7"
                className="setting-single-item-text-wrapper"
              >
                <div className="settings-single-item-heading">
                  Substitutions made
                </div>
              </div>
              <div
                id="w-node-_26b7d67d-1731-d2b4-4284-30193254c0f0-907a86d7"
                className="substitution-button-wrapper"
              >
                <a
                  href="#"
                  className="button-black w-button"
                  onClick={() => setShowModal(true)}
                >
                  Add a substitution
                </a>
              </div>
            </div>
            <div className="repository-grid-wrapper margin-bottom-30">
              <div className="substitutions-grid-navigation-wrapper">
                <div
                  id="w-node-_9c05f61c-27e8-cf83-fbd6-8def027e9596-907a86d7"
                  className="repository-name-wrapper-w-padding"
                >
                  <div className="page-grid-text">Find</div>
                </div>
                <div className="page-grid-text">Replace</div>
              </div>
              {subs.map((sub: any, index: number) => {
                return (
                  <div
                    className={`substitutions-single-item-wrapper ${
                      index % 2 ? "red" : "is-grey"
                    }`}
                    key={sub.id}
                  >
                    <div
                      id="w-node-_9c05f61c-27e8-cf83-fbd6-8def027e959e-907a86d7"
                      className="repository-name-wrapper"
                    >
                      <div className="page-grid-item-text">{sub.str_find}</div>
                    </div>
                    <div
                      id="w-node-_9c05f61c-27e8-cf83-fbd6-8def027e95a1-907a86d7"
                      className="repository-name-wrapper"
                    >
                      <div className="page-grid-item-text">
                        {sub.str_replace}
                      </div>
                    </div>
                    <div
                      id="w-node-_9c05f61c-27e8-cf83-fbd6-8def027e95a5-907a86d7"
                      className="substitution-button-wrapper-copy"
                    >
                      <a href="#" className="w-inline-block">
                        {!sub.loading && (
                          <img
                            src="https://assets.website-files.com/625e72e3b276d01282f085dd/62b45ea0315e3471fc12cb99_trash-2%201.svg"
                            loading="lazy"
                            alt=""
                            onClick={() => showConfirmDelete(sub.id, index)}
                          />
                        )}
                        {sub.loading && <Spin indicator={antIcon} />}
                        <img src="" loading="lazy" alt="" />
                      </a>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>}
        </div>
      </div>
      <CreateSubstitutionModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onCreate={onCreateSubstitution}
      />
      <ConfirmModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={deleteSub}
        message="Are you sure to delete this substitution?"
      />
    </div>
  )
}

export default SubstitutionSettings
