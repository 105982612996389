import React, { useContext, useEffect, useState } from "react"
import NotificationClient from "@/clients/NotificationClient"
import { Project } from "@/models/Project"
import { Notification } from "@/models/Notification"
import { message, Spin, Skeleton } from "antd"
import {
  AppStateContext,
  AppDispatchContext,
} from "@/components/contexts/AppContext"
import moment from "moment"
import { Actions } from "../reducers/AppReducer"

interface NotificationProps {
  project: Project
}

const NotificationList: React.ComponentType<NotificationProps> = (props) => {
  const notificationClient = new NotificationClient()
  const [notifications, setNofications] = useState<Array<Notification>>([])
  const [loading, setLoading] = useState(false)
  const { project } = props
  const { state } = useContext(AppStateContext)
  const { dispatch } = useContext(AppDispatchContext)

  useEffect(() => {
    loadNotifications()
  }, [])

  const loadNotifications = () => {
    setLoading(true)
    notificationClient.getNotifications(project.id!, 1).then(
      (res: any) => {
        // setNofications(res.data);
        dispatch({ type: Actions.UPDATE_NOTIFICATIONS, payload: res.data })
        // setNofications(res.data);
        setLoading(false);
      },
      (error: string) => {
        message.error(error)
      }
    )
  }

  if (loading) <Spin />

  return (
    <div>
      {state.notifications!.length === 0 && loading &&
        [0, 1, 2, 3, 4].map((i) => { 
          return (
            <div className="dashboard-single-item-wrapper" key={i}>
              <div className="dashboard-single-item-heading">
                <Skeleton.Input size="small" />
              </div>
              <div className="dashboard-single-item-text">
                <Skeleton.Input size="small" />
              </div>
              <div className="dashboard-single-item-text">
                <Skeleton.Input size="small" />
              </div>
            </div>
          )
        })
      }

      {!loading && state.notifications!.length <= 0 &&
          <div className="dashboard-notifications-empty">
            No notifications yet
          </div>
      }
      
      {!loading && state.notifications!.map((notification: Notification, index: number) => {
        return (
          <div className="dashboard-single-item-wrapper" key={notification.id}>
            <div className="dashboard-single-item-heading">
              {notification.message}
            </div>
            <div className="dashboard-single-item-text">
              {notification.user?.name}
            </div>
            <div className="dashboard-single-item-text">
              {moment(notification.created_at).format("MM/D/YYYY - LT")}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default NotificationList
