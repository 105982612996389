export const truncateUrl = (url: string, length?: number) => {
  if (!length) length = 50

  if (url.length > 32) {
    let stringLength = (length - 4) / 2 //leght of each side of the string
    let newUrl =
      url.substring(0, stringLength) +
      "..." +
      url.substring(url.length - stringLength, url.length)
    return newUrl
  }

  return url
}

export const getStagingUrl = (path: string, alias: string) => {
  let url = `${process.env.REACT_APP_SITE_URL}/sites/${alias}${
    path.split("index.html")[0]
  }`
  return url
}

export const getTruncatedStagingUrl = (path: string, alias: string) => {
  let url = `${process.env.REACT_APP_SITE_URL}/sites/${alias}${
    path.split("index.html")[0]
  }`
  url = truncateUrl(url, 50)
  return url
}
