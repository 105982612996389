import React, { useState } from "react"
import CloseIcon from "@/resources/images/62614c167edf83cf5be4e62a_close-icon.svg"
import CreateTeam from "@/components/teamsettings/CreateTeam"
import TeamMembers from "@/components/teamsettings/TeamMembers"
import CreateUser from "@/components/usersettings/CreateUser"

interface TeamSettingsModalProps {
  show: boolean
  onClose: () => void
}

const TeamSettignsModal: React.ComponentType<TeamSettingsModalProps> = ({
  show,
  onClose,
}) => {
  const [activeTab, setActiveTab] = useState("create")

  const openTab = (tab: string) => {
    setActiveTab(tab)
  }

  return (
    <div
      style={{ display: show ? "flex" : "none" }}
      className="popup-modal-wrapper"
    >
      <div className="close-all-popup-modals"></div>
      <div className={"user-popup-modal" + (show ? " show" : " hidden")}>
        <div className="user-popup-modal-top-content">
          <h2 className="page-heading">Team Settings</h2>
        </div>
        <div className="user-popup-modal-bottom-content">
          <div
            data-current="Tab 1"
            data-easing="ease"
            data-duration-in="300"
            data-duration-out="100"
            className="user-popup-tabs w-tabs"
          >
            <div className="user-popup-tabs-menu w-tab-menu">
              <a
                data-w-tab="Tab 1"
                onClick={() => openTab("create")}
                className={
                  "user-tab-link w-inline-block w-tab-link" +
                  (activeTab === "create" ? " w--current" : "")
                }
              >
                <div className="user-popup-tab-button">
                  <div className="w-embed">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_508_21982)">
                        <path
                          d="M10.6665 14V12.6667C10.6665 11.9594 10.3856 11.2811 9.88546 10.781C9.38536 10.281 8.70708 10 7.99984 10H3.33317C2.62593 10 1.94765 10.281 1.44755 10.781C0.947456 11.2811 0.666504 11.9594 0.666504 12.6667V14"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5.66667 7.33333C7.13943 7.33333 8.33333 6.13943 8.33333 4.66667C8.33333 3.19391 7.13943 2 5.66667 2C4.19391 2 3 3.19391 3 4.66667C3 6.13943 4.19391 7.33333 5.66667 7.33333Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13.3335 5.33301V9.33301"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.3335 7.33301H11.3335"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_508_21982">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div>Create New User</div>
                </div>
              </a>
              <a
                data-w-tab="Tab 2"
                onClick={() => openTab("info")}
                className={
                  "user-tab-link w-inline-block w-tab-link" +
                  (activeTab === "info" ? " w--current" : "")
                }
              >
                <div className="user-popup-tab-button">
                  <div className="w-embed">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.3332 14V12.6667C13.3332 11.9594 13.0522 11.2811 12.5521 10.781C12.052 10.281 11.3737 10 10.6665 10H5.33317C4.62593 10 3.94765 10.281 3.44755 10.781C2.94746 11.2811 2.6665 11.9594 2.6665 12.6667V14"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.00016 7.33333C9.47292 7.33333 10.6668 6.13943 10.6668 4.66667C10.6668 3.19391 9.47292 2 8.00016 2C6.5274 2 5.3335 3.19391 5.3335 4.66667C5.3335 6.13943 6.5274 7.33333 8.00016 7.33333Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div>Team Members</div>
                </div>
              </a>
            </div>
            <div className="w-tab-content">
              <div
                data-w-tab="Tab 1"
                className={
                  "w-tab-pane" +
                  (activeTab === "create" ? " w--tab-active" : "")
                }
              >
                <CreateUser />
              </div>
              <div
                data-w-tab="Tab 2"
                className={
                  "w-tab-pane" + (activeTab === "info" ? " w--tab-active" : "")
                }
              >
                <TeamMembers />
              </div>
            </div>
          </div>
        </div>
        <a
          href="#"
          className="close-popup-modal w-inline-block"
          onClick={onClose}
        >
          <img src={CloseIcon} loading="lazy" alt="" />
        </a>
      </div>
    </div>
  )
}

export default TeamSettignsModal
