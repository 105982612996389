import React, { useState } from "react"
import { LoadingOutlined } from "@ant-design/icons"
import Plus from "@/resources/images/plus-1-1_1.svg"
import { Spin, Checkbox, message, Skeleton } from "antd"
import moment from "moment"
import ProjectAssetClient from "@/clients/ProjectAssetClient"
import { Project, ProjectAsset, ProjectAssetVersion } from "@/models/Project"

const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />

interface RepositoryVersionTabProps {
  asset: ProjectAsset
  assetVersions: Array<any>
  setVersions: any
  project: Project
  loadVersions: () => void
  loadingVersions: boolean
}

const RepositoryVersionTab: React.ComponentType<RepositoryVersionTabProps> = (
  props: RepositoryVersionTabProps
) => {
  const assetClient = new ProjectAssetClient()
  const {
    assetVersions,
    project,
    asset,
    loadVersions,
    loadingVersions,
    setVersions,
  } = props
  const [loading, setLoading] = useState(false)
  const assetUrl = process.env.REACT_APP_SITE_URL

  const createVersion = () => {
    setLoading(true)
    assetClient.updateAsset(project.id!, asset.id!, asset).then(
      (res: ProjectAsset) => {
        // loadVersions()
        assetVersions.map((v: ProjectAssetVersion) => {
          v.is_active = false
        })
        res.created_at = res.updated_at;
        res.is_active = true;
        assetVersions.unshift(res)
        setVersions([...assetVersions])
        setLoading(false)
      },
      (error: string) => {
        message.error(error)
        setLoading(false)
      }
    )
  }

  const setActiveVersion = (event: any, versionId: number, index: number) => {
    if (!event.target.classList.contains("view-version")) {
      assetVersions.map((ver: any) => {
        if (ver.id === versionId) ver.loading = true
      })
      setVersions([...assetVersions])

      assetClient
        .setActiveVersion(versionId)
        .then(
          () => {
            loadVersions()
          },
          (error: string) => {
            message.error(error)
          }
        )
        .finally(() => {
          assetVersions.map((ver: any) => {
            ver.loading = false
          })
          setVersions([...assetVersions])
        })
    }
  }

  return (
    <div>
      <div
        className="project-overview-card-right"
        style={{ position: "relative" }}
      >
        <div className="project-overview-name-text">Version History</div>
        <a
          href="#"
          className="add-source-link w-inline-block add-asset-version"
          onClick={() => createVersion()}
        >
          {!loading && (
            <img src={Plus} loading="lazy" alt="" className="image" />
          )}
          {loading && (
            <Spin
              spinning={true}
              indicator={antIcon}
              style={{ marginRight: 10 }}
            />
          )}
          <div>Update</div>
        </a>
      </div>
      <div className="repository-grid-wrapper version-grid-wrapper">
        <div className="repository-grid-navigation-wrapper">
          <div className="repository-name-wrapper-w-padding">
            <div className="page-grid-text">Created</div>
          </div>
          <div className="repository-name-wrapper-w-padding">
            <div className="page-grid-text">File Size</div>
          </div>
          <div className="repository-name-wrapper-w-padding">
            <div className="page-grid-text">View</div>
          </div>
        </div>
        <Skeleton loading={loadingVersions}>
          {assetVersions.map((version: any, index: number) => {
            return (
              <div
                key={version.id}
                className={
                  "repository-single-item-wrapper" +
                  (index % 2 === 0 ? " is-grey" : "")
                }
                onClick={(e: any) => setActiveVersion(e, version.id, index)}
              >
                <div className="repository-name-wrapper-w-padding">
                  <div className="page-grid-item-text">
                    {!version.loading && !version.is_active && (
                      <label className="w-checkbox">
                        <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox"></div>
                        <input
                          type="checkbox"
                          id="checkbox"
                          name="checkbox"
                          data-name="Checkbox"
                          style={{
                            opacity: 0,
                            position: "absolute",
                            zIndex: -1,
                          }}
                        />
                        <span className="hidden w-form-label">Checkbox</span>
                      </label>
                    )}
                    {!version.loading && !!version.is_active && (
                      <label className="w-checkbox">
                        <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox w--redirected-checked"></div>
                        <input
                          type="checkbox"
                          id="checkbox"
                          name="checkbox"
                          data-name="Checkbox"
                          style={{
                            opacity: 0,
                            position: "absolute",
                            zIndex: -1,
                          }}
                        />
                        <span className="hidden w-form-label">Checkbox</span>
                      </label>
                    )}

                    {version.loading && <Spin indicator={antIcon} />}
                    <span className="version-created">
                      {moment(version.created_at!).fromNow()}
                    </span>
                  </div>
                </div>
                <div className="repository-name-wrapper-w-padding">
                  <div className="page-grid-item-text">
                    {(version.size / 1024).toFixed(2)}KB
                  </div>
                </div>
                <div className="repository-name-wrapper-w-padding">
                  <div className="page-grid-item-text">
                    <a
                      className="view-version"
                      href={`${assetUrl}${version.content_url}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View
                    </a>
                  </div>
                </div>
              </div>
            )
          })}
        </Skeleton>
      </div>
    </div>
  )
}

export default RepositoryVersionTab
