import React from "react"
import moment from "moment"
import { Project } from "@/models/Project"
import arrowUpRight from "@/resources/images/arrow-up-right-1-1.svg"
import closeIcon from "@/resources/images/close-icon.svg"
import { Link } from "react-router-dom"
import { truncateUrl } from "@/utilities/String"

interface ProjectCardProps {
  project: Project
  setDeleteProject: any
}

const ProjectCard: React.ComponentType<ProjectCardProps> = (props) => {
  const { project, setDeleteProject } = props
  const baseUrl = process.env.REACT_APP_SITE_URL

  return (
    <div className="project-overview-card-wrapper" key={project.alias}>
      <div className="project-overview-card-left">
        <div className="project-overview-image-wrapper">
          {project.thumbnail && (
            <img
              src={baseUrl + project.thumbnail}
              loading="lazy"
              sizes="(max-width: 479px) 85vw, (max-width: 767px) 91vw, (max-width: 991px) 76vw, 100vw"
              width="600"
              alt=""
              className="object-fit-cover"
            />
          )}
          {!project.thumbnail && (
            <img
              src={require("@/resources/images/project_default.jpg")}
              loading="lazy"
              sizes="(max-width: 479px) 85vw, (max-width: 767px) 91vw, (max-width: 991px) 76vw, 100vw"
              width="600"
              alt=""
              className="object-fit-cover"
            />
          )}
        </div>
      </div>
      <div className="project-overview-card-right">
        <div className="project-overview-name-text">{project.name}</div>
        <div className="project-overview-single-item">
          <div className="text-medium">Alias</div>
          <div className="text-light">{project.alias}</div>
        </div>
        <div className="project-overview-single-item is-two-rows-mobile">
          <div className="text-medium">Sources</div>
          {project.sources && project.sources!.length > 0 && (
            <a
              href={project.sources![0].url_homepage}
              target="_blank"
              className="project-overview-link-wrapper w-inline-block"
              rel="noreferrer"
            >
              <div className="project-overview-link">
                {truncateUrl(project.sources![0].url_homepage, 36)}
              </div>
            </a>
          )}
          {project.sources?.length === 0 && <span>--</span>}
        </div>
        <div className="project-overview-single-item">
          <div className="text-medium">Created</div>
          <div className="text-light">
            {moment(project.created_at).fromNow()}
          </div>
        </div>
        <Link to={`/project/${project.alias}`} className="main-button w-button">
          Manage Project
        </Link>
      </div>
      <a
        href="#"
        className="link-block w-inline-block"
        onClick={() => setDeleteProject(project)}
      >
        <img src={closeIcon} loading="lazy" alt="" />
      </a>
    </div>
  )
}

export default ProjectCard
