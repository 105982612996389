import React, { useContext, useState } from "react"
import arrowDown from "@/resources/images/arrow-down.svg"
import Arrow3 from "@/resources/images/Arrow-3.svg"
import JWTHelper from "@/utilities/JWTHelper"
import { useNavigate } from "react-router-dom"
import AccountSettingsModal from "@/components/modals/AccountSettingsModal"
import TeamSettignsModal from "@/components/modals/TeamSettingsModal"
import { AppStateContext } from "@/components/contexts/AppContext"

const AccountSettings = () => {
  let navigate = useNavigate()
  const { state } = useContext(AppStateContext)
  const [navOpen, setNavOpen] = useState(false)
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const [showTeamSettingsModal, setShowTeamSettingsModal] = useState(false)

  const logout = () => {
    JWTHelper.deleteStoredToken()
    navigate("/login", { replace: true })
  }

  const openNav = () => {
    console.log(navOpen)
    setNavOpen(!navOpen)
  }

  const openSettingsModal = () => {
    setNavOpen(!navOpen)
    setShowSettingsModal(true)
  }
  const openTeamModal = () => {
    setNavOpen(!navOpen)
    setShowTeamSettingsModal(true)
  }

  return (
    <div
      data-hover="false"
      data-delay="0"
      className="navbar-user-dropdown w-dropdown"
    >
      <div
        className="navbar-user-dropdown-toggle w-dropdown-toggle"
        onClick={openNav}
      >
        <div className="navbar-user-dropdown-image-wrapper">
          <img
            src={require("@/resources/images/unsplash_-v7EOw5SA4I.png")}
            loading="lazy"
            alt=""
            className="object-fit-cover"
            style={{ display: "none" }}
          />
          {state.user && <div>{state.user!.name.charAt(0)}</div>}
        </div>
        <img src={arrowDown} loading="lazy" alt="" />
      </div>
      <nav
        className={
          `navbar-user-dropdown-list w-dropdown-list` +
          (navOpen ? " w--open" : "")
        }
      >
        <div className="navbar-user-dropdown-list-inner">
          <a
            href="#"
            className="navbar-user-dropdown-link w-dropdown-link"
            onClick={() => openSettingsModal()}
          >
            Account Settings
          </a>
          {state.team?.is_admin && (
            <a
              href="#"
              className="navbar-user-dropdown-link w-dropdown-link"
              onClick={() => openTeamModal()}
            >
              Team Settings
            </a>
          )}
          <a
            href="#"
            className="navbar-user-dropdown-link is-logout w-dropdown-link"
            onClick={logout}
          >
            Logout
          </a>
          <img
            src={Arrow3}
            loading="lazy"
            alt=""
            className="navbar-user-dropdown-triangle"
          />
        </div>
      </nav>
      <AccountSettingsModal
        show={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
      />
      <TeamSettignsModal
        show={showTeamSettingsModal}
        onClose={() => setShowTeamSettingsModal(false)}
      />
    </div>
  )
}

export default AccountSettings
