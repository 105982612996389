import { Paginator } from "@/models/Paginator"
import { ProjectAsset, ProjectSource } from "@/models/Project"
import BaseClient from "./BaseClient"

class ProjectSourceClient extends BaseClient {
  /**
   * Add new source to the project
   * @param projectId
   * @param data
   * @returns Promise<ProjectSource>
   */
  addSource = (
    projectId: number,
    data: ProjectSource
  ): Promise<ProjectSource> => {
    return this.post(`/project/${projectId}/source`, data)
  }

  /**
   * Edit Project Source
   * @param projectId
   * @param sourceId
   * @param data
   * @returns Promise<ProjectSource>
   */
  editSource = (
    projectId: number,
    sourceId: number,
    data: ProjectSource
  ): Promise<ProjectSource> => {
    return this.put(`/project/${projectId}/source/${sourceId}`, data)
  }

  /**
   * Delete project source
   * @param projectId
   * @param sourceId
   * @returns Promise<any>
   */
  deleteSource = (projectId: number, sourceId: number): Promise<any> => {
    return this.delete(`/project/${projectId}/source/${sourceId}`)
  }

  /**
   * Get all project sources
   * @param projectId
   * @param params
   * @returns Promise<Paginator<ProjectSource>>
   */
  getSources = (
    projectId: number,
    params?: { limit?: number }
  ): Promise<Paginator<ProjectSource>> => {
    return this.get(`/project/${projectId}/source`)
  }

  /**
   * Get project source files
   * @param sourceId
   * @returns Promise<any>
   */
  getSourceFiles = (params: {
    url: string
    project_id: number
  }): Promise<any> => {
    return this.get(`/project/source/files`, { params })
  }

  /**
   * Get project source files
   * @param sourceId
   * @returns Promise<any>
   */
  getOtherSourceFiles = (params: {
    project_id: number
    source_id: number
  }): Promise<any> => {
    return this.get(`/project/source/other/files`, { params })
  }

  replaceFile = (data: {
    project_id: number
    source_id: number
    replacement_file: string
  }): Promise<ProjectAsset> => {
    return this.post(`/project/source/replace/files`, data)
  }

  /**
   * Get project sitemap locs
   * @param sourceId
   * @returns Promise<any>
   */
  getSiteMapLocs = (sourceId: number): Promise<any> => {
    return this.get(`/project/${sourceId}/locs`)
  }
}

export default ProjectSourceClient
