import React, { useEffect, useState } from "react"
import CloseIcon from "@/resources/images/62614c167edf83cf5be4e62a_close-icon.svg"
import Button from "@/components/common/Button"

export interface ModalProps {
  show: boolean
  onClose: any
  children: any
}

const Modal: React.ComponentType<ModalProps> = ({
  children,
  show,
  onClose,
}) => {
  const [translate, setTranslate] = useState("50px")

  useEffect(() => {
    setTranslate(show ? "50px" : "0px")
  }, [show])

  return (
    <div
      style={{ display: show ? "flex" : "none", animation: "showDiv 0.3s" }}
      className="popup-modal-wrapper"
    >
      <div className="close-all-popup-modals"></div>
      <div
        className={"new-project-popup-modal"}
        style={{
          transform: `translate3d(0px, ${translate}, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
          opacity: show ? 1 : 0,
          transition: "opacity 0.3s ease-in-out, transform 0.3s",
          transformStyle: "preserve-3d",
        }}
      >
        <div className="start-new-project-popup-content">{children}</div>
        <a
          href="#"
          className="close-popup-modal w-inline-block"
          onClick={onClose}
        >
          <img src={CloseIcon} loading="lazy" alt="" />
        </a>
      </div>
    </div>
  )
}

export interface ConfirmModalProps {
  show: boolean
  onClose: any
  message: string
  onConfirm: any
  danger?: boolean
}

export const ConfirmModal: React.ComponentType<ConfirmModalProps> = ({
  show,
  onClose,
  message,
  onConfirm,
  danger,
}) => {
  return (
    <Modal show={show} onClose={onClose}>
      <div className="margin-bottom-30">
        <div>{message}</div>
      </div>

      <Button
        className="delete-project-button w-button"
        label="Yes, delete"
        danger
        onClick={onConfirm}
      />
      <a href="#" className="cancel-link" onClick={onClose}>
        No, cancel
      </a>
    </Modal>
  )
}

export default Modal
