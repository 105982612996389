import { Project, ProjectSource } from "@/models/Project"
import React, { useState } from "react"
import { Button, Modal } from "@/components/common"
import { message } from "antd"
import ProjectSourceClient from "@/clients/ProjectSourceClient"

interface DeleteSourceModalProps {
  show: boolean
  onClose: any
  source: ProjectSource
  project: Project
  onDelete: any
}

const DeleteSourceModal: React.ComponentType<DeleteSourceModalProps> = ({
  show,
  onClose,
  source,
  project,
  onDelete,
}) => {
  const sourceClient = new ProjectSourceClient()
  const [loading, setLoading] = useState(false)

  const deleteSource = () => {
    setLoading(true)
    sourceClient.deleteSource(project.id!, source.id!).then(
      () => {
        onDelete(source)
        setLoading(false)
      },
      (error) => {
        message.error(error)
        setLoading(false)
      }
    )
  }

  if (!source) return null

  return (
    <Modal show={show} onClose={onClose}>
      <div className="margin-bottom-30">
        <div>Are you sure you want to delete this source?</div>
      </div>

      <Button
        className="delete-project-button w-button"
        label="Yes, delete"
        danger
        onClick={deleteSource}
        fontSize={24}
        loading={loading}
      />
      <a href="#" className="cancel-link" onClick={onClose}>
        No, cancel
      </a>
    </Modal>
  )
}

export default DeleteSourceModal
