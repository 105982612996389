import React, { useContext, useEffect, useState } from "react"
import { Actions } from "@/components/reducers/AppReducer"
import {
  AppStateContext,
  AppDispatchContext,
} from "@/components/contexts/AppContext"
import ProjectClient from "@/clients/ProjectClient"
import { Project, ProjectAwsSetting } from "@/models/Project"
import { Button } from "@/components/common"
import { message, Checkbox } from "antd"

const AWSSettings: React.ComponentType = () => {
  const projectClient = new ProjectClient()
  const { state } = useContext(AppStateContext)
  const { dispatch } = useContext(AppDispatchContext)
  const [key, setKey] = useState("")
  const [secret, setSecret] = useState("")
  const [region, setRegion] = useState("")
  const [bucket, setBucket] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    projectClient
      .getAwsSettings(state.project?.id!)
      .then((settings: ProjectAwsSetting) => {
        if (settings) {
          setKey(settings.key)
          setSecret(settings.secret)
          setRegion(settings.region)
          setBucket(settings.bucket)
        }
      })
  }, [])

  const setupProjectAWS = () => {
    setLoading(true)
    let data = { key, secret, region, bucket }
    projectClient.updateAwsSettings(state.project!.id!, data).then(
      (project: Project) => {
        message.success("AWS Settings successfully updated.")
        setLoading(false)

        state.project!.data.hosting = "aws"
        projectClient
          .updateProject(state.project!.id!, state.project!)
          .then((project: Project) => {
            dispatch({ type: Actions.UPDATE_PROJECT, payload: project })
            setLoading(false)
          })
      },
      (error: string) => {
        message.error(error)
        setLoading(false)
      }
    )
  }

  return (
    <div>
      <div className="translation-single-item-opened">
        <div className="translation-single-item-left">
          <div className="translation-single-item-name-wrapper-opened">
            <div className="icon-wrapper">
              <img
                loading="lazy"
                src="https://assets.website-files.com/625e72e3b276d01282f085dd/62cfd68826fd49bc0c2ba5e2_image%2067%20(1).svg"
                alt=""
                className="object-fit-cover"
              />
            </div>
            <div className="translation-single-item-text">AWS S3</div>
          </div>
        </div>
        <div className="translation-single-item-right">
          <div className="w-form">
            <div className="other-single-item-wrapper">
              <div
                id="w-node-cfc9a258-12ca-a1d2-d884-e1b1f9f3c7c2-907a86d7"
                className="repository-name-wrapper"
              >
                <div>Key</div>
              </div>
              <div
                id="w-node-cfc9a258-12ca-a1d2-d884-e1b1f9f3c7c5-907a86d7"
                className="repository-name-wrapper"
              >
                <input
                  type="text"
                  className="no-margin w-input"
                  name="Key"
                  value={key}
                  onChange={(e) => setKey(e.target.value)}
                  data-name="Key"
                  placeholder="Enter AWS Key"
                  id="Key"
                  required
                />
              </div>
            </div>
            <div className="other-single-item-wrapper">
              <div
                id="w-node-_17239fc9-e44b-99a7-ae7c-c115a455709a-907a86d7"
                className="repository-name-wrapper"
              >
                <div>Secret</div>
              </div>
              <div
                id="w-node-_17239fc9-e44b-99a7-ae7c-c115a455709d-907a86d7"
                className="repository-name-wrapper"
              >
                <input
                  type="password"
                  className="no-margin w-input"
                  name="Secret"
                  value={secret}
                  onChange={(e) => setSecret(e.target.value)}
                  data-name="Secret"
                  placeholder="Enter AWS Secret"
                  id="Secret"
                />
              </div>
            </div>
            <div className="other-single-item-wrapper">
              <div
                id="w-node-_605ab198-92f3-0d15-32db-d3d8d317ff55-907a86d7"
                className="repository-name-wrapper"
              >
                <div>Region</div>
              </div>
              <div
                id="w-node-_605ab198-92f3-0d15-32db-d3d8d317ff58-907a86d7"
                className="repository-name-wrapper"
              >
                <input
                  type="text"
                  className="no-margin w-input"
                  name="Region"
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  data-name="Region"
                  placeholder="Enter AWS Region"
                  id="Region"
                />
              </div>
            </div>
            <div className="other-single-item-wrapper">
              <div
                id="w-node-_703d799b-5926-448b-313a-141e813165f1-907a86d7"
                className="repository-name-wrapper"
              >
                <div>Bucket</div>
              </div>
              <div
                id="w-node-_703d799b-5926-448b-313a-141e813165f4-907a86d7"
                className="repository-name-wrapper"
              >
                <input
                  type="text"
                  className="no-margin w-input"
                  name="Bucket"
                  value={bucket}
                  onChange={(e) => setBucket(e.target.value)}
                  data-name="Bucket"
                  placeholder="Enter AWS Bucket"
                  id="Bucket"
                />
              </div>
            </div>
            <div className="other-single-item-wrapper">
              <div></div>
              <div className="flex">
                <Button
                  label="Update"
                  loading={loading}
                  className="button-black align-right w-button"
                  onClick={setupProjectAWS}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AWSSettings
