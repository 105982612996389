import { Project } from "@/models/Project"

import PlusIcon from "@/resources/images/62da822ecd4961729fc2832b_plus.svg"
import BluePlusIcon from "@/resources/images/62da8374650dccbc05acdd11_blue-plus.svg"
import AWSIcon from "@/resources/images/62cfd68826fd49bc0c2ba5e2_image 67 (1).svg"
import XIcon from "@/resources/images/62b46df6f856a67f3c4f71a0_image 67.svg"
import WGIcon from "@/resources/images/62b46ebed10f4041f983fe15_image 66.svg"
import { HashLink } from "react-router-hash-link"

interface AddExtensionsProps {
  project: Project
}

const AddExtensions: React.ComponentType<AddExtensionsProps> = ({
  project,
}) => {
  return (
    <>
      <div className="separator-line"></div>
      <div>
        <div className="dashboard-overview-text">Extensions</div>
      </div>
      <div className="extensions-card-wrapper">
        <HashLink
          to="settings#settings-extensions"
          className="dashboard-extension-card w-inline-block"
        >
          <div className="dashboard-card-icon-wrapper">
            <img src={PlusIcon} loading="lazy" alt="" />
          </div>
          <div className="dashboard-card-left-text-wrapper">
            <div className="text-light">Add Extensions</div>
          </div>
        </HashLink>
        {!project.data?.hosting && (
          <HashLink
            to="settings#settings-extensions"
            className="dashboard-extension-card-white w-inline-block"
          >
            <div className="dashboard-card-icon-wrapper no-background">
              <img src={AWSIcon} loading="lazy" alt="" />
            </div>
            <div className="dashboard-card-left-text-wrapper">
              <div className="text-light">Add s3</div>
            </div>
            <img
              src={BluePlusIcon}
              loading="lazy"
              alt=""
              className="extension-card-plus"
            />
          </HashLink>
        )}
        {!project.data?.translations && (
          <HashLink
            to="settings#settings-extensions"
            className="dashboard-extension-card-white w-inline-block"
          >
            <div className="dashboard-card-icon-wrapper no-background">
              <img src={WGIcon} loading="lazy" alt="" />
            </div>
            <div className="dashboard-card-left-text-wrapper">
              <div className="text-light">Add Weglot</div>
            </div>
            <img
              src={BluePlusIcon}
              loading="lazy"
              alt=""
              className="extension-card-plus"
            />
          </HashLink>
        )}
      </div>
    </>
  )
}

export default AddExtensions
