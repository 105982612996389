import { Project, ProjectAsset, ProjectAssetVersion } from "@/models/Project"
import React, { useEffect, useState } from "react"
import { Tabs, Badge, message } from "antd"
import {
  SettingOutlined,
  BranchesOutlined,
  CodeOutlined,
  DeleteOutlined,
} from "@ant-design/icons"
import ProjectAssetClient from "@/clients/ProjectAssetClient"
import RepositoryConfigureTab from "./RepositoryConfigureTab"
import RepositoryVersionTab from "./RepositoryVersionTab"
import RepositoryCodeTab from "./RepositoryCodeTab"
import RepositoryDelete from "./RepositoryDelete"
import { Paginator } from "@/models/Paginator"

const { TabPane } = Tabs

interface RepositoryTabsProps {
  assets: Array<ProjectAsset>
  asset: ProjectAsset
  project: Project
  show: boolean
  setReloadAssets: (reload: boolean) => void
  onClose: () => void
  onDeleteAsset: (asset: ProjectAsset) => void
}

const RepositoryTabs: React.ComponentType<RepositoryTabsProps> = (
  props: RepositoryTabsProps
) => {
  const assetClient = new ProjectAssetClient()
  const { show, onClose, asset, project, assets, onDeleteAsset, setReloadAssets } = props
  const [versions, setVersions] = useState<Array<ProjectAssetVersion>>([])
  const [versionData, setVersionData] = useState<Paginator<ProjectAssetVersion>>();
  const [loadingVersions, setLoadingVerions] = useState(false)

  useEffect(() => {
    if (!asset) return

    loadVersions()
  }, [asset])

  const loadVersions = () => {
    setLoadingVerions(true)
    assetClient.getAssetVersions(project.id!, asset.id!).then(
      (res: Paginator<ProjectAssetVersion>) => {
        setLoadingVerions(false)
        setVersionData(res)
        setVersions(res.data)
      },
      (error: string) => {
        message.error(error)
        setLoadingVerions(false)
      }
    )
  }

  return (
    <div
      className="page-side-popup"
      style={{
        transform:
          `translate3d(` +
          (show ? "0" : "100") +
          `%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
        transformStyle: "preserve-3d",
      }}
    >
      {asset && (
        <div className="popup-container">
          <div
            data-w-id="37143ed8-4bf4-428d-5c8b-abdae0031c5b"
            className="popup-close-wrapper"
            onClick={onClose}
          ></div>
          <div className="page-side-popup-inner-wrapper">
            <div className="page-side-popup-inner-padding">
              <div className="padding-vertical-small">
                {/* <div className="page-heading-wrapper">
                <h3 className="popup-header">Home Page</h3>
              </div> */}
                <Tabs defaultActiveKey="config">
                  <TabPane
                    tab={
                      <span>
                        <SettingOutlined />
                        Configure
                      </span>
                    }
                    key="config"
                  >
                    <RepositoryConfigureTab asset={asset} project={project} setReloadAssets={setReloadAssets} />
                  </TabPane>
                  {asset.type !== "image" && <TabPane
                    tab={
                      <span style={{position: "relative"}}>
                        <BranchesOutlined />
                        Versions
                        <span className="version-badge">{versionData?.total}</span>
                      </span>
                    }
                    key="version"
                  >
                    <RepositoryVersionTab
                      assetVersions={versions}
                      loadingVersions={loadingVersions}
                      setVersions={setVersions}
                      asset={asset}
                      project={project}
                      loadVersions={loadVersions}
                    />
                  </TabPane>}
                  {asset.type !== "image" && <TabPane
                    tab={
                      <span>
                        <CodeOutlined />
                        Code
                      </span>
                    }
                    key="code"
                  >
                    <RepositoryCodeTab
                      asset={asset}
                      project={project}
                      supportingFiles={assets}
                    />
                  </TabPane>}
                  <TabPane
                    tab={
                      <span>
                        <DeleteOutlined />
                        Remove
                      </span>
                    }
                    key="remove"
                  >
                    <RepositoryDelete
                      asset={asset}
                      project={project}
                      onDelete={onDeleteAsset}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default RepositoryTabs
