import { ProjectAsset, ProjectSource } from "@/models/Project"
import React, { useEffect, useState } from "react"
import ProjectSourceClient from "@/clients/ProjectSourceClient"
import ProjectAssetClient from "@/clients/ProjectAssetClient"
import { message, Spin, Checkbox, Skeleton } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

interface SourceList {
  show: boolean
  source: ProjectSource
  onClose: any
}

const SourceList: React.ComponentType<SourceList> = ({
  show,
  source,
  onClose,
}) => {
  const sourceClient = new ProjectSourceClient()
  const assetClient = new ProjectAssetClient()
  const [sourceFiles, setSourceFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const [assets, setAssets] = useState<Array<ProjectAsset>>([])

  useEffect(() => {
    if (!source) return

    setLoading(true)
    sourceClient.getSiteMapLocs(source.id!).then(
      (data: any) => {
        setSourceFiles(data)
        setLoading(false)
      },
      (error) => {
        message.error(error)
        setLoading(false)
      }
    )

    assetClient.getAssets(source?.project_id!).then(
      (res: Array<ProjectAsset>) => {
        setAssets(
          res.filter((a) => {
            return a.type === "html"
          })
        )
      },
      (error: string) => {
        message.error(error)
      }
    )
  }, [source])

  const getNotAddFilesCount = () => {
    let count = 0
    sourceFiles.map((f: any) => {
      if (!f.is_added) count += 1
    })

    return count
  }

  const addAllAssets = () => {
    let files: any[] = []
    sourceFiles.map((f: any) => {
      if (!f.is_added) files.push(f.url)
    })

    createAsset(files)
  }

  const addSingleAsset = (e: any, file: any) => {
    if (!e.target.classList.contains("source-link")) {
      if (!file.is_added) createAsset([file.url])
      else deleteAsset(file.url)
    }
  }

  const createAsset = (files: any) => {
    sourceFiles.map((f: any) => {
      let searchFile = files.find((file: any) => file === f.url)
      if (searchFile) {
        f.loading = true
        // set the homepage loading
        // if (searchFile === source.url_homepage) assetLoading = true;
      }
    })

    saveAssets(files, 0, 3)
    setSourceFiles([...sourceFiles])

    // this.project.is_dirty = true;
    // this.$store.commit("setCurrentProject", this.project);
  }

  const deleteAsset = (asset: any) => {
    console.log(asset);
    let assetToDelete = assets.find((a: ProjectAsset) => {
      return asset.replace(/\/$/, "") === a.url_source.replace(/\/$/, "")
    })
    if (assetToDelete) {
      sourceFiles.map((f: any) => {
        let searchFile = sourceFiles.find((file: any) => asset === f.url)
        if (searchFile) {
          f.loading = true
        }
      })
      setSourceFiles([...sourceFiles])
      assetClient
        .deleteAsset(source.project_id!, assetToDelete?.id!)
        .then((res: any) => {
          sourceFiles.map((f: any) => {
            let searchFile = sourceFiles.find((file: any) => asset === f.url)
            if (searchFile) {
              f.loading = false
              f.is_added = false
            }
          })
          setSourceFiles([...sourceFiles])
        })
    }
  }

  const saveAssets = (files: any, current: number, chunk: number) => {
    let count = files.length
    if (count < current) {
      return
    }

    let assets = files.slice(current, current + chunk)

    assetClient
      .createAsset(source.project_id!, {
        url_source: assets,
        source_id: source.id!,
      })
      .then((data: any) => {
        sourceFiles.map((file: any) => {
          let responseData = data.find((e: any) => {
            return e.url === file.url
          })

          if (responseData) {
            if (responseData.is_added) {
              if (file.url === responseData.url) {
                file.loading = false
                file.is_added = true

                if (responseData.url === source.url_homepage) {
                  source.is_added = true
                }
              }
            } else if (responseData.error) message.error(responseData.error)
          }
        })

        setSourceFiles([...sourceFiles])
        current = current + chunk
        saveAssets(files, current, chunk)
      })
      .finally(() => {
        sourceFiles.map((f: any) => {
          let searchFile = assets.find((file: any) => file === f.url)
          if (searchFile) {
            f.loading = false
            // set the homepage loading
            // if (searchFile === source.url_homepage)
            //   assetLoading = false;
          }
        })
        setSourceFiles([...sourceFiles])
      })
  }

  if (!source) return null

  return (
    <div
      className="page-side-popup"
      style={{
        transform:
          `translate3d(` +
          (show ? "0" : "100") +
          `%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
        transformStyle: "preserve-3d",
      }}
    >
      <div className="popup-container">
        <div
          data-w-id="37143ed8-4bf4-428d-5c8b-abdae0031c5b"
          className="popup-close-wrapper"
          onClick={onClose}
        ></div>
        <div className="page-side-popup-inner-wrapper">
          <div className="page-side-popup-inner-padding">
            <div className="padding-vertical-small">
              {/* <div className="page-heading-wrapper">
                <h3 className="popup-header">Home Page</h3>
              </div> */}
              <div className="source-heading-wrapper">
                <h3 className="popup-header">Sitemap</h3>
                {getNotAddFilesCount() > 0 && (
                  <button className="button-add" onClick={addAllAssets}>
                    <img
                      src="https://assets.website-files.com/625e72e3b276d01282f085dd/626915b858818b55c0dba259_plus%20(1)%202.svg"
                      loading="lazy"
                      alt=""
                    />
                    <div>Add {getNotAddFilesCount()} Pages</div>
                  </button>
                )}
              </div>
              <div className="source-grid-wrapper source-detail-grid-wrapper">
                <div className="source-grid-navigation-wrapper repository-name-wrapper-w-padding">
                  <div className="page-grid-text">Name</div>
                  {/* <div className="page-grid-text">Actions</div> */}
                </div>
                <Skeleton loading={loading}>
                  {sourceFiles.map((file: any, index: number) => {
                    return (
                      <div
                        className={
                          "source-single-item-wrapper" +
                          (index % 2 === 0 ? " is-grey" : "")
                        }
                        key={file.url}
                        onClick={(e) => addSingleAsset(e, file)}
                      >
                        <div className="repository-name-wrapper-w-padding">
                          {/* <div className="page-grid-item-text">{file.url}</div> */}
                          {!file.loading && !file.is_added && (
                            <label className="w-checkbox">
                              <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox"></div>
                              <input
                                type="checkbox"
                                id="checkbox-2"
                                name="checkbox-2"
                                data-name="Checkbox 2"
                                style={{
                                  opacity: 0,
                                  position: "absolute",
                                  zIndex: -1,
                                }}
                              />
                              <span className="hidden w-form-label">
                                Checkbox
                              </span>
                            </label>
                          )}
                          {!file.loading && file.is_added && (
                            <label className="w-checkbox">
                              <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox w--redirected-checked"></div>
                              <input
                                type="checkbox"
                                id="checkbox-2"
                                name="checkbox-2"
                                data-name="Checkbox 2"
                                style={{
                                  opacity: 0,
                                  position: "absolute",
                                  zIndex: -1,
                                }}
                              />
                              <span className="hidden w-form-label">
                                Checkbox
                              </span>
                            </label>
                          )}
                          {file.loading && <Spin indicator={antIcon} />}
                          <a
                            href={file.url}
                            target="_blank"
                            className="page-grid-item-link w-inline-block"
                            rel="noreferrer"
                          >
                            <div className="source-link">{file.url}</div>
                          </a>
                        </div>
                        <a
                          href="#"
                          className="repository-name-wrapper-w-padding w-inline-block"
                        >
                          {/* <div className="page-grid-item-text">Inspect</div><img src="https://assets.website-files.com/625e72e3b276d01282f085dd/626914d44588ec0d612f13ea_code%20(1)%201.svg" loading="lazy" alt="" className="code-icon" /> */}
                        </a>
                      </div>
                    )
                  })}
                </Skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SourceList
