import React, { useContext, useState } from "react"
import { Input, message } from "antd"
import { Button } from "@/components/common"
import UserClient from "@/clients/UserClient"
import TeamClient from "@/clients/TeamClient"
import { User } from "@/models/User"
import { AppStateContext } from "@/components/contexts/AppContext"
import { TeamMember } from "@/models/Project"

const { Search } = Input

interface AddMemberProps {
  onAddMember: (member: TeamMember) => void
}

const AddMember: React.ComponentType<AddMemberProps> = ({ onAddMember }) => {
  const userClient = new UserClient()
  const teamClient = new TeamClient()
  const { state } = useContext(AppStateContext)
  const [email, setEmail] = useState("")
  const [searchLoading, setSearchLoading] = useState(false)
  const [addLoading, setAddLoading] = useState(false)
  const [user, setUser] = useState<User | null>()

  const searchUser = () => {
    setSearchLoading(true)
    userClient.findByEmail(email).then(
      (searchUser: User) => {
        setSearchLoading(false)
        if (searchUser) setUser(searchUser)
      },
      (error: string) => {
        setSearchLoading(false)
        message.error(error)
      }
    )
  }

  const addTeamMember = (user: User) => {
    setAddLoading(true)
    teamClient
      .addTeamMember(state.team!.team_id!, {
        user_id: user.id!,
        is_accepted: true,
        is_admin: false,
      })
      .then(
        (member: TeamMember) => {
          member.loading = false
          onAddMember(member)
          setUser(null)
          setAddLoading(false)
        },
        (error: string) => {
          setAddLoading(false)
          message.error(error)
        }
      )
  }

  return (
    <div className="add-member">
      <div className="popup-modal-form-block w-form">
        <div>
          <div className="margin-bottom-30">
            <Search
              placeholder="Enter user email"
              onSearch={searchUser}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              enterButton
              loading={searchLoading}
            />
          </div>
        </div>
      </div>
      {user && (
        <div className="search-user find-user">
          <div className="navbar-user-dropdown-image-wrapper">
            <img
              src={require("@/resources/images/unsplash_-v7EOw5SA4I.png")}
              loading="lazy"
              alt=""
              className="object-fit-cover"
              style={{ display: "none" }}
            />
            <div>{user.name.charAt(0)}</div>
          </div>
          <div className="user-name">{user.name}</div>
          <div></div>
          <div className="user-button">
            <Button
              size="small"
              label="Add Member"
              onClick={() => addTeamMember(user)}
              loading={addLoading}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default AddMember
