import React, { useContext } from "react"
import { AppStateContext } from "../contexts/AppContext"

import ImportFiles from "@/components/dashboard/ImportFiles"
import PublishStaging from "@/components/dashboard/PublishStaging"
import PublishProduction from "@/components/dashboard/PublishProduction"
import NotificationList from "@/components/dashboard/NotificationList"

import LinkIcon from "@/resources/images/62da786ec4980c7dbd64f007_Icon - Link.svg"
import AddExtensions from "../dashboard/AddExtensions"

const Dashboard: React.ComponentType = () => {
  const { state } = useContext(AppStateContext)

  return (
    <>
      <div className="page-padding">
        <div className="container">
          <div className="padding-vertical">
            <div className="dashboard-wrapper">
              <div className="dashboard-left">
                {state.project &&
                  state.project.sources &&
                  state.project.sources.length > 0 && (
                    <a
                      href={state.project.sources[0].url_homepage}
                      target="_blank"
                      className="dashboard-action-cta w-inline-block"
                    >
                      <img src={LinkIcon} loading="lazy" alt="" />
                      <div className="text-light">
                        {state.project.sources[0].domain}
                      </div>
                    </a>
                  )}
                <div className="dashboard-card-wrapper">
                  <ImportFiles project={state.project!} />
                  {/* <PublishStaging /> */}
                </div>
                <div className="dashboard-card-wrapper">
                  {state.project &&
                    <PublishProduction project={state.project!} />}
                </div>
                <AddExtensions project={state.project!} />
              </div>
              <div className="dashboard-right">
                <div>
                  <div className="dashboard-overview-text">Notifications</div>
                </div>
                <div className="dashboard-grid-wrapper">
                  {state.project && (
                    <NotificationList project={state.project} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-padding">
        <div className="container">
          <div className="footer">
            <div className="footer-text">© 2022 Edgar Allan</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
