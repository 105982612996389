import { Project, ProjectAsset, ProjectSource } from "@/models/Project"
import React, { useEffect, useState } from "react"
import ProjectSourceClient from "@/clients/ProjectSourceClient"
import ProjectAssetClient from "@/clients/ProjectAssetClient"
import { message, Spin, Checkbox, Skeleton } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import moment from "moment"
import LokaliseClient from "@/clients/LokaliseClient"

interface SourceList {
  show: boolean
  language: any
  translation: string
  onClose: any
  project: Project
}

const PageList: React.ComponentType<SourceList> = ({
  show,
  language,
  translation,
  onClose,
  project,
}) => {
  const assetClient = new ProjectAssetClient()
  const lokaliseClient = new LokaliseClient()
  const [assets, setAssets] = useState<Array<ProjectAsset>>([])
  const [loading, setLoading] = useState(false)
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

  useEffect(() => {
    if (!language) return

    // console.log(language)
    setLoading(true)
    assetClient.getAssetsForLokalise(project.id!, language, translation).then(
      (assets: Array<ProjectAsset>) => {
        // let files = assets.filter((asset) => {
        //   return !isTranslatedFile(asset.path!);
        // });

        setAssets(assets)
        setLoading(false)
      },
      (error: string) => {
        message.error(error)
        setLoading(false)
      }
    )
  }, [language])

  const addSingleAsset = (e: any, asset: ProjectAsset) => {
    if (!e.target.classList.contains("source-link")) {
      if (!asset.is_in_lokalise) addFilesRecursively([asset], 0, 1)
    }
  }

  const isTranslatedFile = (path: string) => {
    let isTranslated = false
    let languages: any = project.data.weglot_data
      ? project.data.weglot_data
      : []
    languages.forEach((lang: any) => {
      if (path.indexOf(`/${lang.lang}/`) >= 0) isTranslated = true
    })

    return isTranslated
  }

  const sentAllToLokalise = () => {
    assets.map((asset: ProjectAsset) => {
      asset.loading = true
    })
    setAssets([...assets])
    addFilesRecursively(assets, 0, 1)
  }

  const getNotAddFilesCount = () => {
    let count = 0
    assets.map((f: any) => {
      if (!f.is_in_lokalise) count += 1
    })

    return count
  }

  const addFilesRecursively = (
    files: Array<any>,
    start: number,
    chunk: number
  ) => {
    if (files.length < start) return
    let filesToAdd = files.slice(start, start + chunk)
    if (filesToAdd.length <= 0) return
    let assetId = getAsasetIds(filesToAdd, true)

    lokaliseClient
      .addFileToLokalise(project.id!, assetId, language, translation)
      .then(
        (res: Array<any>) => {
          res.forEach((asset: any) => {
            assets.map((a: any) => {
              if (a.url === asset.url_source) {
                a.loading = false
                a.is_in_lokalise = true
                a.last_imported = asset.last_imported
              }
            })
          })

          setAssets([...assets])
          addFilesRecursively(files, start + chunk, chunk)
        },
        (error: string) => {
          message.error(error)
        }
      )
      .finally(() => {
        getAsasetIds(filesToAdd, false)
      })
  }

  const getAsasetIds = (
    filesToAdd: Array<any>,
    loading: boolean
  ): Array<number> => {
    let assetId: Array<number> = []
    filesToAdd.forEach((a: any) => {
      assets.map((f: any) => {
        if (f.url === a.url) f.loading = loading
      })
      assetId.push(a.url)
    })
    setAssets([...assets])

    return assetId
  }

  const getTranslatedUrl = (url: string): string => {
    // console.log(url)
    if(!url)
      return url;

    // if the url has a subdomain, return the url
    let count = (url.match(/\./g))?.length
    if(count! > 1)
      return url;

    // if the url is root domain, then add the language subdomain
    if (url.includes("wwww.")) 
      return url.replace("wwww.", `${language}.`)
    else if (url.includes("https://"))
      return url.replace("https://", `https://${language}.`)
    else 
      return url.replace("http://", `http://${language}.`)
  }

  if (!language) return null

  return (
    <div
      className="page-side-popup"
      style={{
        transform:
          `translate3d(` +
          (show ? "0" : "100") +
          `%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
        transformStyle: "preserve-3d",
      }}
    >
      <div className="popup-container">
        <div
          data-w-id="37143ed8-4bf4-428d-5c8b-abdae0031c5b"
          className="popup-close-wrapper"
          onClick={onClose}
        ></div>
        <div className="page-side-popup-inner-wrapper">
          <div className="page-side-popup-inner-padding">
            <div className="padding-vertical-small">
              {/* <div className="page-heading-wrapper">
                <h3 className="popup-header">Home Page</h3>
              </div> */}
              <div
                className="source-heading-wrapper"
                style={{ cursor: "pointer", marginRight: 10 }}
              >
                <h3 className="popup-header">Pages</h3>
                <div className="button-add" onClick={sentAllToLokalise}>
                  <div>Add {getNotAddFilesCount()} Pages</div>
                </div>
              </div>
              <div className="source-grid-wrapper source-detail-grid-wrapper">
                <div className="source-grid-navigation-wrapper page-grid">
                  <div
                    id="w-node-_7279e3ec-15d2-08ab-980c-f73bcae427c5-1d037768"
                    className="repository-name-wrapper-w-padding"
                  ></div>
                  <div className="page-grid-text">Path</div>
                  <div className="page-grid-text">Last Updated</div>
                </div>
                <Skeleton loading={loading}>
                  {assets.map((file: any, index: number) => {
                    return (
                      <div
                        className={
                          "source-single-item-wrapper page-grid" +
                          (index % 2 === 0 ? " is-grey" : "")
                        }
                        key={index}
                        onClick={(e) => addSingleAsset(e, file)}
                      >
                        <div className="repository-name-wrapper-w-padding">
                          {!file.loading && !file.is_in_lokalise && (
                            <label className="w-checkbox">
                              <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox"></div>
                              <input
                                type="checkbox"
                                id="checkbox-2"
                                name="checkbox-2"
                                data-name="Checkbox 2"
                                style={{
                                  opacity: 0,
                                  position: "absolute",
                                  zIndex: -1,
                                }}
                              />
                              <span className="hidden w-form-label">
                                Checkbox
                              </span>
                            </label>
                          )}
                          {!file.loading && file.is_in_lokalise && (
                            <label className="w-checkbox">
                              <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox w--redirected-checked"></div>
                              <input
                                type="checkbox"
                                id="checkbox-2"
                                name="checkbox-2"
                                data-name="Checkbox 2"
                                style={{
                                  opacity: 0,
                                  position: "absolute",
                                  zIndex: -1,
                                }}
                              />
                              <span className="hidden w-form-label">
                                Checkbox
                              </span>
                            </label>
                          )}
                          {file.loading && <Spin indicator={antIcon} />}
                        </div>
                        <div className="repository-name-wrapper">
                          {/* <div className="page-grid-item-text">{file.url}</div> */}
                          <a
                            href={getTranslatedUrl(file.url)}
                            target="_blank"
                            className="page-grid-item-link w-inline-block source-link"
                            rel="noreferrer"
                          >
                            <div className="source-link">{getTranslatedUrl(file.url)}</div>
                          </a>
                        </div>
                        <div className="repository-name-wrapper">
                          <div className="page-grid-item-text">
                            {file.last_imported
                              ? moment(file.last_imported).fromNow()
                              : ""}
                          </div>
                        </div>
                        <a
                          href="#"
                          className="repository-name-wrapper w-inline-block"
                        >
                          {/* <div className="page-grid-item-text">Inspect</div><img src="https://assets.website-files.com/625e72e3b276d01282f085dd/626914d44588ec0d612f13ea_code%20(1)%201.svg" loading="lazy" alt="" className="code-icon" /> */}
                        </a>
                      </div>
                    )
                  })}
                </Skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageList
