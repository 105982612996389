import React, { useEffect, useState } from "react"
// import "@/resources/css/landing/components.css"
// import "@/resources/css/landing/normalize.css"
// import "@/resources/css/landing/wes-landing-page.css"
// import "@/resources/css/landingpage.css"
import { message } from "antd"
import AccessRequestClient from "@/clients/AccessRequestClient"
import { Link } from 'react-router-dom'

const LandingPage: React.ComponentType = () => {
  const [openModal, setOpenModal] = useState(false)
  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [topic, setTopic] = useState("")
  const [loading, setLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  let requestClient = new AccessRequestClient()

  const showForm = () => {
    setOpenModal(true)
  }

  const closeForm = () => {
    setOpenModal(false)
  }

  const sendRequest = (e: any) => {
    e.preventDefault()

    if (email.length === 0) {
      message.error("Please enter your email.")
      return
    }

    if (firstName.length === 0) {
      message.error("Please enter your First Name.")
      return
    }

    if (lastName.length === 0) {
      message.error("Please enter your Last Name.")
      return
    }

    if (phoneNumber.length === 0) {
      message.error("Please enter your phone number.")
      return
    }

    let data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
      topic: topic,
    }

    setLoading(true)
    requestClient.sendEmail(data).then(
      () => {
        setLoading(false)
        message.success("Your request has been sent.")
        setFirstName("")
        setLastName("")
        setEmail("")
        setPhoneNumber("")
        setOpenModal(false)
        setIsSubmitted(true)
      },
      (error: string) => {
        setLoading(false)
        message.error(error)
      }
    )
  }

  return (
    <React.Fragment>
      <link rel="stylesheet" href="/css/landing/components.css"></link>
      <link rel="stylesheet" href="/css/landing/normalize.css"></link>
      <link rel="stylesheet" href="/css/landing/wes-landing-page.css"></link>
      <link rel="stylesheet" href="/css/landingpage.css"></link>
      <div
        data-animation="default"
        className="navbar4_component w-nav"
        data-easing2="ease"
        fs-scrolldisable-element="smart-nav"
        data-easing="ease"
        data-collapse="all"
        style={{
          opacity: 0,
          transform:
            "translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        }}
        data-w-id="e0e2adeb-8a20-0fb3-a322-77e438213f12"
        role="banner"
        data-duration="400"
        data-doc-height="1"
      >
        <div
          data-w-id="685e9566-0db0-54ed-1639-4656d8a303a0"
          className="nav-background"
        ></div>
        <div className="navbar4_container">
          <a
            href="/"
            aria-current="page"
            className="navbar4_logo-link w-nav-brand w--current"
          >
            <img
              src="/images/Wes.svg"
              loading="lazy"
              alt="Wes"
              className="navbar4_logo"
            />
          </a>
          <div className="navbar4_wrapper">
            <Link to="/login"
              className="button is-hero is-tertiary w-button"
            >
              Log In
            </Link>
            <a
              data-w-id="e0e2adeb-8a20-0fb3-a322-77e438213f19"
              href="#"
              className="button is-navbar4-button w-button"
              onClick={showForm}
            >
              Book a Demo
            </a>
          </div>
        </div>
      </div>
      <main className="main-wrapper" style={{opacity: 0}}>
        <div className="background-gradient">
          <img
            src="/images/Abstraction.png"
            loading="lazy"
            sizes="(max-width: 991px) 60vw, 652px"
            srcSet="/images/Abstraction-p-500.png 500w, /images/Abstraction.png 652w"
            alt=""
            className="hero-abstraction"
          />
          <header className="section_header28 pos-relative">
            <div className="padding-global">
              <div className="container-large">
                <div className="padding-top padding-xxhuge">
                  <div className="header28_component align-left align-center-tab">
                    <div className="margin-bottom margin-xxlarge">
                      <div className="text-align-left text-align-center-tab">
                        <div className="max-width-large extra-padding-left-desktop">
                          <div className="margin-bottom margin-small">
                            <h1>Webflow Enterprise Solutions </h1>
                          </div>
                          <div className="button-group justify-center-tab">
                            <a
                              data-w-id="f90ffa0e-037e-055f-efcc-9a22f0640d4d"
                              href="#"
                              className="button is-hero w-button"
                              onClick={showForm}
                            >
                              Book a Demo
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      src="/images/Video-Thumbnail.png"
                      loading="eager"
                      sizes="90vw"
                      srcSet="/images/Video-Thumbnail-p-500.png 500w, /images/Video-Thumbnail-p-800.png 800w, /images/Video-Thumbnail-p-1080.png 1080w, /images/Video-Thumbnail-p-1600.png 1600w, /images/Video-Thumbnail-p-2000.png 2000w, /images/Video-Thumbnail-p-2600.png 2600w, /images/Video-Thumbnail.png 2624w"
                      alt=""
                      className="header28_lightbox-image shadow-xxlarge border-radius-normal"
                    />
                    <a
                      href="#"
                      className="header28_lightbox display-none w-inline-block w-lightbox"
                    >
                      <img
                        src="/images/Video-Thumbnail.png"
                        loading="eager"
                        sizes="100vw"
                        srcSet="/images/Video-Thumbnail-p-500.png 500w, /images/Video-Thumbnail-p-800.png 800w, /images/Video-Thumbnail-p-1080.png 1080w, /images/Video-Thumbnail-p-1600.png 1600w, /images/Video-Thumbnail-p-2000.png 2000w, /images/Video-Thumbnail-p-2600.png 2600w, /images/Video-Thumbnail.png 2624w"
                        alt=""
                        className="header28_lightbox-image shadow-xxlarge"
                      />
                      <div className="lightbox-play-icon w-embed">
                        <svg
                          width="114"
                          height="114"
                          viewBox="0 0 114 114"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="56.7773"
                            cy="56.7773"
                            r="56.7773"
                            fill="url(#paint0_linear_73_2741)"
                            fillOpacity="0.83"
                          ></circle>
                          <path
                            d="M48.7388 44.831C48.7388 43.5124 50.1975 42.716 51.3066 43.4291L69.8908 55.376C70.9113 56.0321 70.9113 57.5238 69.8908 58.1799L51.3066 70.1268C50.1975 70.8399 48.7388 70.0435 48.7388 68.7249V44.831Z"
                            fill="white"
                          ></path>
                          <defs>
                            <linearGradient
                              id="paint0_linear_73_2741"
                              x1="-104.723"
                              y1="-121.723"
                              x2="136.305"
                              y2="-14.5081"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#6443D8"></stop>
                              <stop offset="1" stopColor="#E3B4BF"></stop>
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                      <div className="video-overlay-layer"></div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <section className="section_content7">
            <div className="padding-global">
              <div className="container-small padding-mob-small">
                <div className="padding-section-large">
                  <div className="content7_component">
                    <div className="text-size-large">
                      Meet Wes — your go-to solutions toolkit for creating
                      tailored server side configurations for Webflow in your
                      enterprise environment. Wes allows large organizations the
                      flexibility to customize, integrate, and deploy Webflow at
                      scale.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="background-gradient is-inverse">
          <section className="section_layout303">
            <div className="padding-global">
              <div className="container-large padding-mob-small">
                <div className="padding-section-large extra-padding-left-desktop extra-padding-right-desktop">
                  <div className="layout303_component align-center">
                    <div className="margin-bottom margin-xxlarge">
                      <h3>Solutions to Extend &amp; Scale</h3>
                    </div>
                    <div className="w-layout-grid layout303_list">
                      <div className="layout303_item">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/sliders.svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">
                            Custom Deployment Flow
                          </h3>
                        </div>
                        <p>
                          With Wes, you can create custom deployment, into your
                          environment. This gives you the power to tailor your
                          deployment process to your unique needs, ensuring a
                          perfect fit for your organization.
                        </p>
                      </div>
                      <div className="layout303_item">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/shield.svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">
                            GDPR Specific Server Deployment
                          </h3>
                        </div>
                        <p>
                          Wes supports GDPR-specific server deployment, helping
                          you meet stringent data protection requirements
                          without breaking a sweat.
                        </p>
                      </div>
                      <div className="layout303_item">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/loader.svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">Change Detection</h3>
                        </div>
                        <p>
                          Wes&#x27;s change detection feature keeps you informed
                          about every update, so you can deploy efficiently and
                          effectively.
                        </p>
                      </div>
                      <div className="layout303_item">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/rotate-ccw.svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">Version Control</h3>
                        </div>
                        <p>
                          Roll back a single page, section or the entire build.
                          Wes allows you to keep track of every change with
                          Wes&#x27;s robust version control.
                        </p>
                      </div>
                      <div className="layout303_item">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/edit-3.svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">
                            Per Page Publishing
                          </h3>
                        </div>
                        <p>
                          Update what you need, when you need it. With
                          Wes&#x27;s per page publishing, you can make changes
                          to individual pages without updating the full build,
                          saving you time and resources.
                        </p>
                      </div>
                      <div className="layout303_item">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/package.svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">Owned CDN</h3>
                        </div>
                        <p>
                          Deploy your website to a custom-configured CDN,
                          gaining complete control over content delivery. Enjoy
                          faster load times, improved user experience, and
                          optimized scalability with a tailored content delivery
                          solution.
                        </p>
                      </div>
                      <div className="layout303_item">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/lock.svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">Locked CSS</h3>
                        </div>
                        <p>
                          Keep your site&#x27;s look and feel secure with
                          Wes&#x27;s Locked CSS feature. Prevent unauthorized
                          changes and maintain consistency across your site.
                        </p>
                      </div>
                      <div className="layout303_item">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/git-merge.svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">
                            Server-Side Code Injections
                          </h3>
                        </div>
                        <p>
                          Merge Webflow code with other code systems
                          effortlessly. Wes&#x27;s server-side code injections
                          allow for seamless integration, giving you more
                          flexibility and control.
                        </p>
                      </div>
                      <div className="layout303_item">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/calendar.svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">
                            Managed Release Schedule
                          </h3>
                        </div>
                        <p>
                          Plan your releases with precision. With Wes&#x27;s
                          managed release schedule, you can coordinate your
                          deployments to align with your timelines and business
                          goals.
                        </p>
                      </div>
                      <div className="layout303_item">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/globe-2.svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">Translations</h3>
                        </div>
                        <p>
                          Speak your audience&#x27;s language. Wes&#x27;s
                          creates a surface to connect to a variety of
                          translation systems.
                        </p>
                      </div>
                      <div className="layout303_item">
                        <div className="margin-bottom margin-xsmall">
                          <img
                            src="/images/search-1.svg"
                            loading="lazy"
                            alt=""
                            className="icon-1x1-small"
                          />
                        </div>
                        <div className="margin-bottom margin-xxsmall">
                          <h3 className="heading-style-h5">Unified Site Map</h3>
                        </div>
                        <p>
                          Consolidate multiple Webflow instances into one build
                          with a unified site map, boosting the indexability of
                          your site.{" "}
                        </p>
                      </div>
                    </div>
                    <a
                      id="loadmore-button"
                      href="#"
                      className="button margin-top margin-large show-mobile-landscape w-button"
                    >
                      Load More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div>
            <section className="section_layout373">
              <div className="padding-global">
                <div className="container-large">
                  <div className="padding-section-large extra-padding-left-desktop extra-padding-right-desktop">
                    <div className="margin-bottom margin-xxlarge padding-mob-small">
                      <div className="text-align-center text-align-left-mob">
                        <div className="max-width-large align-center">
                          <div className="margin-bottom margin-small">
                            <h2>Wes Integrations</h2>
                          </div>
                          <p className="text-size-medium">
                            Wes seamlessly integrates with authentication
                            platforms like Auth0 and Okta, code management
                            systems like GitHub, and popular cloud environments
                            including S3, Azure, and Salesforce Cloud, enabling
                            a seamless workflow and enhancing the power of your
                            Webflow deployments.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-layout-grid layout373_component">
                      <div
                        id="w-node-e798e1bc-c718-8b3e-ce9d-8a67a2c6abaa-456df947"
                        className="w-layout-grid layout373_row"
                      >
                        <div
                          id="w-node-e798e1bc-c718-8b3e-ce9d-8a67a2c6abab-456df947"
                          className="layout373_card-large border-bottom"
                        >
                          <div className="layout373_card-large-content">
                            <div className="layout373_card-large-content-top">
                              <div className="margin-bottom margin-small">
                                <div className="display-flex">
                                  <div className="margin-right margin-large">
                                    <img
                                      src="/images/Auth0.svg"
                                      loading="lazy"
                                      alt=""
                                      className="icon-height-auth0"
                                    />
                                  </div>
                                  <img
                                    src="/images/Okta.svg"
                                    loading="lazy"
                                    alt=""
                                    className="icon-height-okta"
                                  />
                                </div>
                              </div>
                              <p className="text-size-regular">
                                Enhance security with Wes&#x27;s seamless
                                integration with authentication platforms like
                                Auth0 and Okta. Safeguard your web applications
                                and ensure secure access with industry-leading
                                authentication and authorization mechanisms.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="layout373_card-small border-bottom-left">
                          <div className="layout373_card-small-content">
                            <div className="layout373_card-small-content-top">
                              <div className="margin-bottom margin-xsmall">
                                <img
                                  src="/images/github.svg"
                                  loading="lazy"
                                  alt=""
                                  className="icon-height-github"
                                />
                              </div>
                              <p className="text-size-regular">
                                Streamline your code management with Wes&#x27;s
                                integration with GitHub.{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="layout373_card-small border-bottom-left border-left-tablet">
                          <div className="layout373_card-small-content">
                            <div className="layout373_card-small-content-top">
                              <div className="margin-bottom margin-xsmall">
                                <img
                                  src="/images/AWS.svg"
                                  loading="lazy"
                                  alt=""
                                  className="icon-height-aws"
                                />
                              </div>
                              <p className="text-size-regular">
                                Leverage the power of Wes&#x27;s integration
                                with Amazon S3 for efficient cloud storage.{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="w-node-cfee55ab-59aa-9be0-020b-19b56edbea9b-456df947"
                        className="w-layout-grid layout373_row"
                      >
                        <div
                          id="w-node-cfee55ab-59aa-9be0-020b-19b56edbea9c-456df947"
                          className="layout373_card-large"
                        >
                          <div className="layout373_card-large-content">
                            <div className="layout373_card-large-content-top">
                              <div className="margin-bottom margin-small">
                                <img
                                  src="/images/MsAzure.svg"
                                  loading="lazy"
                                  alt=""
                                  className="icon-height-msazure"
                                />
                              </div>
                              <p className="text-size-regular">
                                Harness the capabilities of Microsoft Azure with
                                Wes&#x27;s integration. Seamlessly deploy your
                                Webflow projects to the Azure cloud, taking
                                advantage of its robust infrastructure,
                                scalability, and vast array of services.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          id="w-node-_5f7e0185-6a8a-42d1-b140-f3a90359a541-456df947"
                          className="layout373_card-large border-left"
                        >
                          <div className="layout373_card-large-content">
                            <div className="layout373_card-large-content-top">
                              <div className="margin-bottom margin-small">
                                <img
                                  src="/images/Salesforce.svg"
                                  loading="lazy"
                                  alt=""
                                  className="icon-height-salesforce"
                                />
                              </div>
                              <p className="text-size-regular">
                                Unleash the potential of your customer
                                relationship management with Wes&#x27;s
                                integration with Salesforce Cloud. Seamlessly
                                connect your Webflow-powered websites or
                                applications to Salesforce, enabling seamless
                                data synchronization and empowering your sales
                                and marketing teams.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <section className="section_layout353">
          <div className="padding-global">
            <div className="container-large">
              <div className="padding-section-large extra-padding-left-desktop extra-padding-right-desktop">
                <div className="w-layout-grid layout353_component">
                  <div className="layout353_content-left">
                    <div className="margin-bottom margin-small padding-mob-small">
                      <h2>Benefits for Enterprises</h2>
                    </div>
                  </div>
                  <div
                    id="w-node-_645e1c1f-fd78-709b-8a71-1cab2cb4d320-456df947"
                    className="layout353_content-right"
                  >
                    <div className="layout353_content-item content-item-1">
                      <div className="margin-bottom margin-medium">
                        <h3 className="heading-style-h4">Deployment Control</h3>
                      </div>
                      <p className="text-size-regular">
                        Wes puts you in the driver&#x27;s seat, offering
                        unparalleled control over your deployment process. From
                        custom deployments to specific environments, to per-page
                        publishing, Wes allows you to tailor your deployment
                        strategy to your organization&#x27;s unique needs. With
                        the ability to roll back a single page, section, or the
                        entire build, you can manage changes effectively and
                        maintain a robust version control.
                      </p>
                    </div>
                    <div className="layout353_content-item content-item-2">
                      <div className="margin-bottom margin-medium">
                        <h3 className="heading-style-h4">Security</h3>
                      </div>
                      <p className="text-size-regular">
                        Wes is designed with security at its core. From
                        GDPR-specific server deployments to Locked CSS that
                        prevents unauthorized changes, Wes helps you maintain a
                        secure and compliant environment. Additionally,
                        Wes&#x27;s seamless integration with other code systems
                        through server-side code injections enhances your
                        security posture.
                      </p>
                    </div>
                    <div className="layout353_content-item content-item-3">
                      <div className="margin-bottom margin-medium">
                        <h3 className="heading-style-h4">Scalability</h3>
                      </div>
                      <p className="text-size-regular">
                        Wes is built to scale with your organization. Whether
                        you&#x27;re deploying to your custom configured CDN or
                        managing a release schedule that aligns with your
                        business goals, Wes supports your growth. Its change
                        detection feature ensures you stay informed about every
                        update, enabling efficient and effective deployments.
                      </p>
                    </div>
                    <div className="layout353_content-item content-item-4">
                      <div className="margin-bottom margin-medium">
                        <h3 className="heading-style-h4">Flexibility</h3>
                      </div>
                      <p className="text-size-regular">
                        Wes provides the flexibility your enterprise needs. With
                        features like server-side code injections for seamless
                        integration with other code systems, and a surface to
                        connect to a variety of translation systems, Wes allows
                        you to customize your Webflow experience. Plus, with the
                        &#x27;Draft&#x27; component, you have the flexibility to
                        control when pages move into production.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="background-gradient is-inverse">
          <section className="section_layout201">
            <div className="padding-global">
              <div className="container-large">
                <div className="padding-section-large extra-padding-left-desktop extra-padding-right-desktop">
                  <div className="w-layout-grid layout201_component">
                    <div className="layout201_image-wrapper">
                      <img
                        src="/images/enterprise-exclusive.png"
                        loading="lazy"
                        sizes="(max-width: 767px) 90vw, (max-width: 991px) 43vw, 33vw"
                        srcSet="/images/enterprise-exclusive-p-500.png 500w, /images/enterprise-exclusive-p-800.png 800w, /images/enterprise-exclusive-p-1080.png 1080w, /images/enterprise-exclusive-p-1600.png 1600w, /images/enterprise-exclusive.png 1760w"
                        alt=""
                        className="layout201_image shadow-xxlarge"
                      />
                    </div>
                    <div
                      id="w-node-_74390d43-f847-f018-2b70-831730faee8e-456df947"
                      className="layout201_content"
                    >
                      <div className="margin-bottom margin-small">
                        <h2>Webflow Enterprise Exclusive</h2>
                      </div>
                      <div className="w-layout-grid layout201_item-list">
                        <div className="layout201_item">
                          <div className="margin-bottom margin-xxsmall">
                            <h3 className="heading-style-h5">
                              Only for Enterprise{" "}
                            </h3>
                          </div>
                          <p>
                            Wes is exclusively available to Webflow Enterprise
                            customers. As a Webflow Enterprise customer, you
                            have the unique opportunity to leverage Wes&#x27;s
                            powerful features and benefits.
                          </p>
                        </div>
                        <div className="layout201_item">
                          <div className="margin-bottom margin-xxsmall">
                            <h3 className="heading-style-h5">
                              Built in Partnership
                            </h3>
                          </div>
                          <p>
                            We&#x27;ve built Wes in close collaboration with
                            Webflow, ensuring it seamlessly extends
                            Webflow&#x27;s capabilities to meet the needs of
                            enterprise-level deployments.
                          </p>
                        </div>
                      </div>
                      <div className="margin-top margin-medium">
                        <div className="button-group">
                          <a
                            data-w-id="74390d43-f847-f018-2b70-831730faeeab"
                            href="#"
                            className="button is-hero w-button"
                            onClick={showForm}
                          >
                            Book a Demo
                          </a>
                          <Link
                            to="/login"
                            className="button is-hero is-tertiary w-button"
                          >
                            Log In
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <header className="section_cta37">
          <div className="padding-global">
            <div className="container-medium">
              <div className="padding-section-large">
                <div className="cta37_component">
                  <a
                    data-w-id="8fd3994b-17b2-d9fb-26c2-f1f6840f31c8"
                    href="#"
                    className="cta37_link-block w-inline-block"
                    onClick={showForm}
                  >
                    <h3 className="cta37_link-text">
                      Book a Demo for
                      <br />
                      Webflow Enterprise
                    </h3>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </header>
      </main>
      <footer className="footer4_component">
        <div className="padding-global">
          <div className="container-large">
            <div className="padding-vertical padding-small">
              <div className="w-layout-grid footer4_top-wrapper">
                <a
                  href="index.html"
                  id="w-node-ee999d70-510f-77aa-68d9-ad3b2d593bf2-2d593bed"
                  aria-current="page"
                  className="footer4_logo-link w-nav-brand w--current"
                >
                  <img src="/images/Wes.svg" loading="lazy" alt="Wes" />
                </a>
                <div className="w-layout-grid footer4_link-list">
                  <Link
                  to="/privacy"
                    id="w-node-_630333c3-5fc9-5e71-243c-aa5930162276-2d593bed"
                    className="footer4_link"
                  >
                    Privacy Policy
                  </Link>
                  <Link to="/term" className="footer4_link">
                    Terms of Service
                  </Link>
                </div>
                <div
                  id="w-node-ee999d70-510f-77aa-68d9-ad3b2d593bf6-2d593bed"
                  className="w-layout-grid footer4_social-list"
                >
                  <a
                    href="https://www.edgarallan.com/"
                    target="_blank"
                    className="made-by-ea w-inline-block"
                  >
                    <div>Made By</div>
                    <img
                      src="/images/ea-logo-dot.svg"
                      loading="lazy"
                      alt=""
                      className="image"
                    />
                  </a>
                  <div className="display-none">
                    <a
                      href="https://www.linkedin.com/company/edgar-allan"
                      target="_blank"
                      className="footer4_social-link w-inline-block"
                    >
                      <div className="social-icon w-embed">
                        <svg
                          width="100%"
                          height="100%"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3ZM8 18C8.27614 18 8.5 17.7761 8.5 17.5V10.5C8.5 10.2239 8.27614 10 8 10H6.5C6.22386 10 6 10.2239 6 10.5V17.5C6 17.7761 6.22386 18 6.5 18H8ZM7.25 9C6.42157 9 5.75 8.32843 5.75 7.5C5.75 6.67157 6.42157 6 7.25 6C8.07843 6 8.75 6.67157 8.75 7.5C8.75 8.32843 8.07843 9 7.25 9ZM17.5 18C17.7761 18 18 17.7761 18 17.5V12.9C18.0325 11.3108 16.8576 9.95452 15.28 9.76C14.177 9.65925 13.1083 10.1744 12.5 11.1V10.5C12.5 10.2239 12.2761 10 12 10H10.5C10.2239 10 10 10.2239 10 10.5V17.5C10 17.7761 10.2239 18 10.5 18H12C12.2761 18 12.5 17.7761 12.5 17.5V13.75C12.5 12.9216 13.1716 12.25 14 12.25C14.8284 12.25 15.5 12.9216 15.5 13.75V17.5C15.5 17.7761 15.7239 18 16 18H17.5Z"
                            fill="CurrentColor"
                          ></path>
                        </svg>
                      </div>
                    </a>
                    <a
                      href="https://www.youtube.com/edgarallan"
                      target="_blank"
                      className="footer4_social-link w-inline-block"
                    >
                      <div className="social-icon w-embed">
                        <svg
                          width="100%"
                          height="100%"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M18.5399 4.33992L19.9999 4.48992C21.7284 4.68529 23.0264 6.16064 22.9999 7.89992V16.0999C23.0264 17.8392 21.7284 19.3146 19.9999 19.5099L18.5999 19.6599C14.2315 20.1099 9.82835 20.1099 5.45991 19.6599L3.99991 19.5099C2.27143 19.3146 0.973464 17.8392 0.999909 16.0999V7.89992C0.973464 6.16064 2.27143 4.68529 3.99991 4.48992L5.39991 4.33992C9.76835 3.88995 14.1715 3.88995 18.5399 4.33992ZM11.1099 15.2199L14.9999 12.6199H15.0599C15.2695 12.4833 15.3959 12.2501 15.3959 11.9999C15.3959 11.7497 15.2695 11.5165 15.0599 11.3799L11.1699 8.77992C10.9402 8.62469 10.6437 8.60879 10.3987 8.73859C10.1538 8.86839 10.0004 9.12271 9.99991 9.39992V14.5999C10.0128 14.858 10.1576 15.0913 10.3832 15.2173C10.6088 15.3433 10.8834 15.3443 11.1099 15.2199Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </div>
                    </a>
                    <a
                      href="https://twitter.com/EdgarAllanCo"
                      target="_blank"
                      className="footer4_social-link w-inline-block"
                    >
                      <div className="social-icon w-embed">
                        <svg
                          width="100%"
                          height="100%"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20.9728 6.7174C20.5084 7.33692 19.947 7.87733 19.3103 8.31776C19.3103 8.47959 19.3103 8.64142 19.3103 8.81225C19.3154 11.7511 18.1415 14.5691 16.0518 16.6345C13.962 18.6999 11.1312 19.8399 8.19405 19.7989C6.49599 19.8046 4.81967 19.4169 3.29642 18.6661C3.21428 18.6302 3.16131 18.549 3.16162 18.4593V18.3604C3.16162 18.2313 3.26623 18.1267 3.39527 18.1267C5.06442 18.0716 6.67402 17.4929 7.99634 16.4724C6.48553 16.4419 5.12619 15.5469 4.5006 14.1707C4.46901 14.0956 4.47884 14.0093 4.52657 13.9432C4.57429 13.8771 4.653 13.8407 4.73425 13.8471C5.19342 13.8932 5.65718 13.8505 6.1002 13.7212C4.43239 13.375 3.17921 11.9904 2.99986 10.2957C2.99349 10.2144 3.02992 10.1357 3.096 10.0879C3.16207 10.0402 3.24824 10.0303 3.32338 10.062C3.77094 10.2595 4.25409 10.3635 4.74324 10.3676C3.28184 9.40846 2.65061 7.58405 3.20655 5.92622C3.26394 5.76513 3.40181 5.64612 3.5695 5.61294C3.73718 5.57975 3.90996 5.63728 4.02432 5.76439C5.99639 7.86325 8.70604 9.11396 11.5819 9.25279C11.5083 8.95885 11.4721 8.65676 11.4741 8.35372C11.501 6.76472 12.4842 5.34921 13.9634 4.76987C15.4425 4.19054 17.1249 4.56203 18.223 5.71044C18.9714 5.56785 19.695 5.31645 20.3707 4.96421C20.4202 4.93331 20.483 4.93331 20.5325 4.96421C20.5634 5.01373 20.5634 5.07652 20.5325 5.12604C20.2052 5.87552 19.6523 6.50412 18.9509 6.92419C19.5651 6.85296 20.1685 6.70807 20.7482 6.49264C20.797 6.45942 20.8611 6.45942 20.9099 6.49264C20.9508 6.51134 20.9814 6.54711 20.9935 6.59042C21.0056 6.63373 20.998 6.68018 20.9728 6.7174Z"
                            fill="CurrentColor"
                          ></path>
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div style={{ display: "none", opacity: 0 }} className="contact-modal2_component">
        <div
          style={{
            transform:
              "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
          }}
          className="contact-modal2_content-wrapper"
        >
          <div className="margin-bottom margin-large">
            <div className="text-align-center">
              <div className="max-width-large text-align-left">
                <h2>Book a Demo</h2>
              </div>
            </div>
          </div>
          <div className="contact-modal2_form-block w-form">
            {!isSubmitted && <form
              id="Wes-Contact"
              name="wf-form-Wes-Contact"
              data-name="Wes Contact"
              method="get"
              className="contact-modal2_form"
            >
              <div className="form-field-2col">
                <div className="form-field-wrapper">
                  <label
                    htmlFor="Contact-2-First-Name-2"
                    className="field-label"
                  >
                    First name
                  </label>
                  <input
                    type="text"
                    className="form-input w-input"
                    maxLength={256}
                    name="Contact-2-First-Name"
                    data-name="Contact 2 First Name"
                    placeholder=""
                    id="Contact-2-First-Name-2"
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="form-field-wrapper">
                  <label htmlFor="Contact-2-Last-Name" className="field-label">
                    Last name
                  </label>
                  <input
                    type="text"
                    className="form-input w-input"
                    maxLength={256}
                    name="Contact-2-Last-Name"
                    data-name="Contact 2 Last Name"
                    placeholder=""
                    id="Contact-2-Last-Name"
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-field-2col is-mobile-1col">
                <div className="form-field-wrapper">
                  <label htmlFor="Contact-2-Email-2" className="field-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-input w-input"
                    maxLength={256}
                    name="Contact-2-Email-2"
                    data-name="Contact 2 Email 2"
                    placeholder=""
                    id="Contact-2-Email-2"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-field-wrapper">
                  <label htmlFor="Contact-phone" className="field-label">
                    Phone number
                  </label>
                  <input
                    type="tel"
                    className="form-input w-input"
                    maxLength={256}
                    name="Contact-phone"
                    data-name="Contact phone"
                    placeholder=""
                    id="Contact-phone"
                    required
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>
              {/* <div className="form-field-wrapper">
                <label htmlFor="Interest-topic" className="field-label">
                  Choose a topic
                </label>
                <select
                  id="Interest-topic"
                  name="Interest-topic"
                  data-name="Interest topic"
                  required
                  className="form-input is-select-input w-select"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                >
                  <option value="">Select one...</option>
                  <option value="Learning more">
                    I&#x27;m interested in learning more about Wes
                  </option>
                  <option value="Webflow enterprise">
                    I&#x27;m interested in Webflow Enterprise
                  </option>
                  <option value="Wes and Enterprise">
                    I&#x27;m interested in Wes &amp; Enterprise
                  </option>
                </select>
              </div> */}
              <div
                id="w-node-_8497122d-ee73-144f-3e0a-044c1885ec3e-456df947"
                className="form-button-wrap"
              >
                <input
                  type="submit"
                  value={loading? "Please wait..." : "Submit"}
                  data-wait="Please wait..."
                  className="button is-form w-button"
                  disabled={loading}
                  onClick={sendRequest}
                />
              </div>
            </form>}
            {isSubmitted && <div className="success-message">
              <div className="success-text">
                Thank you! Your submission has been received!
              </div>
            </div>}
            <div className="error-message w-form-fail">
              <div className="error-text">
                Oops! Something went wrong while submitting the form.
              </div>
            </div>
          </div>
        </div>
        <a
          data-w-id="10a2723d-93e2-6e06-76fe-e81f33403914"
          href="#"
          className="contact-modal2_close-button w-inline-block"
          onClick={closeForm}
        >
          <div className="icon-embed-small hide-mobile-landscape w-embed">
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.056 23.5004L23.5004 24.056C23.1935 24.3628 22.696 24.3628 22.3892 24.056L16 17.6668L9.61078 24.056C9.30394 24.3628 8.80645 24.3628 8.49961 24.056L7.94403 23.5004C7.63719 23.1936 7.63719 22.6961 7.94403 22.3892L14.3332 16L7.94403 9.61081C7.63719 9.30397 7.63719 8.80648 7.94403 8.49964L8.49961 7.94406C8.80645 7.63721 9.30394 7.63721 9.61078 7.94406L16 14.3333L22.3892 7.94404C22.6961 7.6372 23.1935 7.6372 23.5004 7.94404L24.056 8.49963C24.3628 8.80647 24.3628 9.30395 24.056 9.61079L17.6667 16L24.056 22.3892C24.3628 22.6961 24.3628 23.1936 24.056 23.5004Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <img
            src="https://uploads-ssl.webflow.com/624380709031623bfe4aee60/624380709031623afe4aee7e_icon_close-modal.svg"
            loading="lazy"
            alt=""
            className="show-mobile-landscape"
          />
        </a>
        <div
          data-w-id="10a2723d-93e2-6e06-76fe-e81f33403917"
          className="contact-modal2_background-overlay"
        ></div>
      </div>
      <div className="extra-padding-right-desktop"></div>
    </React.Fragment>
  )
}

export default LandingPage
