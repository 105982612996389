import { Paginator } from "@/models/Paginator"
import { Project, ProjectAwsSetting } from "@/models/Project"
import BaseClient from "./BaseClient"

class ProjectClient extends BaseClient {
  /**
   * get all the projects
   * @param params
   * @returns Promise<Paginator<Project>>
   */
  getAllProjects = (params?: {
    search?: string
    limit?: number
    sort_by?: string
    order?: string
    page?: number
  }): Promise<Paginator<Project>> => {
    return this.get("/project", { params })
  }

  /**
   * create new project
   * @param data
   * @returns Promise<Project>
   */
  createProject = (data: Project): Promise<Project> => {
    return this.post("/project", data)
  }

  /**
   * update project
   * @param id
   * @param data
   * @returns Promise<Project>
   */
  updateProject = (id: number, data: Project): Promise<Project> => {
    return this.put(`/project/${id}`, data)
  }

  /**
   * get project detail
   * @param id
   * @returns
   */
  getProject = (id: number | string): Promise<Project> => {
    return this.get(`/project/${id}`)
  }

  /**
   * delete project
   * @param id
   * @returns
   */
  deleteProject = (id: number): Promise<Project> => {
    return this.delete(`/project/${id}`)
  }

  /**
   * Create project build
   * @param id
   * @returns
   */
  buildProject = (id: number): Promise<any> => {
    return this.post(`/project/${id}/build`, [])
  }

  /**
   * Get all project builds
   * @param id
   * @param params
   * @returns
   */
  getProjectBuilds = (id: number, params: any): Promise<any> => {
    return this.get(`/project/${id}/build`, { params })
  }

  /**
   * Create a zip file for the project
   * @param id
   * @returns
   */
  createArchive = (id: number): Promise<any> => {
    return this.post(`/project/${id}/archive`, [])
  }

  uploadArchive = (projectId: number, buildId: number, accel: boolean) => {
    return this.post(`/project/${projectId}/build/${buildId}/upload`, { accel })
  }

  purgeRepository = (id: number): Promise<any> => {
    return this.delete(`/project/${id}/repository`, [])
  }

  purgeBuild = (id: number): Promise<any> => {
    return this.delete(`/project/${id}/build`, [])
  }

  updateThumbnail = (id: number, image: string): Promise<Project> => {
    return this.post(`/project/${id}/thumbnail`, { image })
  }

  getAwsSettings = (id: number): Promise<ProjectAwsSetting> => {
    return this.get(`/project/${id}/aws/settings`)
  }

  createLokaliseProject = (id: number, data: object): Promise<Project> => {
    return this.post(`/project/${id}/lokalise/project`, data)
  }

  addLokaliseProjectLanguage = (id: number, lang: string): Promise<any> => {
    return this.post(`/project/${id}/lokalise/language`, { lang })
  }

  deleteLokaliseLanguage = (id: number, lang: string): Promise<any> => {
    return this.delete(`/project/${id}/lokalise/language/${lang}`)
  }

  updateAwsSettings = (id: number, data: any): Promise<Project> => {
    return this.post(`/project/${id}/aws/settings`, data)
  }

  pushLokaliseToS3 = (id: number, lang: string): Promise<any> => {
    return this.post(`/project/${id}/lokalise/to/s3`, { lang })
  }

  enableWeglot = (
    project_id: number,
    languages: Array<any>
  ): Promise<Project> => {
    return this.post(`/weglot`, { project_id, languages })
  }

  publishProduction = (id: number, data?: any) => {
    return this.post(`/project/${id}/publish/production`, data)
  }

  getLogs = (id: number, search?: string): Promise<any> => {
    return this.get(`/project/${id}/logs`, { params: { search }});
  }
}

export default ProjectClient
