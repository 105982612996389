import React, { useContext, useEffect, useState } from "react"
import { AppStateContext } from "@/components/contexts/AppContext"
import ProjectClient from "@/clients/ProjectClient"
import Plus from "@/resources/images/plus-1-1_1.svg"
import ArchiveItem from "@/components/archive/ArchiveItem"
import { message, Skeleton, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import Echo from "laravel-echo"
import JWTHelper from "@/utilities/JWTHelper"
const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />

const Archive: React.ComponentType = () => {
  const projectClient = new ProjectClient()
  const { state } = useContext(AppStateContext)
  const [archives, setArchives] = useState<Array<any>>([])
  const [loading, setLoading] = useState(false)
  const [createLoading, setCreateLoading] = useState(false)
  const [isListening, setIsListening] = useState(false)
  const [echo, setEcho] = useState<Echo>()

  useEffect(() => {
    if (state.project) {
      loadArchives()
    }
  }, [])

  useEffect(() => {
    if (!echo) return

    // if (isListening)
    //   return;

    echo!
      .private(`project.${state.project!.id}.build`)
      .listen(".zip.created", (e: any) => {
        let build = e.build
        archives.map((b: any, index) => {
          // console.log(b.id);
          if (b.id === build.id) {
            archives[index].is_done = 1
          }
        })
        setArchives([...archives])
        setCreateLoading(false)
      })

    setIsListening(true)
  }, [echo])

  const loadArchives = () => {
    setLoading(true)
    projectClient.getProjectBuilds(state.project!.id!, { limit: 15 }).then(
      (res: any) => {
        setArchives(res.data)
        setLoading(false)
      },
      (error: string) => {
        message.error(error)
        setLoading(false)
      }
    )
  }

  const initializeEcho = () => {
    if (echo !== undefined)
      // don't re-initiliaze echo so it will not send multiple notifications
      return

    let client = new Echo({
      broadcaster: "pusher",
      key: process.env.REACT_APP_PUSHER_APP_KEY,
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      forceTLS: false,
      authEndpoint: process.env.REACT_APP_API_BASE_URL + "/broadcasting/auth",
      auth: {
        headers: { Authorization: "Bearer " + JWTHelper.getAccessToken() },
      },
    })
    setEcho(client)
  }

  const createArchive = () => {
    if (createLoading) return true

    setCreateLoading(true)
    projectClient.createArchive(state.project!.id!).then(
      (res: any) => {
        archives.unshift(res)
        setArchives([...archives])
        setEcho(undefined) // reset echo to update the data
        initializeEcho()
      },
      (error: string) => {
        message.error(error)
      }
    )
  }

  return (
    <div className="padding-vertical-small" style={{ position: "relative" }}>
      <div className="page-heading-wrapper">
        <h2 className="page-heading">Build Archives</h2>
        <a
          href="#"
          className="add-source-link w-inline-block add-archive-item"
          onClick={() => createArchive()}
        >
          {!createLoading && (
            <img src={Plus} loading="lazy" alt="" className="image" />
          )}
          {createLoading && (
            <Spin
              spinning={true}
              indicator={antIcon}
              style={{ marginRight: 10 }}
            />
          )}
          <div>Create Archive</div>
        </a>
      </div>
      <div className="repository-grid-wrapper archive-grid-wrapper ">
        <div className="repository-grid-navigation-wrapper">
          <div
            id="w-node-_4daa76ca-2b85-2e30-0711-de7cb3f0b322-1d037768"
            className="repository-name-wrapper-w-padding"
          >
            <div className="page-grid-text">Name</div>
          </div>
          <div className="page-grid-text">Created</div>
          <div className="page-grid-text">Actions</div>
        </div>
        <Skeleton loading={loading}>
          {archives.map((archive, index) => {
            return (
              <ArchiveItem
                key={archive.id}
                archive={archive}
                isgrey={index % 2 === 0 ? 1 : undefined}
                project={state.project!}
              />
            )
          })}
          {archives.length === 0 && (
            <div className="empty-state">
              You have not yet created an archive
            </div>
          )}
        </Skeleton>
      </div>
    </div>
  )
}

export default Archive
