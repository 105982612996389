import React, { useContext, useEffect, useState } from "react"
import Plus from "@/resources/images/plus-1-1_1.svg"
import {
  AppStateContext,
  AppDispatchContext,
} from "@/components/contexts/AppContext"
import { ProjectSource } from "@/models/Project"
import { Actions } from "../reducers/AppReducer"
import AddSourceModal from "@/components/modals/AddSourceModal"
import EditSourceModal from "@/components/modals/EditSourceModal"
import DeleteSourceModal from "@/components/modals/DeleteSourceModal"
import SourceList from "@/components/source/SourceList"
import SourceItem from "@/components/source/SourceItem"
import Translation from "@/components/pages/Translation"

const Sources = () => {
  const { state } = useContext(AppStateContext)
  const { dispatch } = useContext(AppDispatchContext)
  const [showCreateSource, setShowCreateSource] = useState(false)
  const [showEditSource, setShowEditSource] = useState(false)
  const [showDeleteSource, setShowDeleteSource] = useState(false)
  const [showSourceList, setShowSourceList] = useState(false)
  const [currentSource, setCurrentSource] = useState<ProjectSource>()

  const onCreateSource = (source: ProjectSource) => {
    state.project!.sources!.push(source)
    dispatch({ type: Actions.UPDATE_PROJECT, payload: state.project })
    setShowCreateSource(false)
  }

  useEffect(() => {
    setShowCreateSource(!state.project!.sources!.length)
  }, [])

  const onUpdateSource = (source: ProjectSource) => {
    setShowEditSource(false)
    state.project!.sources!.map((s: ProjectSource, index) => {
      if (s.id === source.id) state.project!.sources![index] = source
      state.project!.sources![index].open_dropdown = false
    })
    // console.log(state.project);
    dispatch({ type: Actions.UPDATE_PROJECT, payload: state.project })
  }

  const openEditModal = (source: ProjectSource) => {
    setCurrentSource(source)
    setShowEditSource(true)
    state.project!.sources!.map((s: ProjectSource, index) => {
      state.project!.sources![index].open_dropdown = false
    })
  }

  const deleteSource = (source: ProjectSource) => {
    // console.log(source);
    setCurrentSource(source)
    setShowDeleteSource(true)
  }

  const onDeleteSource = (source: ProjectSource) => {
    let sources = state.project!.sources?.filter((s: ProjectSource) => {
      return s.id !== source.id
    })
    state.project!.sources = sources
    dispatch({ type: Actions.UPDATE_PROJECT, payload: state.project })
    setShowDeleteSource(false)
  }

  const setSourceList = (e: any, source: ProjectSource) => {
    // console.log(e.target);
    if (e.target.classList.contains("object-fit-cover")) {
      state.project!.sources!.map((s: ProjectSource, index) => {
        if (s.id === source.id)
          state.project!.sources![index].open_dropdown = !source.open_dropdown
        else state.project!.sources![index].open_dropdown = false
      })
      // console.log(state.project);
      dispatch({ type: Actions.UPDATE_PROJECT, payload: state.project })
    } else if (!e.target.classList.contains("w-dropdown-link")) {
      state.project!.sources!.map((s: ProjectSource, index) => {
        state.project!.sources![index].open_dropdown = false
      })
      dispatch({ type: Actions.UPDATE_PROJECT, payload: state.project })
      setCurrentSource(source)
      setShowSourceList(true)
    }
  }

  const closeSourceList = () => {
    setShowSourceList(false)
  }

  return (
    <div className="padding-vertical-small">
      <div className="page-heading-wrapper">
        <h2 className="page-heading">Sources</h2>
      </div>
      <div className="repository-grid-wrapper source-grid ">
        <div className="repository-grid-navigation-wrapper">
          <div className="repository-name-wrapper-w-padding">
            <div className="page-grid-text">Name</div>
          </div>
          <div className="page-grid-text">Homepage URL</div>
          <div className="page-grid-text">Created</div>
        </div>
        {!state.project!.sources ||
          (!state.project!.sources!.length && (
            <div className="empty-state">You haven't added a source yet</div>
          ))}
        {state.project!.sources!.map((source, i) => {
          return (
            <SourceItem
              source={source}
              editSource={openEditModal}
              key={source.id}
              index={i}
              onDelete={deleteSource}
              onClick={(e: any) => setSourceList(e, source)}
            />
          )
        })}
      </div>
      <a
        href="#"
        className="add-source-link w-inline-block"
        onClick={() => setShowCreateSource(true)}
      >
        <img src={Plus} loading="lazy" alt="" className="image" />
        <div>Add Source</div>
      </a>
      <AddSourceModal
        show={showCreateSource}
        onClose={() => setShowCreateSource(false)}
        onCreate={onCreateSource}
        project={state.project!}
      />
      <EditSourceModal
        show={showEditSource}
        onClose={() => setShowEditSource(false)}
        onUpdate={onUpdateSource}
        project={state.project!}
        source={currentSource!}
      />
      <DeleteSourceModal
        show={showDeleteSource}
        onClose={() => setShowDeleteSource(false)}
        onDelete={onDeleteSource}
        source={currentSource!}
        project={state.project!}
      />
      <SourceList
        show={showSourceList}
        source={currentSource!}
        onClose={closeSourceList}
      />

      {state.project && state.project.data.translations && (
        <div className="padding-vertical-small">
          <Translation />
        </div>
      )}
    </div>
  )
}

export default Sources
