import React, { useState } from "react"
import { User } from "@/models/User"
import UserClient from "@/clients/UserClient"
import { Button } from "@/components/common"
import { message } from "antd"

const CreateUser: React.ComponentType = () => {
  const userClient = new UserClient()
  const [name, setName] = useState("")
  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [newPass, setNewPass] = useState("")
  const [confirmPass, setConfirmPass] = useState("")
  const [loading, setLoading] = useState(false)

  const createUser = () => {
    if (newPass.length <= 0) {
      message.error("Please enter New Password.")
      return
    }

    if (name.length <= 0) {
      message.error("Please enter name.")
      return
    }

    if (username.length <= 0) {
      message.error("Please enter username.")
      return
    }

    if (newPass !== confirmPass) {
      message.error("Password mismatch!")
      return
    }

    let data = {
      name: name,
      username: username,
      email: email,
      role_id: 1,
      password: newPass,
    }

    setLoading(true)
    userClient
      .createUser(data)
      .then(
        (user: User) => {
          message.success(
            "You have successfully created a new user. Provide the new user the following crendentials to sign in. Email: " +
              email +
              " Password: " +
              newPass
          )
          setLoading(false)
          setName("")
          setUsername("")
          setEmail("")
          setNewPass("")
          setConfirmPass("")
        },
        (error: string) => message.error(error)
      )
      .finally(() => setLoading(false))
  }

  return (
    <div className="user-popup-content-wrapper">
      <div className="popup-modal-form-block w-form">
        <form
          id="New-User"
          name="wf-form-New-User"
          data-name="New User"
          method="get"
        >
          <div className="margin-bottom-30">
            <label className="popup-modal-field-label">Display Name</label>
            <input
              type="text"
              className="popup-modal-input-field w-input"
              maxLength={256}
              name="new-user-display-name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              data-name="new user display name"
              placeholder="Display Name"
              id="new-user-display-name"
            />
            <label className="popup-modal-field-label">Username</label>
            <input
              type="text"
              className="popup-modal-input-field w-input"
              maxLength={256}
              name="new-user-username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              data-name="new user username"
              placeholder="Username"
              id="new-user-username"
              required
            />
            <label className="popup-modal-field-label">Email</label>
            <input
              type="email"
              className="popup-modal-input-field w-input"
              maxLength={256}
              name="new-user-email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              data-name="new user email"
              placeholder="Email"
              id="new-user-email"
              required
            />
            <label className="popup-modal-field-label">Password</label>
            <input
              type="password"
              className="popup-modal-input-field w-input"
              maxLength={256}
              name="new-user-password"
              value={newPass}
              onChange={(e) => setNewPass(e.target.value)}
              data-name="new user password"
              placeholder="Password"
              id="new-user-password"
              required
            />
            <label className="popup-modal-field-label">Re-type Password</label>
            <input
              type="password"
              className="popup-modal-input-field w-input"
              maxLength={256}
              name="new-user-re-type-password"
              value={confirmPass}
              onChange={(e) => setConfirmPass(e.target.value)}
              data-name="new user re type password"
              placeholder="Re-type Password"
              id="new-user-re-type-password"
              required
            />
          </div>

          <Button
            label="Create User"
            loading={loading}
            className={"submit-button w-button"}
            onClick={createUser}
          />
        </form>
      </div>
    </div>
  )
}

export default CreateUser
