import React, { useContext, useEffect, useState } from "react"
import {
  AppStateContext,
  AppDispatchContext,
} from "@/components/contexts/AppContext"
import { User } from "@/models/User"
import UserClient from "@/clients/UserClient"
import { Actions } from "../reducers/AppReducer"
import { message } from "antd"
import { Button } from "@/components/common"

const ProfileInformation: React.ComponentType = () => {
  const userClient = new UserClient()
  const { state } = useContext(AppStateContext)
  const { dispatch } = useContext(AppDispatchContext)
  const [user, setUser] = useState<User>()
  const [displayName, setDisplayName] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (state.user) {
      setUser(state.user)
      setDisplayName(state.user.name)
    }
  }, [state.user])

  const updateUser = () => {
    setLoading(true)
    user!.name = displayName

    userClient.updateUser(user!.id!, user!).then(
      (u: User) => {
        dispatch({ type: Actions.UPDATE_USER, payload: u })
        setLoading(false)
      },
      (error: string) => {
        message.error(error)
        setLoading(false)
      }
    )
  }

  if (!user) return null

  return (
    <div className="user-popup-content-wrapper">
      <div className="popup-modal-form-block w-form">
        <form
          id="Account-Settings"
          name="wf-form-Account-Settings"
          data-name="Account Settings"
          method="get"
        >
          <div className="margin-bottom-30">
            <label className="popup-modal-field-label">Display Name</label>
            <input
              type="text"
              className="popup-modal-input-field w-input"
              maxLength={256}
              name="display-name"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              data-name="display name"
              placeholder="Display Name"
              id="display-name"
            />
            <label className="popup-modal-field-label">Username</label>
            <input
              type="text"
              className="popup-modal-input-field w-input"
              maxLength={256}
              name="username"
              readOnly
              value={user.username}
              data-name="username"
              placeholder="Username"
              id="username"
              required
            />
            <label className="popup-modal-field-label">Email Address</label>
            <input
              type="text"
              className="popup-modal-input-field w-input"
              maxLength={256}
              name="email-address"
              readOnly
              value={user.email}
              data-name="email address"
              placeholder="Email Address"
              id="email-address"
              required
            />
          </div>
          <Button
            label="Save"
            loading={loading}
            className={"submit-button w-button"}
            onClick={updateUser}
          />
        </form>
      </div>
    </div>
  )
}

export default ProfileInformation
