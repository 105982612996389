import { Project } from "@/models/Project"
import React, { useContext, useEffect, useState } from "react"
import moment from "moment"
import { Button } from "@/components/common"
import ProjectClient from "@/clients/ProjectClient"
import Echo from "laravel-echo"
import JWTHelper from "@/utilities/JWTHelper"
import { message, Skeleton } from "antd"
import {
  AppDispatchContext,
  AppStateContext,
} from "@/components/contexts/AppContext"
import { Actions } from "../reducers/AppReducer"
import LinkIcon from "@/resources/images/62da786ec4980c7dbd64f007_Icon - Link.svg"
import AWSIcon from "@/resources/images/aws_s3.svg"
import AzureIcon from "@/resources/images/azure.svg"
import GithubIcon from "@/resources/images/github.svg"
import ZipIcon from "@/resources/images/zip.svg"
import { truncateUrl } from "@/utilities/String"

interface PubishProductionProps {
  project: Project
}

const PublishProduction: React.ComponentType<PubishProductionProps> = ({
  project,
}) => {
  const projectClient = new ProjectClient()
  const { dispatch } = useContext(AppDispatchContext)
  const { state } = useContext(AppStateContext)
  const [loading, setLoading] = useState(false)
  const [publishing, setPublishing] = useState(false)
  const [echo, setEcho] = useState<Echo | null>()
  const [isListening, setIsListening] = useState(false)
  const [archives, setArchives] = useState<Array<any>>([])

  useEffect(() => {
    initializeEcho()
    loadArchives()
  }, [])

  const loadArchives = () => {
    setLoading(true)
    projectClient.getProjectBuilds(project!.id!, { limit: 1 }).then(
      (res: any) => {
        setArchives(res.data)
        setLoading(false)
      },
      (error: string) => {
        message.error(error)
        setLoading(false)
      }
    )
  }

  const getBuildLink = () => {
    return process.env.REACT_APP_SITE_URL + "/archive/" + archives[0].filename
  }

  useEffect(() => {
    // console.log(echo);
    if (!echo) return

    // console.log("Setup listener!!");
    let channel = echo!
      .private(`project.${project.id}.build`)
      .listen(".zip.created", (e: any) => {
        let build = e.build
        // console.log(build);
        let data = {
          build_id: build.id,
          accel: project.id === 72 || project.id === 90 || project.id === 106,
        }

        if (build.project_id !== project.id) return

        setArchives([build])

        if (project.data?.hosting && publishing) {
          projectClient.publishProduction(project.id!, data).then(
            () => {
              // message.success("Successfully publish build.")
              project.is_publishing_prod = false
              project.is_publishing = false
              project!.is_published = true
              dispatch({ type: Actions.UPDATE_PROJECT, payload: project })
              setPublishing(false)
            },
            (error: string) => {
              // message.error(error);
              setPublishing(false)
            }
          )
        } else {
          project.is_publishing_prod = false
          project.is_publishing = false
          project!.is_published = true
          dispatch({ type: Actions.UPDATE_PROJECT, payload: project })
          setPublishing(false)
          // message.success("Archive successfully created.")
        }

        // axios.post('https://portal.accel.com/deploy', {file_url: process.env.REACT_APP_SITE_URL + "/archive/" + build.filename}).then(
        //   () => {
        //     message.success("Successfully publish build.");
        //     setLoading(false);
        //   },
        //   (error) => {
        //     setLoading(false);
        //   }
        // )
      })

    // setIsListening(true)

    return () => {
      channel.stopListening(".zip.created")
    }
  }, [publishing])

  const createArchive = () => {
    setPublishing(true)
    state.project!.is_publishing_prod = true
    dispatch({ type: Actions.UPDATE_PROJECT, payload: state.project })

    projectClient.createArchive(project.id!).then(
      () => {},
      (error: string) => {
        message.error(error)
      }
    )
  }

  const getIcon = () => {
    switch (project.data.hosting) {
      case "aws":
        return AWSIcon
      case "azure":
        return AzureIcon
      case "github":
        return GithubIcon
      default:
        return ZipIcon
    }
  }

  const getHostingName = () => {
    switch (project.data.hosting) {
      case "aws":
        return "AWS S3"
      case "azure":
        return "Azure Blob Storage"
      case "github":
        return "Github"
    }
  }

  const initializeEcho = () => {
    if (echo !== undefined)
      // don't re-initiliaze echo so it will not send multiple notifications
      return

    let client = new Echo({
      broadcaster: "pusher",
      key: process.env.REACT_APP_PUSHER_APP_KEY,
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      forceTLS: false,
      authEndpoint: process.env.REACT_APP_API_BASE_URL + "/broadcasting/auth",
      auth: {
        headers: { Authorization: "Bearer " + JWTHelper.getAccessToken() },
      },
    })
    setEcho(client)
  }

  return (
    <React.Fragment>
      {project?.data?.hosting && (
        <div className="dashboard-card">
          <div className="dashboard-card-left">
            <div className="dashboard-card-icon-wrapper no-background">
              <img src={getIcon()} loading="lazy" alt="" />
            </div>
            <div className="dashboard-card-left-text-wrapper">
              <div className="dashboard-small-text">Hosting</div>
              <div className="dashboard-big-text">
                {getHostingName()} Hosting
              </div>
            </div>
          </div>
          <Button
            className={`button-black-longer w-button ${
              publishing && "when-updating"
            }`}
            label="Publish to Production"
            onClick={createArchive}
            loading={publishing || state.project?.is_publishing_prod}
            fontSize={20}
          />
        </div>
      )}

      {!project?.data?.hosting && (
        <div className="dashboard-card">
          <div className="dashboard-card-left">
            <div className="dashboard-card-icon-wrapper no-background">
              <img src={getIcon()} loading="lazy" alt="" />
            </div>
            <div className="dashboard-card-left-text-wrapper">
              <div className="dashboard-small-text">Build</div>
              <div className="dashboard-big-text">
                {archives.length > 0 ? (
                  truncateUrl(archives[0].filename)
                ) : (
                  <>No builds yet</>
                )}
              </div>
            </div>
          </div>
          <Button
            className={`button-black-longer w-button ${
              publishing && "when-updating"
            }`}
            label="Create Archive"
            onClick={createArchive}
            loading={publishing || state.project?.is_publishing_prod}
            fontSize={20}
          />
        </div>
      )}

      <div className="dashboard-action-links-wrapper margin-bottom-40">
        {!loading ? (
          archives.length > 0 && (
            <a
              href={getBuildLink()}
              target="_blank"
              className="dashboard-action-cta w-inline-block"
              rel="noreferrer"
            >
              <img src={LinkIcon} loading="lazy" alt="" />
              <div className="text-light">Download Zip</div>
            </a>
          )
        ) : (
          <Skeleton.Input size="small" />
        )}
      </div>
    </React.Fragment>
  )
}

export default PublishProduction
